export const ReportsHelp = {
    //Transaction-Counts
    txnCounts : '<h3><center><u>Transaction Counts</u></center></h3><hr/><h6><u><b>Number of transactions:</b></u></h6><p> Either all or Particular transactions done in a given period, (i.e., how many transactions happened)</p>' +
                '<h6><u><b>Average number of Transaction per facility:</b></u></h6><p> Dividing total number of transactions by total number of facilities.</p>' +
                '<h6><u><b>% of store with transaction:</b></u></h6><p>Dividing number of <u>facilities with at least one transaction</u> by total number of facilities in a given period.</p>' +
                '<h6><u><b>Monthly:</b></u></h6><p> Maximum period 12 months can be selected.</p>' +
                '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                '<h6><u><b>Daily:</b></u></h6><p> Maximum period 31 days can be selected.</p>'+
                '<h6><u><b>Note: </b></u></h6><p> This report generated based on the Actual Transaction Date.</p>', 

    //Domain Activity
    dmnActvty : '<h3><center><u>Domain Activity</u></center></h3><hr/><h6><u><b>Live Facility:</b></u></h6><p> Facilities with at least one transaction since creations</p>' +
                '<h6><u><b>Active Facility:</b></u></h6><p> Facilities with at least one transaction in last 30 days.</p>' +
                '<h6><u><b>Live Assets: </b></u></h6><p>Eligible monitored Assets that have received at least one temperature data point since creations.</p>' +
                '<h6><u><b>Active Assets: </b></u></h6><p>Eligible monitored Assets that have received at least one temperature data point in last 7 days.</p>'+
                '<h6><u><b>Eligible CCE: </b></u></h6><p>Only For BVS, CCP and MCCP facility types, except DF all CCE types are included. Whereas for remaining Facility types all CCE types are included(Consider Working, Defrosting and Under Repair status only).</p>', 

    //Activity Rate
    actRate : '<h3><center><u>Activity Rate</u></center></h3><hr/><p> Activity rate report will provide the facilities transaction performance status over the selected period. It will gives the percentage of facilities which are done transaction out of total facilities in selected period. </p>'+
              '<h6><u><b>Note: </b></u></h6><p> This report generated based on the Actual Transaction Date.</p>',
                
    //Stock Report
    StkReport : '<h3><center><u>Stock Report</u></center></h3><hr/><p> Stock report will provide the stock aggregations count state wise, district wise and facility wise. </p>'+
                '<h6><u><b>Total without In-transit: </b></u></h6><p> This is the total stock (available + allocated) without in-transit stock.</p>'+
                '<h6><u><b>Total with In-transit: </b></u></h6><p> This is the total stock (available + allocated + in-transit stock).</p>'+
                '<h6><u><b>Total and in-transit: </b></u></h6><p> This is the total (available + allocated) and in-transit stock separately.</p>',

   //Consumption
   Consumption : '<h3><center><u>Consumption</u></center></h3><hr/><p> Consumption report will provide the amount of pure issue-net utilization transaction (without any tracking object). Which means the actual issue/net utilization transactions happened over the selected period. </p>'+
                 '<h6><u><b>Total: </b></u></h6><p> This metric is the aggregation of all the pure issue/net utilization transaction quantities.</p>'+
                 '<h6><u><b>Avg Monthly: </b></u></h6><p> This metric is the average of the pure issue/net utilization transaction quantity. i.e total quantity divided by number of monthly selected.</p>'+
                 '<h6><u><b>Avg Weekely: </b></u></h6><p> This metric is the average of the pure issue/net utilization transaction quantity. i.e total quantity divided by number of weeks selected.</p>',

    //User Activity
    usrActvty : '<h3><center><u>User Activity</u></center></h3><hr/><h6><u><b>Number of logins:</b></u></h6><p> Number of times users login in a given period</p>' +
                '<h6><u><b>Number of transactions:</b></u></h6><p> Number of transactions performed by the user in a given period.</p>' +
                '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' +
                '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>'+
                '<h6><u><b>Note: </b></u></h6><p> This report generated based on the Actual Transaction Date.</p>', 

    //Abnormal Stock
    abnrmlStk : '<h3><center><u>Abnormal Stock</u></center></h3><hr/><h6><u><b>Number of events:</b></u></h6><p> Total number of abnormal stock events in a given period i.e. Stock out, `>`Max and `<`Min in given period of time</p>' +
                '<h6><u><b>Average number of events per store:</b></u></h6><p> Total number of events divided by Total number of facilities in a given period.</p>' +
                '<h6><u><b>Duration of event: </b></u></h6><p>Total duration of Stock out , `>`Max and `<`Min events in days in a given period of time.</p>' +
                '<h6><u><b>Average Duration per event (Days): </b></u></h6><p> Duration of events (in days) divided by total number of events in a given period of time.</p>' + 
                '<h6><u><b>% of Inventory Items with this abnormality: </b></u></h6><p> Out of total inventory items, how much % of material have Stock out , `>`Max and `<`Min.  i.e.,(Divide number of materials with abnormality(Stock out , `>`Max and `<`Min) by total number of materials in given period of time).</p>' + 
                '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
                '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>',  
    
    //Discards
    dscrds : '<h3><center><u>Discards</u></center></h3><hr/><h6><u><b>Total Discard: </b></u></h6><p> Number of discards (in doses) performed in a given period, reason wise.</p>' +
            '<h6><u><b>%Discarded against stock held:</b></u></h6><p> % of doses discarded compared to total number of doses held during the given period i.e.,(Divide number of doses discarded by total stock in a given period of time)</p>' +     
            '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
            '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
            '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>', 
    
    //Stock Availability
    stkAvlblty : '<h3><center><u>Stock Availability</u></center></h3><hr/><h6><u><b>% of time stock available: </b></u></h6><p> Percentage of time, inventory is available across the facilities or group of facilities.</p>' +
                '<p><u><b>Example (for BCG)</b></u></p><p>Total days in the month: 30 days</p> <p>Total number of Facilities: 10 CCP</p> <p>30 days x 10 Cold Chain Points = 300 </p> <p>Number of stock out days (Zero Stock) = 9 days</p> <p>Stock available days = 300-9 = 291</p> <p>% Availability of Stock =  (291/300)x100 = 97%</p>' +
                '<h6><u><b>% of store with level of availability: </b></u></h6><p> Percentage of facilities that have a certain level ,X, availability or in stock %.</p><p>For example % of facilities which have >=90% availability. (Have 27 days or more in stock in a month).</p><p>For April Month  BCG (dose)</p><p>CCP 1:  Total days = 300, Total Stock out days = 0, availability = 100%</p>' + 
                '<p>CCP 2:  Total days = 300, Total Stock out days = 0, availability = 100%</p><p>CCP 3:  Total days = 300, Total Stock out days = 3.5 days, availability = 98.8% </p><p>CCP 4:  Total days = 300, Total Stock out days = 0, availability = 100%</p><p>CCP 5:  Total days = 300, Total Stock out days = 10 days, availability = 96.6% </p><p>CCP 6:  Total days = 300, Total Stock out days = 5 days, availability = 98.3%</p>' + 
                '<p>CCP 7:  Total days = 300, Total Stock out days = 0 days, availability = 100%</p><p>CCP 8:  Total days = 300, Total Stock out days = 0 days, availability = 100%</p><p>CCP 9:  Total days = 300, Total Stock out days = 5 days, availability = 98.3%</p><p>CCP 10:  Total days = 300, Total Stock out days = 4 days, availability = 98.6%</p><p> % of store with level of availability =100%  5 out of 10 = 50%</p>' + 
                '<p>% of store with level of availability =99%  5 out of 10 = 50%</p><p>% of store with level of availability =90%  10 out of 10 = 100%</p>' + 
                '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
                '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>', 

    //Stock Trends
    stkTrnds : '<h3><center><u>Stock Trends</u></center></h3><hr/><h6><u><b>Closing Stock : </b></u></h6><p> Latest available stock on the end date of given period.</p>' +
                '<h6><u><b>Issues/Net Utilization : </b></u></h6><p> Total number doses issued in a given period of time.</p>' +
                '<h6><u><b>Transfers :</b></u></h6><p> Total number doses transferred in a given period of time.</p>' +  
                '<h6><u><b>Stock-In :</b></u></h6><p> Total number doses received in a given period of time.</p>' +  
                '<h6><u><b>Stock-Edit :</b></u></h6><p> Total number doses edited in a given period of time.</p>' +  
                '<h6><u><b>Stock-Discards :</b></u></h6><p> Total number doses discarded in a given period of time.</p>' +     
                '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
                '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>', 

     //Indent Response time report
     indntRspnseTmeRprt : '<h3><center><u>Indent Response time report</u></center></h3><hr/><h6><u><b>Average Processing time (Days): </b></u></h6><p> Average time taken between Indent Creation to Shipment status( Total time taken from creation to shipment divided by total number of indents ).</p>' +
                        '<h6><u><b>Average Delivery lead time (Days) :</b></u></h6><p> Average time taken between Indent Shipment to Fulfillment status( Total time taken from shipment to fulfillment divided by total number of indents ).</p>' +  
                        '<h6><u><b>Average Lead time (Days) : </b></u></h6><p> Average time taken between Indent Creation to Fulfillment status( Total time taken from creation to fulfillment divided by total number of indents ). </p>' +                           
                        '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
                        '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                        '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>', 

    //CCE Management Status report
     cceMngmntStsRprt : '<h3><center><u>CCE Management Status report</u></center></h3><hr/><p>  Consider only assets in working, under repair, beyond repair status.</p>' +
                        '<h6><u><b>Number of times assets changed to this status: </b></u></h6><p> Total number of times Asset changed to this status (selected) in the selected period. </p>' +  
                        '<h6><u><b>Average number of times assets changed to this status: </b></u></h6><p> Total number of times Asset changed to this status in selected period divided total number of CCE Available. </p>' +   
                        '<h6><u><b>Average duration in this status per asset (in days): </b></u></h6><p> Total duration asset in selected status divided by total number of CCE Available. </p>' +                              
                        '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
                        '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                        '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>',
        
    //Power Availability
    pwrAvlblty : '<h3><center><u>Power Availability</u></center></h3><hr/><h6><u><b>Number of Power cuts: </b></u></h6><p> Total number of power cuts reported in a given period. </p>' +
                '<h6><u><b>Average powercut duration per asset: </b></u></h6><p> Total power cut duration divided by total number of CCE. </p>' +  
                '<h6><u><b>Average Power cut frequency per an asset: </b></u></h6><p> In a given period , total number of power cuts divided by total number of assets. (only relationship mapped and working status)</p>' +                        
                '<h6><u><b>% of Power availability: </b></u></h6><p> Computed by Sum of power availability duration to the total duration.(Month *24 hrs) Power cut is considered when the temperature logger is not getting power supply. Sum of total time power availability duration divided by no of hours *100.</p>' +                        
                '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
                '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>', 

    //Response Time To Repair
    rspnsTmeToRpr : '<h3><center><u>Response Time To Repair</u></center></h3><hr/><h6><u><b>Response Time To Repair :  </b></u></h6><p> Calculated by time taken in days for status change from Under repair to Working status. </p>' +                                              
                    '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
                    '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                    '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>',
                
    //Up Time
    upTme : '<h3><center><u>Up Time</u></center></h3><hr/><h6><u><b>% Up time :  </b></u></h6><p> Total monthly time duration of working assets compared to total monthly time duration in which working assets will be in normal range. </p>' +  
            '<p>720-6/720*100 (Monthly hours-not in normal range hours)/monthly hours*100</p> <p>Only Working to be considered</p>' +                                             
            '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
            '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
            '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>',

    //Temperature Excursions
    tmprtrExcrsn : '<h3><center><u>Temperature Excursions</u></center></h3><hr/><h6><u><b>Number of excursions :  </b></u></h6><p> Total number of hot or cold excursions took place in a given period. </p>' +
                   '<h6><u><b>Average Excursion duration : </b></u></h6><p> Total duration of excursions compared with total number of excursions to pull out average. </p>' +  
                   '<h6><u><b>Number of excursions per asset : </b></u></h6><p> Total number of excursions compared with total number of assets. </p>' +                              
                   '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
                   '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                   '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>', 

    indntDscrpncy : '<h3><center><u>Indent Discrepancies</u></center></h3><hr/><p> Ordering discrepancy is the difference between ordered quantity and the recommended quantity. </p>'+ 
                        '<p> Fulfillment discrepancy is the difference between received quantity and the shipped quantity. </p>' +
                    '<h6><u><b>Total Number of Indents : </b></u></h6><p> This metric represents the total number of indents which are created in the selected period. </p>' +  
                    '<h6><u><b>Total Number of Discrepancies : </b></u></h6><p> This metric represents the total number of indents which are having discrepancies in the selected period. </p>' +  
                    '<h6><u><b>Total Discrepancy Quantity : </b></u></h6><p> This metric represents the total number of discrepancies (in doses) at the time of Indent creation and Indent fulfill in the selected period. </p>'+
                    '<h6><u><b>Positive Discrepancy at Order : </b></u></h6><p> The actual quantity ordered is less than Recommended ordered quantity. </p>'+
                    '<h6><u><b>Negative Discrepancy at Order : </b></u></h6><p> The actual quantity ordered is more than Recommended ordered quantity. </p>'+
                    '<h6><u><b>Positive Discrepancy at fulfilled : </b></u></h6><p> The fulfilled quantity ordered is less than shipped quantity. </p>'+
                    '<h6><u><b>Negative Discrepancy at fulfilled : </b></u></h6><p> The fulfilled quantity ordered is more than shipped quantity. </p>'+
                    '<h6><u><b>Monthly: </b></u></h6><p> Maximum period 12 months can be selected.</p>' + 
                    '<h6><u><b>Weekly: </b></u></h6><p> Maximum period 42 days can be selected.</p>' +
                    '<h6><u><b>Daily: </b></u></h6><p> Maximum period 31 days can be selected.</p>',                         
                    
}