<form [formGroup]="transForm" autocomplete="off">
    <div class="row">
        <div class="col-xl-12" class="row">
            <div class="col-sm-3 mb-3">
                <label for="inputEmail3" class="col-form-label">Transaction Type
                    <span class="text-danger"><sup>*</sup></span>
                </label>
                <select id="inputState" class="form-select form-select-sm" formControlName="transType" #TransType
                        (change)="isSbmtd=false;str='';onChngTT()" [ngClass]="{ 'is-invalid': isSbmtd && fc.transType.errors }">
                    <option value="">-Select-</option>
                    <option [value]="txnTY.id" *ngFor="let txnTY of lstTxnTyps">{{txnTY.name}}</option>
                </select>
                <div *ngIf="isSbmtd && fc.transType.errors" class="invalid-feedback">
                    <div *ngIf="fc.transType.errors.required">Please choose Transaction Type</div>
                </div>
            </div>
            <div class="col-sm-3 mb-3">
                <label for="inputEmail4" class="col-form-label">
                    {{(TransType.value=='1' || TransType.value=='5'?'Issuing ':'')}}Facility <span class="text-danger"><sup>*</sup></span>
                </label>
                <ng-template #cstmStore let-model="item">
                    <span class="font-weight-bold">{{model.storeName}}</span><br />
                    <span class="fs-12">{{model.location}}</span>
                </ng-template>
                <input name="store" type="text" placeholder="{{(TransType.value=='1' || TransType.value=='5'?'Issuing ':'')}}Facility"
                    (change)="onChngFclty($event, 'chng')" (typeaheadOnSelect)="onChngFclty($event, 'slct')"
                    [typeahead]="lstFacs" [typeaheadItemTemplate]="cstmStore" [adaptivePosition]="true" typeaheadOptionField="storeName" 
                    class="form-control form-control-sm" [typeaheadScrollable]="true" [ngClass]="{ 'is-invalid': isSbmtd && fc.facility.errors }"
                    [attr.disabled]="(TransType.value == '' || lstFacs.length == 1 ||isSUFclty)?true : null" id="Store"
                    formControlName="facility" [typeaheadOptionsLimit]="2500" />
                <div *ngIf="isSbmtd && fc.facility.errors" class="invalid-feedback" >
                    Please choose {{(TransType.value=='1' || TransType.value=='5'?'Issuing ':'')}}Facility
                </div>
            </div>
            <div class="col-sm-3 mb-3" *ngIf="false">
                <label for="inputEmail3" class="col-form-label">Material Category Filter</label>
                <select id="inputState" class="form-select form-select-sm" formControlName="matCatgFilt">
                    <option value="">-Select-</option>
                    <option *ngFor="let mcf of lstMCF" [value]="mcf.id">{{mcf.name}}</option>
                </select>
            </div>
            <div class="col-sm-3 mb-3"> <!-- *ngIf="TransType.value=='1' || TransType.value=='2'"  --> <!--  *ngIf="actTxnFlag" -->
                <label for="transactualdate" class="col-form-label">
                    Date of Actual Transaction<span class="text-danger"><sup>*</sup></span> <!-- *ngIf="rqdFlag" -->
                </label>
                <div class="form-group">
                    <div class="input-group mb-3">
                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                            class="form-control form-control-sm optinbg bsDP-bg" [bsConfig]="{ isAnimated: true,
                                dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default',
                                showWeekNumbers: false, adaptivePosition: true }" readonly #dpDAT="bsDatepicker"
                            [dateCustomClasses]="cstmDt" (bsValueChange)="onChngDT($event)"
                            [maxDate]="tdDate" [ngClass]="{ 'is-invalid': isSbmtd && fc.transactualdate.errors }"
                            onkeypress='return event.charCode == 8' formControlName="transactualdate">
                        <div class="input-group-append">
                            <button class="btn btn-sm btn-outline-bsdp p-2 pntr" (click)="dpDAT.toggle()" [attr.aria-expanded]="dpDAT.isOpen">
                                <i class="fa fa-calendar f-12"></i>
                            </button>
                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                (click)="dpDAT.hide();clearBSDate('transactualdate', 0, 'm')">
                                <i class="fa fa-times"></i>
                            </button>
                        </div>
                        <div *ngIf="isSbmtd && fc.transactualdate.errors" class="invalid-feedback">
                            <div *ngIf="fc.transactualdate.errors.required">Please fill Date of Actual Transaction</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3" style="padding-top:2rem;">
                <div class="">
                    <button class="btn btn-sm btn-primary">Filter</button>
                </div>
            </div>
           
            <div class="col-sm-3 mb-3" *ngIf="TransType.value=='1'||TransType.value=='2'">
                <label for="linked_store_id" class="col-form-label">
                    {{TransType.value=='2'?'Issuing ':'Receiving '}}Facility <!-- <span class="text-danger" *ngIf="TransType.value=='5'"><sup>*</sup></span> -->
                </label>
                <!-- <ng-template #rcvngStore let-model="item">
                    <span class="font-weight-bold">{{model.storeName}}</span><br />
                    <span class="fs-12">{{model.location}}</span>
                </ng-template>
                <input name="store" type="text" id="linked_store_id" [typeaheadScrollable]="true"
                    placeholder="{{(TransType.value=='1'?'Receiving':(TransType.value=='2'?'Issuing ':'Transferring '))}}Facility"
                    formControlName="linked_store_id" [typeahead]="lstFacs" [typeaheadItemTemplate]="rcvngStore"
                    (change)="onFacChng($event)" typeaheadOptionField="storeName" [adaptivePosition]="true"
                    class="form-control form-control-sm" (typeaheadOnSelect)="onChngFclty($event, 's2')"
                    [ngClass]="{ 'is-invalid': isSbmtd && fc.linked_store_id.errors }"
                    [typeaheadOptionsLimit]="500" /> -->
                <ng-template #rcvngStore let-model="item">
                    <span class="font-weight-bold">{{model.mapped_store_name}}</span> <br />
                    <span class="fs-12">{{model.mapped_store_location}}</span>
                </ng-template>
                <input name="linked_store_id" type="text" id="linked_store_id" (change)="onChngFclty($event, 'chng', 's2')"
                    formControlName="linked_store_id" [typeahead]="lstLinkedStrs" [typeaheadItemTemplate]="rcvngStore"
                    typeaheadOptionField="mapped_store_name" [adaptivePosition]="true" [typeaheadScrollable]="true"
                    class="form-control form-control-sm" [ngClass]="{ 'is-invalid': isSbmtd && fc.linked_store_id.errors }"
                    placeholder="{{(TransType.value=='1'?'Receiving':(TransType.value=='2'?'Issuing ':'Receiving '))}}Facility"
                    (typeaheadOnSelect)="onChngFclty($event, 'slct', 's2')" [typeaheadOptionsLimit]="2500" />
                <div *ngIf="isSbmtd && fc.linked_store_id.errors" class="invalid-feedback">
                    <div *ngIf="fc.linked_store_id.errors.required">Please choose {{TransType.value=='2'?'Issuing ':'Receiving '}}Facility</div>
                </div>
            </div>
            <div class="col-sm-3 mb-3" *ngIf="TransType.value=='5'">
                <label for="linked_store_id" class="col-form-label">
                    Receiving Facility<span class="text-danger"><sup>*</sup></span>
                </label>
                <ng-template #rcvngStore1 let-model="item">
                    <span class="font-weight-bold">{{model.storeName}}</span> <br />
                    <span class="fs-12">{{model.location}}</span>
                </ng-template>
                <input name="linked_store_id" type="text" id="linked_store_id" (change)="onChngFclty($event, 'chng', 's3')"
                    formControlName="linked_store_id" [typeahead]="lstFacs1" [typeaheadItemTemplate]="rcvngStore1"
                    typeaheadOptionField="storeName" [adaptivePosition]="true" [typeaheadScrollable]="true"
                    class="form-control form-control-sm" [ngClass]="{ 'is-invalid': isSbmtd && fc.linked_store_id.errors }"
                    placeholder="Receiving Facility" (typeaheadOnSelect)="onChngFclty($event, 'slct', 's3')" 
                    [typeaheadOptionsLimit]="2500"/>
                <div *ngIf="isSbmtd && fc.linked_store_id.errors" class="invalid-feedback">
                    <div *ngIf="fc.linked_store_id.errors.required">Please choose Receiving Facility</div>
                </div>
            </div>
            <div class="col-sm-6 mb-3 mt-4">
                <label>Capture Lat & Long</label>&nbsp;&nbsp;
                <input class="form-check-input" type="radio" name="isReqLL" value="1" (click)="isReqLL='1'"
                    [checked]="isReqLL=='1'">&nbsp;&nbsp;<label class="form-check-label">Yes</label>&nbsp;&nbsp;
                <input class="form-check-input" type="radio" name="isReqLL" value="0" (click)="isReqLL='0'"
                    [checked]="isReqLL=='0'">&nbsp;&nbsp;<label class="form-check-label">No</label><br />
                <!-- <div class="highTemp font-wt" *ngIf="isReqLL == '1'">Kindly provide Location access to Create Transaction..!</div> -->
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header p-2">Choose</div>
        <div class="card-body p-2">
            <div class="table-responsive">
                <table class="table table-striped table1 table-sm table-bordered table_spacing" formArrayName="products">
                    <thead>
                        <tr>
                            <th class="text-center">Item No.</th>
                            <th class="text-center">Material</th>
                            <th class="text-center">Total stock<br /><small>{{str}}</small></th>
                            <th class="text-center">Allocated stock</th>
                            <th class="text-center">Available stock<br /><small>{{str}}</small></th>
                            <th class="text-center" style="width: 20% !important;">Quantity<span class="text-danger"><sup>*</sup></span></th> <!-- <sup>*</sup> -->
                            <th class="text-center" style="width: 20% !important;">Reason<span class="text-danger" *ngIf="isRsnMndtry"><sup>*</sup></span></th> <!-- *ngIf="TransType.value=='3'" -->
                            <th class="text-center">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of GetMatrls().controls;let tabIndx=index;" [formGroupName]="tabIndx">
                        <tr>
                            <td class="text-center">{{tabIndx+1}}</td>
                            <td class="text-center">
                                {{item?.get('nameMat')?.value}}&nbsp;<a *ngIf="isLclDev">"({{item?.get('handlingQuantity')?.value}})"</a>
                            </td>
                            <td class="text-center" [ngStyle]="{ 'backgroundColor': item?.get('stockIndicator')?.value != null ? item?.get('stockIndicator')?.value.backgroundColor : null,
                                'color': item?.get('stockIndicator')?.value != null ? '#000000' : null }">
                                {{item?.get('ts')?.value}}<br />
                                <small title="(min, max)">({{item?.get('invMin')?.value}}, {{item?.get('invMax')?.value}})</small>                                
                            </td>                            
                            <td class="text-center">{{item?.get('alctdStk')?.value}}</td>
                            <td class="text-center">{{item?.get('stk')?.value}}</td>
                            <td class="text-center">
                                <div *ngIf="item?.get('batchManagement')?.value">
                                    <a *ngIf="!item.get('isExpnd')?.value" (click)="onClkEBB(tabIndx)" role="button"
                                        class="btn btn-sm btn-primary">Entry by Batch</a>
                                    <a *ngIf="item.get('isExpnd')?.value" (click)="onClkEBB(tabIndx)" role="button"
                                        class="btn btn-sm btn-info">Hide Batch</a>
                                </div>
                                <div *ngIf="!item?.get('batchManagement')?.value">
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-sm" formControlName="quantity" id="nbQnty{{tabIndx}}"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' (keyup)="onEditMatQty($event, item)"
                                            [ngClass]="{ 'is-invalid': isSbmtd && (item?.get('quantity')?.errors || item?.get('isInvalidPttrn')?.value) }"
                                            [maxlength]="(TransType.value == '1' || TransType.value == '4' || TransType.value == '5') ? item?.get('stk')?.value?.toString().length : 8"
                                            [attr.disabled]="(TransType.value == '1' || TransType.value == '4' || TransType.value == '5') && item?.get('stk')?.value == 0 ? true : null">
                                        <div *ngIf="isSbmtd && item?.get('quantity')?.errors || item?.get('isInvalidPttrn')?.value" class="invalid-feedback">
                                            <!-- <div *ngIf="item?.get('quantity')?.errors?.pattern">Quantity should be multiple of 5</div> -->
                                            <div *ngIf="item?.get('isInvalidPttrn')?.value">Quantity should be multiple of {{item?.get('handlingQuantity')?.value}}</div>
                                            <div *ngIf="item?.get('quantity')?.errors?.required">Please fill Quantity</div>
                                            <div *ngIf="item?.get('quantity')?.errors?.min">Min. value should be {{TransType.value == '3' ? item?.get('alctdStk')?.value : (item?.get('handlingQuantity')?.value != null ? item?.get('handlingQuantity')?.value : '1')}}</div>
                                            <div *ngIf="item?.get('quantity')?.errors?.minlength">Min. Length is {{TransType.value == '3' ? item?.get('alctdStk')?.value.toString().length : (item?.get('handlingQuantity')?.value != null ? item?.get('handlingQuantity')?.value : '1')}}</div>
                                            <div *ngIf="item?.get('quantity')?.errors?.max">Max. allowed is {{TransType.value == '1' || TransType.value == '4' || TransType.value == '5' ? item?.get('stk')?.value : 99999999}}</div>
                                            <div *ngIf="item?.get('quantity')?.errors?.maxlength">Max. Length is {{TransType.value == '1' || TransType.value == '4' || TransType.value == '5' ? item?.get('stk')?.value?.toString().length : 8}}</div>
                                        </div>
                                    </div>
                                    <label>Material status<span class="text-danger" *ngIf="isStsMndtry && item?.get('quantity')?.value != ''"><sup>*</sup></span></label>  
                                    <div *ngIf="TransType.value=='1'">
                                        <select class="form-select form-select-sm" style="width: 100% !important;"
                                            formControlName="material_status" [attr.disabled]="item?.get('stk')?.value == 0 ? true : null"
                                            [ngClass]="{ 'is-invalid': isSbmtd && item?.get('material_status')?.errors }">
                                            <option value="">-Select-</option>
                                            <option *ngFor="let sts of stIssue" [value]="sts.id">{{sts.name}}</option>
                                        </select>
                                        <div *ngIf="isSbmtd && item?.get('material_status')?.errors" class="invalid-feedback">
                                            <div *ngIf="isSbmtd && item?.get('material_status')?.errors?.required">
                                                Please choose Status
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="TransType.value=='2'">
                                        <select class="form-select form-select-sm" style="width: 100% !important;" formControlName="material_status"
                                            [ngClass]="{ 'is-invalid': isSbmtd && item?.get('material_status')?.errors }">
                                            <option value="">-Select-</option>
                                            <option *ngFor="let sts of stReceipt" [value]="sts.id">{{sts.name}}</option>
                                        </select>
                                        <div *ngIf="isSbmtd && item?.get('material_status')?.errors" class="invalid-feedback">
                                            <div *ngIf="isSbmtd && item?.get('material_status')?.errors?.required">
                                                Please choose Status
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="TransType.value=='3'">
                                        <select class="form-select form-select-sm" style="width: 100% !important;" formControlName="material_status"
                                            [ngClass]="{ 'is-invalid': isSbmtd && item?.get('material_status')?.errors }">
                                            <option value="">-Select-</option>
                                            <option *ngFor="let sts of stStkCnt" [value]="sts.id">{{sts.name}}</option>
                                        </select>
                                        <div *ngIf="isSbmtd && item?.get('material_status')?.errors" class="invalid-feedback">
                                            <div *ngIf="isSbmtd && item?.get('material_status')?.errors?.required">
                                                Please choose Status
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="TransType.value=='4'">
                                        <select class="form-select form-select-sm" style="width: 100% !important;"
                                            [attr.disabled]="item?.get('stk')?.value == 0 ? true : null" formControlName="material_status"
                                            [ngClass]="{ 'is-invalid': isSbmtd && item?.get('material_status')?.errors }">
                                            <option value="">-Select-</option>
                                            <option *ngFor="let sts of stDscrd" [value]="sts.id">{{sts.name}}</option>
                                        </select>
                                        <div *ngIf="isSbmtd && item?.get('material_status')?.errors" class="invalid-feedback">
                                            <div *ngIf="isSbmtd && item?.get('material_status')?.errors?.required">
                                                Please choose Status
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="TransType.value=='5'">
                                        <select class="form-select form-select-sm" style="width: 100% !important;"
                                            [attr.disabled]="item?.get('stk')?.value == 0 ? true : null" formControlName="material_status"
                                            [ngClass]="{ 'is-invalid': isSbmtd && item?.get('material_status')?.errors }">
                                            <option value="">-Select-</option>
                                            <option *ngFor="let sts of stTrnsfr" [value]="sts.id">{{sts.name}}</option>
                                        </select>
                                        <div *ngIf="isSbmtd && item?.get('material_status')?.errors" class="invalid-feedback">
                                            <div *ngIf="isSbmtd && item?.get('material_status')?.errors?.required">
                                                Please choose Status
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td> 
                            <td class="text-center">
                                <!-- <div *ngIf="TransType.value!='3' && TransType.value!='4'">{{item?.get('reason_id')?.value}}</div> -->
                                <div *ngIf="TransType.value=='1'">
                                    <select class="form-select form-select-sm" formControlName="reason_id"
                                        [ngClass]="{ 'is-invalid': isSbmtd && item?.get('reason_id')?.errors }" style="width: 100% !important;">
                                        <option value="">-Select-</option>
                                        <option *ngFor="let rsn of item.get('rsnsLst')?.value" [value]="rsn.reasonId">{{rsn.reasonName}}</option>                                        
                                        <!-- <option *ngFor="let rsn of lstRsnsIssue" [value]="rsn.reasonId">{{rsn.reasonName}}</option> -->
                                    </select>
                                    <div *ngIf="isSbmtd && item?.get('reason_id')?.errors" class="invalid-feedback">
                                        <div *ngIf="isSbmtd && item?.get('reason_id')?.errors?.required">
                                            Please choose Reason
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="TransType.value=='2'">
                                    <select class="form-select form-select-sm" formControlName="reason_id"
                                        [ngClass]="{ 'is-invalid': isSbmtd && item?.get('reason_id')?.errors }" style="width: 100% !important;">
                                        <option value="">-Select-</option>
                                        <option *ngFor="let rsn of item.get('rsnsLst')?.value" [value]="rsn.reasonId">{{rsn.reasonName}}</option> 
                                        <!-- <option *ngFor="let rsn of lstRsnsRcpt" [value]="rsn.reasonId">{{rsn.reasonName}}</option> -->
                                    </select>
                                    <div *ngIf="isSbmtd && item?.get('reason_id')?.errors" class="invalid-feedback">
                                        <div *ngIf="isSbmtd && item?.get('reason_id')?.errors?.required">Please choose Reason</div>
                                    </div>
                                </div>
                                <div *ngIf="TransType.value=='3'">
                                    <select class="form-select form-select-sm" formControlName="reason_id"
                                        [ngClass]="{ 'is-invalid': isSbmtd && item?.get('reason_id')?.errors }" style="width: 100% !important;">
                                        <option value="">-Select-</option>
                                        <option *ngFor="let rsn of item.get('rsnsLst')?.value" [value]="rsn.reasonId">{{rsn.reasonName}}</option> 
                                        <!-- <option *ngFor="let rsn of lstRsnTSC" [value]="rsn.reasonId">{{rsn.reasonName}}</option> -->
                                    </select>
                                    <div *ngIf="isSbmtd && item?.get('reason_id')?.errors" class="invalid-feedback">
                                        <div *ngIf="isSbmtd && item?.get('reason_id')?.errors?.required">
                                            Please choose Reason
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="TransType.value=='4'">
                                    <select class="form-select form-select-sm" formControlName="reason_id"  (change)="slctReason($event, tabIndx+1)"
                                        [ngClass]="{ 'is-invalid': isSbmtd && item?.get('reason_id')?.errors }" style="width: 100% !important;">
                                        <option value="">-Select-</option>
                                        <option *ngFor="let rsn of item.get('rsnsLst')?.value" [value]="rsn.reasonId">{{rsn.reasonName}}</option> 
                                        <!-- <option *ngFor="let rsn of lstRsnDscrd" [value]="rsn.reasonId">{{rsn.reasonName}}</option> -->
                                    </select>
                                    <div *ngIf="isSbmtd && item?.get('reason_id')?.errors" class="invalid-feedback">
                                        <div *ngIf="isSbmtd && item?.get('reason_id')?.errors?.required">
                                            Please choose Reason
                                        </div>
                                    </div>
                                    <div *ngIf ="TransType?.value=='4' && item?.get('reason_id')?.value=='354' "> 
                                        <textarea class="form-control form-control-name taRS mt-2" formControlName="resnOthrs"
                                        [ngClass]="{ 'is-invalid': isSbmtd && item?.get('resnOthrs')?.errors}"  (keyup)="onChngRsnTxt($event, tabIndx+1)"
                                            placeholder="Enter Reason"></textarea>
                                            <div *ngIf="isSbmtd && item?.get('resnOthrs')?.errors" class="text-danger">
                                                <div *ngIf="item?.get('resnOthrs')?.errors?.required">Please Enter not Reason</div>
                                                <div *ngIf="item?.get('resnOthrs')?.errors?.maxlength">Please Enter not More than 15 Characters</div>

                                            </div>
                                        </div>
                                        <!-- <div *ngIf="isSbmtd && item?.get('resnOthrs')?.errors" class="invalid-feedback">
                                            <div *ngIf="isSbmtd && item?.get('resnOthrs')?.errors?.required">
                                                Please Enter not More than 15 Characters
                                            </div>
                                        </div> -->
                                </div>
                                <!-- *ngIf ="TransType?.value=='4' && ReasonType.value==354 && item?.get('resnOthrs')?.value ==null" -->
                                <!-- [ngClass]="{ 'is-invalid': item?.get('resnOthrs')?.value ==null || (item?.get('resnOthrs')?.value).length>15 }"  -->
                                <!-- *ngIf="item?.get('resnOthrs')?.value.length>15" -->
                               
                                <div *ngIf="TransType.value=='5'">
                                    <select class="form-select form-select-sm" formControlName="reason_id"
                                        [ngClass]="{ 'is-invalid': isSbmtd && item?.get('reason_id')?.errors }" style="width: 100% !important;">
                                        <option value="">-Select-</option>
                                        <option *ngFor="let rsn of item.get('rsnsLst')?.value" [value]="rsn.reasonId">{{rsn.reasonName}}</option> 
                                        <!-- <option *ngFor="let rsn of lstRsnVVM" [value]="rsn.reasonId">{{rsn.reasonName}}</option> -->
                                    </select>
                                    <div *ngIf="isSbmtd && item?.get('reason_id')?.errors" class="invalid-feedback">
                                        <div *ngIf="isSbmtd && item?.get('reason_id')?.errors?.required">
                                            Please choose Reason
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <a (click)="onDelMtrls(tabIndx, item?.get('nameMat')?.value)">
                                    <i class="fa fa-trash toggle-data"></i>
                                </a>
                            </td>
                        </tr>
                        <tr *ngIf="item.get('isExpnd')?.value">
                            <td colspan="8"> <!-- *ngIf="GetListMB(tabIndx).controls.length > 0" -->
                                <div class="table_spacing table-responsive" formArrayName="mbList">
                                    <table class="table table-striped table-condensed table-bordered table-design table-sm table-hover">
                                        <thead>
                                            <tr>
                                                <th class="text-center">Sl.No </th>
                                                <th class="text-center">Batch ID<span class="text-danger"><sup>*</sup></span></th>
                                                <th class="text-center">Manufacturer<span class="text-danger"><sup>*</sup></span></th>
                                                <th class="text-center">Manufactured Date<span class="text-danger"><sup>*</sup></span><br />(dd/mm/yyyy)</th>
                                                <th class="text-center">Expiry Date<span class="text-danger"><sup>*</sup></span><br />(dd/mm/yyyy)</th>
                                                <th class="text-center" *ngIf="TransType.value!='2'">Total stock</th>
                                                <th class="text-center" *ngIf="TransType.value!='2'">Allocated</th>
                                                <th class="text-center" *ngIf="TransType.value!='2'">Available stock</th>
                                                <th class="text-center">Quantity<span class="text-danger"></span> <!-- <sup>*</sup> --></th>
                                                <!-- <th class="text-center" *ngIf="TransType.value!='1' && TransType.value!='2' &&
                                                    TransType.value!='3' && TransType.value!='5'">Reason
                                                    <span *ngIf="TransType.value=='3' || TransType.value=='4'"
                                                        class="text-center"><sup>*</sup>
                                                    </span>
                                                </th> -->
                                                <th class="text-center">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let bItem of GetListMB(tabIndx).controls;let batIndx=index;" [formGroupName]="batIndx">
                                            <tr>
                                                <td class="text-center">{{batIndx+1}}</td>
                                                <td class="text-center">
                                                    <input type="text" class="form-control form-control-sm text-uppercase"
                                                        [ngClass]="{ 'is-invalid': isSbmtd && bItem?.get('bid')?.errors }" maxlength="20"
                                                        onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                            (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 8)) ||
                                                            (event.charCode >= 48 && event.charCode <= 57)' formControlName="bid"
                                                        [attr.disabled]="bItem?.get('isEdit')?.value == false ? true : null"
                                                        (change)="onChngBM(tabIndx, batIndx, bItem?.get('isEdit')?.value)">
                                                    <div *ngIf="isSbmtd && bItem?.get('bid')?.errors" class="invalid-feedback">
                                                        <div *ngIf="bItem?.get('bid')?.errors?.required">Please fill Batch ID</div>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <ng-template #customTemplate let-model="item">
                                                        <span class="font-weight-bold">{{model.name}}</span>
                                                    </ng-template>
                                                    <input name="'manufacturer'+{{batIndx}}" type="text" id="'manufacturer'+{{batIndx}}"
                                                        formControlName="mnm" [typeahead]="lstAllProducers" typeaheadOptionField="name"
                                                        [typeaheadItemTemplate]="customTemplate" class="form-control form-control-sm"
                                                        (typeaheadOnSelect)="slctdPd($event, tabIndx, batIndx, 'slct');
                                                            onChngBM(tabIndx, batIndx, bItem?.get('isEdit')?.value)"
                                                        (change)="slctdPd($event, tabIndx, batIndx, 'chng')"
                                                        [ngClass]="{ 'is-invalid': isSbmtd && bItem?.get('mnm')?.errors }"
                                                        [typeaheadScrollable]="true" [adaptivePosition]="true"
                                                        [attr.disabled]="bItem?.get('isEdit')?.value == false ? true : null"
                                                        [typeaheadOptionsLimit]="2500">
                                                    <div *ngIf="isSbmtd && bItem?.get('mnm')?.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="bItem?.get('mnm')?.errors?.required">Please choose Manufacturer</div>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="input-group mb-3">
                                                        <!-- [dateCustomClasses]="cstmDt2" (bsValueChange)="onChngManDT($event)" -->
                                                        <input type="text" autocomplete="off" onpaste="return false"
                                                            class="form-control form-control-sm optinbg bsDP-bg"
                                                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default',
                                                                showWeekNumbers: false, isAnimated: true, adaptivePosition: true }"
                                                            formControlName="md" [maxDate]="tdDate" #dpMD="bsDatepicker"
                                                            [attr.disabled]="bItem?.get('isEdit')?.value == false ||
                                                                bItem?.get('isDMD')?.value == true ? true : null"
                                                            [ngClass]="{ 'is-invalid': isSbmtd && bItem?.get('md')?.errors }"
                                                            onkeypress='return event.charCode == 8' readonly bsDatepicker>
                                                        <div class="input-group-append" *ngIf="bItem?.get('isEdit')?.value">
                                                            <button class="btn btn-sm btn-outline-bsdp p-2 pntr"
                                                                (click)="dpMD.toggle()" [attr.aria-expanded]="dpMD.isOpen"
                                                                [attr.disabled]="bItem?.get('isDMD')?.value ? true : null">
                                                                <i class="fa fa-calendar f-12"></i>
                                                            </button>
                                                            <!-- <button class="btn btn-sm btn-outline-bsdp p-2" type="button"
                                                                title="Clear Date" (click)="dpED.hide();clearBSDate('md', batIndx)">
                                                                <i class="fa fa-times"></i>
                                                            </button> -->
                                                        </div>
                                                        <div *ngIf="isSbmtd && bItem?.get('md')?.errors" class="invalid-feedback">
                                                            <div *ngIf="bItem.get('md')?.errors?.required">Please fill Manufactured Date</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="input-group mb-3">
                                                        <!-- [dateCustomClasses]="cstmDt3" (bsValueChange)="onChngDe($event)" -->
                                                        <input type="text" autocomplete="off" onpaste="return false"
                                                            class="form-control form-control-sm optinbg bsDP-bg"
                                                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', isAnimated: true,
                                                                containerClass: 'theme-default', showWeekNumbers: false,
                                                                adaptivePosition: true }" formControlName="e"
                                                            [minDate]="tdDate" #dpED="bsDatepicker" readonly
                                                            [ngClass]="{ 'is-invalid': isSbmtd && bItem?.get('e')?.errors }"
                                                            [attr.disabled]="bItem?.get('isEdit')?.value == false ||
                                                                bItem?.get('isDED')?.value == true ? true : null"
                                                            onkeypress='return event.charCode == 8' bsDatepicker>
                                                        <div class="input-group-append"
                                                            *ngIf="bItem?.get('isEdit')?.value">
                                                            <button class="btn btn-sm btn-outline-bsdp p-2 pntr"
                                                                (click)="dpED.toggle()" [attr.aria-expanded]="dpED.isOpen"
                                                                [attr.disabled]="bItem?.get('isDED')?.value ? true : null">
                                                                <i class="fa fa-calendar"></i>
                                                            </button>
                                                            <!-- <button class="btn btn-sm btn-outline-bsdp p-2" type="button"
                                                                title="Clear Date" (click)="dpED.hide();clearBSDate('e', batIndx)">
                                                                <i class="fa fa-times"></i>
                                                            </button> -->
                                                        </div>
                                                        <div *ngIf="isSbmtd && bItem?.get('e')?.errors" class="invalid-feedback">
                                                            <div *ngIf="bItem.get('e')?.errors?.required">Please fill Expiry Date</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td *ngIf="TransType.value!='2'" class="text-center">
                                                    {{bItem?.get('isEdit')?.value ? 0 : bItem?.get('ts')?.value}} <!-- {{bItem?.get('isEdit')?.value ? item?.get('ts')?.value : bItem?.get('ts')?.value}} -->
                                                    <!-- <input type="text" class="form-control form-control-sm" formControlName="ts" /> -->
                                                </td>
                                                <td *ngIf="TransType.value!='2'" class="text-center">
                                                    {{bItem?.get('isEdit')?.value ? 0 : bItem?.get('alctdStk')?.value}} <!-- {{bItem?.get('isEdit')?.value ? item?.get('alctdStk')?.value : bItem?.get('alctdStk')?.value}} -->
                                                    <!-- <input type="text" class="form-control form-control-sm" formControlName="alctdStk" /> -->
                                                </td>
                                                <td *ngIf="TransType.value!='2'" class="text-center">
                                                    {{bItem?.get('isEdit')?.value ? 0 : bItem?.get('as')?.value}} <!-- {{bItem?.get('isEdit')?.value ? item?.get('stk')?.value : bItem?.get('as')?.value}} -->
                                                </td>
                                                <td class="text-center">
                                                    <div class="form-group">
                                                        <input type="text" formControlName="quantity" class="form-control form-control-sm" id="wbQnty{{tabIndx}}{{batIndx}}"
                                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' (keyup)="onEditQty($event, item, bItem)"
                                                            [maxlength]="(TransType.value == '1' || TransType.value == '4' || TransType.value == '5') ? bItem?.get('as')?.value.toString().length : 8"
                                                            [ngClass]="{ 'is-invalid': isSbmtd && (bItem?.get('quantity')?.errors || bItem?.get('isInvalidPttrn')?.value) }"
                                                            [attr.disabled]=" expiredDateCheck(bItem?.get('e')?.value) && TransType.value != '4' || (TransType.value == '1' || TransType.value == '4' || TransType.value == '5') && bItem?.get('as')?.value == 0 ? true : null">
                                                        <div *ngIf="isSbmtd && bItem?.get('quantity')?.errors || bItem?.get('isInvalidPttrn')?.value" class="invalid-feedback">
                                                            <!-- <div *ngIf="bItem?.get('quantity')?.errors?.pattern">Quantity should be multiple of 5</div> -->
                                                            <div *ngIf="bItem?.get('isInvalidPttrn')?.value">Quantity should be multiple of {{item?.get('handlingQuantity')?.value}}</div>
                                                            <div *ngIf="bItem?.get('quantity')?.errors?.required">Please fill Quantity</div>
                                                            <div *ngIf="bItem?.get('quantity')?.errors?.min">Min. value should be {{item?.get('handlingQuantity')?.value != null ? item?.get('handlingQuantity')?.value : '1'}}</div>
                                                            <div *ngIf="bItem?.get('quantity')?.errors?.minlength">Min. Length is {{item?.get('handlingQuantity')?.value != null ? item?.get('handlingQuantity')?.value.toString().length : '1'}}</div>
                                                            <!-- <div *ngIf="bItem?.get('quantity')?.errors?.min">Min. value should be {{TransType.value == '3' ? bItem?.get('alctdStk')?.value : (item?.get('handlingQuantity')?.value != null ? item?.get('handlingQuantity')?.value : '1')}}</div>
                                                            <div *ngIf="bItem?.get('quantity')?.errors?.minlength">Min. Length is {{TransType.value == '3' ? bItem?.get('alctdStk')?.value.toString().length : (item?.get('handlingQuantity')?.value != null ? item?.get('handlingQuantity')?.value : '1')}}</div> -->
                                                            <div *ngIf="bItem?.get('quantity')?.errors?.max">Max. allowed is {{TransType.value == '1' || TransType.value == '4' || TransType.value == '5' ? bItem?.get('as')?.value : 99999999}}</div>
                                                            <div *ngIf="bItem?.get('quantity')?.errors?.maxlength">Max. Length is {{TransType.value == '1' || TransType.value == '4' || TransType.value == '5' ? bItem?.get('as')?.value?.toString().length : 8}}</div>
                                                        </div>
                                                    </div>
                                                    <label>Material status<span class="text-danger" *ngIf="isStsMndtry && bItem?.get('quantity')?.value != ''"><sup>*</sup></span></label>                                                    
                                                    <div *ngIf="TransType.value=='1'">
                                                        <select class="form-select form-select-sm" formControlName="material_status" style="width: 100% !important;"
                                                            [attr.disabled]="bItem?.get('as')?.value == 0 ? true : null"
                                                            [ngClass]="{ 'is-invalid': isSbmtd && bItem?.get('material_status')?.errors }">
                                                            <option value="">-Select-</option>
                                                            <option *ngFor="let sts of stIssue" [value]="sts.id">{{sts.name}}</option>
                                                        </select>
                                                        <div *ngIf="isSbmtd && bItem?.get('material_status')?.errors" class="invalid-feedback">
                                                            <div *ngIf="isSbmtd && bItem?.get('material_status')?.errors?.required">
                                                                Please choose Status
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="TransType.value=='2'">
                                                        <select class="form-select form-select-sm" formControlName="material_status" style="width: 100% !important;"
                                                            [ngClass]="{ 'is-invalid': isSbmtd && bItem?.get('material_status')?.errors }">
                                                            <option value="">-Select-</option>
                                                            <option *ngFor="let sts of stReceipt" [value]="sts.id">{{sts.name}}</option>
                                                        </select>
                                                        <div *ngIf="isSbmtd && bItem?.get('material_status')?.errors" class="invalid-feedback">
                                                            <div *ngIf="isSbmtd && bItem?.get('material_status')?.errors?.required">
                                                                Please choose Status
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="TransType.value=='3'">
                                                        <select class="form-select form-select-sm" formControlName="material_status" style="width: 100% !important;"
                                                            [ngClass]="{ 'is-invalid': isSbmtd && bItem?.get('material_status')?.errors }">
                                                            <option value="">-Select-</option>
                                                            <option *ngFor="let sts of stStkCnt" [value]="sts.id">{{sts.name}}</option>
                                                        </select>
                                                        <div *ngIf="isSbmtd && bItem?.get('material_status')?.errors" class="invalid-feedback">
                                                            <div *ngIf="isSbmtd && bItem?.get('material_status')?.errors?.required">
                                                                Please choose Status
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="TransType.value=='4'">
                                                        <select class="form-select form-select-sm" formControlName="material_status" style="width: 100% !important;"
                                                            [attr.disabled]="bItem?.get('as')?.value == 0 ? true : null"
                                                            [ngClass]="{ 'is-invalid': isSbmtd && bItem?.get('material_status')?.errors }">
                                                            <option value="">-Select-</option>
                                                            <option *ngFor="let sts of stDscrd" [value]="sts.id">{{sts.name}}</option>
                                                        </select>
                                                        <div *ngIf="isSbmtd && bItem?.get('material_status')?.errors" class="invalid-feedback">
                                                            <div *ngIf="isSbmtd && bItem?.get('material_status')?.errors?.required">
                                                                Please choose Status
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="TransType.value=='5'">
                                                        <select class="form-select form-select-sm" formControlName="material_status" style="width: 100% !important;"
                                                            [attr.disabled]="bItem?.get('as')?.value == 0 ? true : null"
                                                            [ngClass]="{ 'is-invalid': isSbmtd && bItem?.get('material_status')?.errors }">
                                                            <option value="">-Select-</option>
                                                            <option *ngFor="let sts of stTrnsfr" [value]="sts.id">{{sts.name}}</option>
                                                        </select>
                                                        <div *ngIf="isSbmtd && bItem?.get('material_status')?.errors" class="invalid-feedback">
                                                            <div *ngIf="isSbmtd && bItem?.get('material_status')?.errors?.required">
                                                                Please choose Status
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td *ngIf="TransType.value!='1' && TransType.value!='2' && TransType.value!='3' &&
                                                    TransType.value!='5'" class="text-center">
                                                    <div *ngIf="TransType.value!='3' && TransType.value!='4'">
                                                        {{bItem?.get('reason_id')?.value}}
                                                    </div>
                                                    <div *ngIf="TransType.value=='3' || TransType.value=='4'">
                                                        <input type="text" formControlName="reason_id"
                                                            class="form-control form-control-sm"
                                                            [ngClass]="{ 'is-invalid': isSbmtd && bItem?.get('reason_id')?.value=='' }">
                                                        <div *ngIf="isSbmtd && bItem?.get('reason_id')?.value==''"
                                                            class="invalid-feedback">
                                                            Please enter Reason
                                                        </div>
                                                    </div>
                                                </td> -->
                                                <td class="text-center">
                                                    <a *ngIf="bItem?.get('isEdit')?.value">
                                                        <i class="fa fa-trash toggle-data" (click)="onBtchActvty('d', tabIndx, batIndx)"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="TransType.value=='2' || TransType.value=='3'">
                                            <tr>
                                                <td colspan="11" class="text-left">
                                                    <button class="btn btn-sm btn-info" (click)="onBtchActvty('a', tabIndx, 0)">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-xl-12">
                                        <div class="col-sm-3">
                                            <button type="button" class="btn-light btn-sm" (click)="onClkBtch('v', tabIndx)"
                                                *ngIf="TransType.value=='2'">View Batches</button>
                                            <button type="button" class="btn-light btn-sm" (click)="onClkBtch('c', tabIndx)"
                                                *ngIf="TransType.value=='3'">Clear Quantities</button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <!-- <tbody *ngIf="lstMats.length==0">
                        <tr>
                            <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                        </tr>
                    </tbody> -->
                </table>
                <div class="table-responsive">
                    <table class="table table-striped table1 table-sm table-bordered table-design">
                        <tbody>
                            <tr>
                                <td></td>
                                <td colspan="2" [hidden]="TransType.value!='' && slctdStrId != null && slctdStrId != undefined">
                                    <span>Select Facility & Transaction type To Choose Products..</span>
                                </td>
                                <td [hidden]="slctdStrId == null || slctdStrId == undefined ||TransType.value==''" colspan="2">
                                    <div *ngIf="lstMats.length!=0">
                                        <ng-template #matItem let-model="item">
                                            <span class="font-weight-bold">{{model.productName}}</span>
                                        </ng-template>
                                        <input name="mat" autocomplete="off" placeholder="Type Material name to choose"
                                            type="text" [typeahead]="lstMats" [typeaheadItemTemplate]="matItem"
                                            typeaheadOptionField="productName" [typeaheadScrollable]="true" id="srchMtrl"
                                            class="form-control form-control-sm w-40" (typeaheadOnSelect)="slctdMat($event, 'slct')"
                                            (change)="slctdMat($event, 'chng')" formControlName="srchMtrl" [adaptivePosition]="true"
                                            [typeaheadOptionsLimit]="2500">
                                    </div>
                                    <div *ngIf="lstMats.length==0 && TransType.value=='5'">
                                        <label>Select Issuing Facility & Receiving Facility to get Materials</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="float-end">
        <button class="btn btn-sm btn-light me-2" (click)="isResetClk=true;isRsnMndtry=false;isStsMndtry=false;onReset()">Reset</button> &nbsp;
        <button class="btn btn-sm btn-primary" (click)="isSbmtd=true;onChkLL();" [disabled]="GetMatrls().controls.length==0">Submit</button>
    </div>
</form>