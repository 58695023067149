import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-power-availability',
  templateUrl: './power-availability.component.html',
  styleUrls: ['./power-availability.component.css']
})
export class PowerAvailabilityComponent extends BaseComponent implements OnInit {

  isTypeBdge = 'badge'; isFacBdge = 'badge'; lstMatTags: any[] = []; lstFacTags: any[] = []; lstFcltys: any[] = []; lstSlctdFacTags: any[] = [];
  isSbmtd = false; lstStrs: any[] = []; isManfterBdge = 'badge'; lstDts: any[] = []; lstDDts: any[] = []; lstGridDate: any[] = [];
  lstSlctdMatTags: any[] = []; lstSlctdStores: any[] = []; lstFacIds: any[] = []; slctdFac: any[] = []; slctdFacId: any[] = []; tab = 'o';
  stsLst: any[] = []; blkLst: any[] = []; dstLst: any[] = []; lstTxnTyps: any[] = []; shwStDrpdwn = false; 
  slctdStateId: any; slctdDistrictId: any; slctdBlockId: any; lstMAT: any[] = []; lstFAC: any[] = []; lstGrid: any[] = []; pLSD = ''; plED = '';
  isDtCng: string = ''; pstT = 'Monthly'; dpSDVal: any = new Date(); dpEDVal: any = new Date(); isMnthChng = false; isDayChng = false;
  txtFD = ''; txtED = ''; pstType = 'monthly';  lstDataDDL: any[] = [];  crntPage = 1; pageEntrs = ''; pageSize = environment.tblPageSize; totalItems: any;
  maxToDate: Date = new Date(); usrLvl = ''; crntDate: Date = new Date(); lstX: any[] = []; lstY: any[] = []; lstTxn: any[] = []; mBDate: any;
  astCapctyForm: FormGroup = this.fb.group({}); frmDate: any = ''; tooDate: any = ''; frmDates: any = ''; tooDates: any = ''; lstFacsTag: any;
  noRcrdTxt = environment.TblNoRcrds; ttlCount = 0;  yBDate: any; lstFacsName: any; lstDX: any[] = []; lstDY: any[] = []; astTypLst: any[] = [];
  dt2days = new Date(); manfter: any; astType: any = '1'; manfterLst: any[] = []; modelsLst: any[] = []; isManfterName: any = '2'; 
  metrcVal: any = '2';  montrngType: any = '0'; isModel: any = '18'; mntrngType: any; asstType: any; mandatryDate: any =(new Date()).getFullYear();
  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true }; ddlStngs = { singleSelection: false, idField: 'storeId', textField: 'storeName',
  selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; ttlItms: any; expDrpn: any = '1'; expDrpnDly: any = '1';
  lblTextOfDate:any = 'Max 12 months will be accepted'; yrMin : any;  ttlDayItms: any; crntDPage = 1; pageEntrsD = ''; isShwDayTable: boolean=  false;
  isRprtsHlpShw = environment.isRprtsHlpShw; astLst: any[] = []; steRes: string = ''; dstRes: string = ''; steResDly: string = ''; dstResDly: string = '';
  isWekChng = false; ttlCnt: any[] = []; ttlCntDly: any[] = []; lstCcpAll: any[] = []; isFclty: any[] = []; lstSlctdFclty: any[] = [];

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstFacTags = s.filter(f => f.badgeTypeId === environment.tagTypes.facility) }),
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstFacIds = s; }),
      this.glblSvc.sbjctMstrData.subscribe(s => {
        this.stsLst = s.statesLst, this.blkLst = s.blockLst, this.dstLst = s.dstLst, this.lstTxnTyps = s.txnTypeLst,
        this.astTypLst = s.astTypLst, this.astLst = this.astTypLst.filter(s => (s.name == 'Temperature Logger'))
      });
  }

  ngOnInit(): void {
    this.astCapctyForm = this.fb.group({
      isFacBdge: ['badge'], storeTags: [[], Validators.required], storeIds: [[], Validators.required], isManfterBdge: ['badge'],
      pstT: ['Monthly'], block: ['null'], district: [null], state: [null], dpSDVal: ['', Validators.required], dpEDVal: ['', Validators.required],
      fromDate: [''], toDate: [''], isTypeBdge: ['badge'], metricId: [], assetType: [], facilityIds: [[]] });
    // monitoringType: ['0'], manufacturerYear: ['', [Validators.required, Validators.min(environment.yomSY), Validators.max((new Date()).getFullYear())]]
    this.LoadManufacturers(); this.LoadModels(); localStorage.setItem('isPgLd', '0')
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.astCapctyForm.get('country')?.setValue(cntryId);
    } this.dmnLocation(), this.astCapctyForm.get('assetType')?.disable();
    setTimeout(() => { this.onAS(); }, 1000);
  }

  get fc() { return this.astCapctyForm.controls };
  get fgrv() { return this.astCapctyForm.getRawValue(); };

  LoadCCPs() {
    let frmCtrls = this.astCapctyForm.getRawValue(), payLoad = { stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state,  
    districtId: frmCtrls.district == 'null' ? 0 : +frmCtrls.district ?? 0, blockId: (frmCtrls.block == null || frmCtrls.block == 'null') ? 0 : 
      +frmCtrls.block}; this.lstCcpAll = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.store, 
          environment.apiMthds.strByLocns, payLoad, 0, true, 2).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstCcpAll = this.result.data != null ? this.result.data : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.astCapctyForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.astCapctyForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.astCapctyForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.astCapctyForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.astCapctyForm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.astCapctyForm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => { this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.astCapctyForm.get('state')?.setValue('null'), this.astCapctyForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }


  onChngDT(dtVal: any, ctrl: string) {
    if (localStorage.getItem('isPgLd') == '0') {
      if (this.pstT == 'Monthly') {
        if (this.isMnthChng) {
          dtVal ='', this.dpEDVal ='',  
          this.astCapctyForm.get('fromDate')?.setValue(''), this.astCapctyForm.get('toDate')?.setValue(''), this.astCapctyForm.get('dpSDVal')?.setValue(''),
          this.astCapctyForm.get('dpEDVal')?.setValue('');
          this.isMnthChng = false;
        }
        else{
          if(ctrl == 'sd' && dtVal !=null){
            this.astCapctyForm.get('dpSDVal')?.setValue(dtVal);
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)); this.yBDate = new Date(dt1.setMonth(dt1.getMonth() -1));
            this.dpSDVal  = dtVal;  this.yrMin = dtVal;
           this.astCapctyForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.MY));
           let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(this.yBDate, this.dtF.ymd));
           if (exDate < toDayDate )
           this.yBDate;
           else
             this.yBDate = new Date(this.crntDate);
             
            this.astCapctyForm.get('dpEDVal')?.setValue('');
          }
          else if(ctrl == 'ed' && dtVal !=null){
            this.dpEDVal = dtVal;
           this.astCapctyForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
           this.astCapctyForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
          }
        }
      }
      if (this.pstT == 'Weekly') {
        if (this.isWekChng) {
          dtVal='', this.dpEDVal = '', this.dpSDVal= '';
          this.astCapctyForm.get('fromDate')?.setValue(''), this.astCapctyForm.get('toDate')?.setValue(''), this.astCapctyForm.get('dpSDVal')?.setValue(''),
          this.astCapctyForm.get('dpEDVal')?.setValue('');
          this.isWekChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal = '';
          this.astCapctyForm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.astCapctyForm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 41));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);

            this.astCapctyForm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.astCapctyForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.astCapctyForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));

         }
        }
      }
      if (this.pstT == 'Daily') {
        if (this.isDayChng) {
          dtVal='', this.dpEDVal ='', this.dpSDVal='';
          this.astCapctyForm.get('fromDate')?.setValue(''), this.astCapctyForm.get('toDate')?.setValue(''), this.astCapctyForm.get('dpSDVal')?.setValue(''),
          this.astCapctyForm.get('dpEDVal')?.setValue('');
          this.isDayChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal ='';
          this.astCapctyForm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.astCapctyForm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 30));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);

            this.astCapctyForm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.astCapctyForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.astCapctyForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));

         }
        }
      }
    }
    let dtFrmt = this.pstT == 'Monthly' ? this.dtF.MY : this.dtF.DMY_;
    this.txtFD = this.glblSvc.setDtFrmt(this.dpSDVal, dtFrmt), this.txtED = this.glblSvc.setDtFrmt(this.dpEDVal, dtFrmt);
  }

  onTabClk(tabType: string) {
    this.tab = tabType, this.lstGrid = [], this.crntPage=1, this.crntDPage = 1, this.GenRprt(); this.isSbmtd = true;
  }

  setPage(pagNo: number, type: any): void {
    if ( type == 'dm') 
      this.crntPage = pagNo, this.GenRprt();
    if ( type == 'dd')
      this.crntDPage = pagNo, this.GetDailyData('', 'g');
  }

  onChngState(evnt: any) {
    if (evnt)
      this.shwStDrpdwn = this.stsLst.filter((a: { name: any; }) => a.name == evnt.target.value).length > 0;
  }

  onClkRdbs(ctrl: string, val: any, ctrlId: string) {
    this.astCapctyForm.get(ctrl)?.setValue(val), setTimeout(() => { $(`#${ctrlId}`).val(''); }, 200);
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.astCapctyForm.get('district')?.setValue(null),
          this.astCapctyForm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [], this.astCapctyForm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
        replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (type == 'state')
                this.dstLst = this.result.data != null ? this.result.data : [], this.astCapctyForm.get('district')?.enable(),
                  this.astCapctyForm.get('block')?.disable();
              else
                this.blkLst = this.result.data != null ? this.result.data : [], this.astCapctyForm.get('block')?.enable();
            }
          }
        })
    }
    else {
      if (type == 'state')
        this.astCapctyForm.get('district')?.setValue('null'), this.astCapctyForm.get('block')?.setValue('null'),
          this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('block')?.disable();
      else
        this.astCapctyForm.get('block')?.setValue('null'), this.astCapctyForm.get('block')?.disable();
    }
    type == 'district' ? this.LoadCCPs() : '';
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.astCapctyForm.get('state')?.enable(), this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.astCapctyForm.get('state')?.disable(), this.astCapctyForm.get('district')?.enable(), this.astCapctyForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.astCapctyForm.get('state')?.disable(), this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.astCapctyForm.get('state')?.disable(), this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('block')?.disable();
  }

  onChngPstT() {
    this.astCapctyForm.get('dpSDVal')?.setValue(''); this.lblTextOfDate = this.pstT=='Monthly' ? 'Max 12 months will be accepted' : 
      (this.pstT=='Daily' ? 'Max 31 days will be accepted': 'Max 42 days will be accepted');
    this.pstType = this.pstT.toLowerCase(), this.isMnthChng = this.pstT == 'Monthly', this.isWekChng = this.pstT == 'Weekly', this.isDayChng = this.pstT == 'Daily', this.dpSDVal='', this.dpEDVal='';
  }

  GenRprt(type: string = 'g') {
    this.checkFormValidations(this.astCapctyForm); let formCtrls = this.astCapctyForm.getRawValue(); this.lstGridDate = [];
    // this.mntrngType = (this.astCapctyForm.controls.monitoringType.value == '0' ? 'All' : (this.astCapctyForm.controls.monitoringType.value == '1' ?
    //    'Monitoring' : (this.astCapctyForm.controls.monitoringType.value == '2' ? 'Monitored' : '')));
    if ((this.fc.storeTags.value != '' || this.fc.storeIds.value != '') && this.fc.dpSDVal.value != '' && this.fc.dpEDVal.value != ''  ) {
      if (formCtrls.fromDate == null || formCtrls.fromDate == '' || formCtrls.fromDate == undefined)
        this.astCapctyForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
        else {
          if (this.dpSDVal)
            this.astCapctyForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
        }
        if (formCtrls.toDate == null || formCtrls.toDate == '' || formCtrls.toDate == undefined)
          this.astCapctyForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
      else {
        if (this.dpEDVal  &&  this.pstT == 'Monthly'){
          if(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd_) == this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd_)){
            this.astCapctyForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd));
          }
          else {
            let dt = this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd), slctDte: any = dt.split('-');
           let dts = this.GetMonthDates(+slctDte[1], +slctDte[0]),
           dtdts = dts.split('...');
           this.astCapctyForm.get('toDate')?.setValue(dtdts[1]);
          }
        }
        else{
          this.astCapctyForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
        }
      }
      let payLoad: any = this.astCapctyForm.getRawValue(), fT: any[] = [], sN: any[] = [], fTs: any[] = [], lstFacsTags: any[] = [], lstFacsNames: any[] = [];
      this.isDtCng = this.pstT; 
      // this.frmDate = payLoad.fromDate, this.tooDate = payLoad.toDate,
      this.frmDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt(new Date(payLoad.fromDate), this.dtF.MY)) : 
      (this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.dmy))), this.tooDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt
      (new Date(payLoad.toDate), this.dtF.MY)) : (this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.dmy))),
      this.lstSlctdFacTags?.forEach((e: any) => { lstFacsTags.push(e.name) }), this.lstFacsTag = lstFacsTags;
      this.lstSlctdStores?.forEach((e: any) => { lstFacsNames.push(e.storeName) }), this.lstFacsName = lstFacsNames;
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
      payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.metricId = (payLoad.metricId != '' &&
      payLoad.metricId != null && payLoad.metricId != undefined) ? +payLoad.metricId : null, payLoad.facilityIds =
      (payLoad.facilityIds != '' && payLoad.facilityIds != null && payLoad.facilityIds != undefined && this.tab == 'f' &&
      (payLoad.district != 'null' || payLoad.block != 'null')) ? this.lstSlctdFclty?.forEach((e: any) => { fTs.push(e.storeId) }) : [], 
      payLoad.facilityIds = fTs,
      // payLoad.manufacturerYear = (payLoad.manufacturerYear != '' && payLoad.manufacturerYear != null && payLoad.manufacturerYear != undefined) ? +payLoad.manufacturerYear : null,
      payLoad.assetType = (payLoad.assetType != '' && payLoad.assetType != null && payLoad.assetType != undefined) ? +payLoad.assetType : null,
      // payLoad.monitoringType = (payLoad.monitoringType != '' && payLoad.monitoringType != null && payLoad.monitoringType != undefined) ? +payLoad.monitoringType : null,
      payLoad.storeTags = payLoad.storeTags != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [],
      payLoad.storeTags = fT; payLoad.storeIds = payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeId) }) : [],
      payLoad.storeIds = sN, payLoad.email =this.usrLgnDtls?.email,
      ['pstT', 'dpSDVal', 'dpEDVal', 'state', 'district', 'block', 'isTypeBdge', 'isFacBdge', 'isManfterBdge'].forEach(e => { delete payLoad[e] });
      if (type == 'g')
        this.isShwDayTable = false, this.onGen(payLoad);
      else
        this.tab == 'f' ? (payLoad.exportLevel = this.expDrpn !='' ? this.expDrpn : '') : '', this.onEE(payLoad);
    }
    else
      this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
  }

  onGen(payLoad: any) {
    this.lstGrid = [], this.ttlCnt = [], this.ttlCntDly = [], this.lstX = [], this.lstY = [], this.lstMAT = [], this.lstFAC = [],
    this.ttlCount = 0; let result: any[] = [], cnt: any[] = [], crntPgIndx = this.crntPage - 1;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.reports, environment.apiMthds.astPwrAvlblty.
      replace('ddd', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'f' ? '2' : '3')}`).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`),
      payLoad, 6, true, 2).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result?.data) {
              if (this.tab == 'o')
                this.lstGrid = this.result?.data.powerAvailabilityDTOs ?? [], this.lstTxn = this.result?.data?.txnCountDTo ?? [], this.lstDts = [];
              else {
                let data = this.result?.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
                if (data) {
                  this.ttlItms = this.result.data?.size ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.ttlItms),
                  Object.keys(data.powerAvailabilityMap).forEach((s: any, i: number) => {
                    let fName = `${s}`
                    if(i==0){
                      data.powerAvailabilityMap[s].forEach((e: any) => { hdrs.push(e.date)});
                    }
                    if (data) {
                      data.powerAvailabilityMap[s].forEach((e: any) => { cnt.push(e.date) });
                      result.push({ kName: fName, hdrs: hdrs, cnt: cnt, matData: data.powerAvailabilityMap[s] });
                    }
                  });
                  data.dateAndCount?.forEach((s: any) => this.ttlCnt.push(s.count));
                  this.lstGrid = result, this.steRes = this.lstGrid[0]?.matData[0]?.state, 
                  this.dstRes = this.lstGrid[0]?.matData[0].district;
                
                }
                else if (data == null)
                  this.glblSvc.onTstr('i', this.result.message);
                else
                  this.glblSvc.onTstr('e', this.result.message);
                this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGrid); //this.glblSvc.sbjctTblDataCnt.next(data?.totalRecordsCount ?? 0);
                // if (result.length == 0)
                //   this.glblSvc.onTstr('i', 'No data found');
              }
            }
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        });
  }
  
  onCncl() {
    this.lblTextOfDate = 'Max 12 months will be accepted'; this.dpEDVal ='', this.dpSDVal='', this.isShwDayTable =false,
    this.lstSlctdFacTags = [...[]], this.lstSlctdStores = [...[]], this.slctdFac = [], this.slctdFacId = [], //this.montrngType = '0',
    this.astType = '1', this.isManfterName = '2', this.metrcVal = '2'; this.astCapctyForm.get('isTypeBdge')?.setValue('badge'),
    this.astCapctyForm.get('isFacBdge')?.setValue('badge'), this.astCapctyForm.get('isManfterBdge')?.setValue('badge'),
    this.astCapctyForm.get('storeTags')?.setValue([]),this.astCapctyForm.get('storeIds')?.setValue([]), this.ttlCnt = [], this.ttlCntDly = [],
    // this.astCapctyForm.get('monitoringType')?.setValue(['0']),this.astCapctyForm.get('manufacturerYear')?.setValue([]), 
    this.astCapctyForm.get('assetType')?.setValue(['1']), this.astCapctyForm.get('manufacturerId')?.setValue([]),
    this.astCapctyForm.get('metricId')?.setValue(['2']), this.astCapctyForm.get('pstT')?.setValue('Monthly'),
    this.astCapctyForm.get('dpEDVal')?.setValue(''), this.astCapctyForm.get('dpSDVal')?.setValue(''); this.expDrpnDly = '1',
    this.astCapctyForm.get('fromDate')?.setValue(''), this.astCapctyForm.get('toDate')?.setValue(''); this.expDrpn = '1',
    this.astCapctyForm.get('facilityIds')?.setValue([]), this.lstCcpAll = [], this.isFclty = [], this.lstSlctdFclty = [...[]],
    this.dmnLocation(), setTimeout(() => { this.onAS()}, 500); this.steRes = '', this.dstRes = '', this.steResDly = '', this.dstResDly = '';
  }

  onClkRdb(ctrl: string) {
    // if (ctrl == 'monitoringType')
    //   this.fc?.assetType.setValue([]), this.astType = '2', this.fc?.assetType?.clearValidators(); //this.fc?.monitoringType?.setValidators([Validators.required]);
    // else if (ctrl == 'assetType')
    //   this.fc?.monitoringType.setValue([]), this.montrngType = '0', this.fc?.monitoringType?.clearValidators(), //this.fc?.assetType?.setValidators([Validators.required]),
    //     this.fc?.monitoringType?.updateValueAndValidity(), this.fc?.assetType?.updateValueAndValidity();
    // else 
    if (ctrl == 'storeTags')
      this.fc?.storeIds.setValue([]),
        this.fc?.storeIds?.clearValidators(), this.fc?.storeTags?.setValidators([Validators.required]), this.lstSlctdStores = [...[]];
    else
      this.fc?.storeTags.setValue([]), this.fc?.storeTags?.clearValidators(), this.fc?.storeIds?.setValidators([Validators.required]),
        this.fc?.storeTags?.updateValueAndValidity(), this.fc?.storeIds?.updateValueAndValidity(), this.lstSlctdFacTags = [...[]];
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      } else if (ctrl == 'fc') {
          if (this.lstSlctdFclty.filter((f: { storeId: any; }) => f.storeId === item.storeId).length == 0)
            this.lstSlctdFclty.push(item);
      } else{
       if (this.lstSlctdStores.filter((f: { storeIds: any; }) => f.storeIds === item.storeId).length == 0)
      this.lstSlctdStores.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'fc')
        this.lstSlctdFclty = this.lstSlctdFclty.filter(f => f.storeId != item.storeId);
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstSlctdStores.filter(f => f.storeId != item.storeId);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else if (ctrl == 'fc')
        this.lstSlctdFclty = this.lstCcpAll;
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstFacIds;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else if (ctrl == 'fc')
        this.lstSlctdFclty = [];
      else if (ctrl == 'c')
        this.lstSlctdStores = [];
    }
  }

  // LoadStrName() {
  //   let frmCtrls = this.astCapctyForm.getRawValue(), payLoad = { stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state, districtId:
  //       frmCtrls.district == 'null' ? 0 : +frmCtrls.district }; this.slctdFacId = [], this.lstFacIds = [...[]], this.lstSlctdStores = [...[]],
  //     this.FetchData(environment.apiCalls.post, environment.apiPaths.ccp, environment.apiMthds.ccpStore, payLoad, 0, true, 2).then(() => {
  //       if (this.result) {
  //         if (this.result.status == 'OK') {
  //           if (this.result.data)
  //             this.lstFacIds = this.result.data != null ? this.result.data : [];
  //           else
  //             this.glblSvc.onTstr('W', this.result.message);
  //         }
  //       }
  //     });
  // }

  LoadManufacturers() {
    this.manfterLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetVendor, environment.apiMthds.getAll,
      null).then(() => {
        if (this.result) {
          if (this.result.data)
            this.manfterLst = this.result.data != null ? this.result.data : [];
        }
      })
  }

  LoadModels() {
    this.modelsLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetMdl, environment.apiMthds.getAll,
      null).then(() => {
        if (this.result) {
          if (this.result.data)
            this.modelsLst = this.result.data != null ? this.result.data : [];
        }
      })
  }

  onEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.reports, environment.apiMthds.astPwrAvbltyExprt.
      replace('ddd', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).replace('ttt', `${this.tab == 'o' ? '1' :
        (this.tab == 'f' ? '2' :'3')}`), payLoad, 6, true, 2).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  GetDailyData(dtt: any, type: string='g') {
    if ((this.pstType == 'monthly' || this.pstType == 'weekly') && dtt != 'Total Count') {
      let dtEND: any, maxDate: any, sm: any, frmDte: any = '';
      if (dtt !='') {
        sm = dtt, this.glblSvc.sbjctExpt.next(dtt);
      } else {
        this.glblSvc.sbjctExpt.subscribe(s => { sm = s });
      }
      if (this.pstType == 'monthly') {
        dtEND = this.glblSvc.setDtFrmt(this.dt2days, this.dtF.ymd);  let mn = sm.split("-");
        let mnth = this.mnths.filter(m => m.name == mn[0]), v = this.lstMnths.find(month => month.val == mnth[0].val),
        fromDateDay = mn[1] + '-' + v?.val + '-01', toDateDay = mn[1] + '-' + v?.val + '-' + v?.days; frmDte = fromDateDay;
        if (dtEND < toDateDay) {
          maxDate = dtEND;
        } else {
          maxDate = toDateDay;
        }
      }
      if (this.pstType == 'weekly') {
        frmDte = '', maxDate = '';
        let dt = sm.split('('), frTo: any = dt[1], frToDly: any; frTo = frTo.substring(0, frTo.length-1), frToDly = frTo.split(' '),
          frmDte = frToDly[0], maxDate = frToDly[2];
      }
      let payLoad: any = this.astCapctyForm.getRawValue(), fT: any[] = [], fTs: any[] = [], mN: any[] = [], sN: any[] = [], tc: any[] = [];
      this.frmDates = this.pstType == 'weekly' ? frmDte: this.glblSvc.setDtFrmt(frmDte, this.dtF.dmy), this.tooDates = this.pstType == 'weekly' ?
        maxDate: this.glblSvc.setDtFrmt(maxDate, this.dtF.dmy), payLoad.fromDate = this.pstType == 'weekly' ? this.glblSvc.setDtFrmt(frmDte, 
          this.dtF.dmy): frmDte , payLoad.toDate = this.pstType == 'weekly' ? this.glblSvc.setDtFrmt(maxDate, this.dtF.ymd) : maxDate,
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
      payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.metricId = (payLoad.metricId != '' &&
      payLoad.metricId != null && payLoad.metricId != undefined) ? +payLoad.metricId : null,payLoad.facilityIds =
      (payLoad.facilityIds != '' && payLoad.facilityIds != null && payLoad.facilityIds != undefined && this.tab == 'f' &&
      (payLoad.district != 'null' || payLoad.block != 'null')) ? this.lstSlctdFclty?.forEach((e: any) => { fTs.push(e.storeId) }) : [], 
      payLoad.facilityIds = fTs, 
      // payLoad.manufacturerYear = (payLoad.manufacturerYear != '' && payLoad.manufacturerYear != null && payLoad.manufacturerYear != undefined) ? +payLoad.manufacturerYear : null,
      payLoad.assetType = (payLoad.assetType != '' && payLoad.assetType != null && payLoad.assetType != undefined) ? +payLoad.assetType : null,
      // payLoad.monitoringType = (payLoad.monitoringType != '' && payLoad.monitoringType != null && payLoad.monitoringType != undefined) ? +payLoad.monitoringType : null,
      payLoad.storeTags = payLoad.storeTags != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [],
      payLoad.storeTags = fT; payLoad.storeIds = payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeId) }) : [],
      payLoad.storeIds = sN, payLoad.email =this.usrLgnDtls?.email,
      ['pstT', 'dpSDVal', 'dpEDVal', 'state', 'district', 'block', 'isTypeBdge', 'isFacBdge', 'isManfterBdge'].forEach(e => { delete payLoad[e] });
      if(type=='g')
        this.isShwDayTable = true, this.onGenDaysData(payLoad);
      else
        this.tab == 'f' ? (payLoad.exportLevel = this.expDrpnDly !='' ? this.expDrpnDly : '') : '', this.onDaysEE(payLoad);
    }
  }

  onGenDaysData(payLoad: any) {
    this.lstGridDate = []; this.ttlCntDly = []; let crntPgIndx = this.crntDPage - 1;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.reports, environment.apiMthds.astPwrAvlblty.
      replace('ddd', 'd').replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'f' ? '2' : '3')}`).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`),
      payLoad, 6, true, 2).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result?.data) {
              if (this.tab == 'o')
                this.lstGridDate = this.result?.data.powerAvailabilityDTOs ?? [], this.lstTxn = this.result?.data?.txnCountDTo ?? [], this.lstDDts = [];
              else {
                let data = this.result?.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [], cnt: any[] = [];
                if (data) {
                this.ttlDayItms = this.result.data?.size ?? 0, this.pageEntrsD = this.GetPageSizeTxt(this.crntDPage, this.pageSize, this.ttlDayItms),
                  Object.keys(data.powerAvailabilityMap).forEach((s: any, i: number) => {
                    let fName = `${s}`
                    if(i==0){
                      data.powerAvailabilityMap[s].forEach((e: any) => {hdrs.push((e.date))});
                    }
                    if (data) {
                      data.powerAvailabilityMap[s].forEach((e: any) => { cnt.push(e.date) });
                      result.push({ kName: fName, hdrs: hdrs, cnt: cnt, matData: data.powerAvailabilityMap[s] });
                      this.lstGridDate = result,this.steResDly = this.lstGridDate[0]?.matData[0]?.state,
                      this.dstResDly = this.lstGridDate[0]?.matData[0].district;
                    }
                  });
                  data.dateAndCount?.forEach((s: any) => this.ttlCntDly.push(s.count));
                }
                else if (data == null)
                  this.glblSvc.onTstr('i', this.result.message);
                else
                  this.glblSvc.onTstr('e', this.result.message);
                this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGridDate); //this.glblSvc.sbjctTblDataCnt.next(data?.totalRecordsCount ?? 0);
                if (result.length == 0)
                  this.glblSvc.onTstr('i', 'No data found');
              }
            }
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
      });
  }
  onDaysEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.reports, environment.apiMthds.astPwrAvbltyExprt.
      replace('ddd', `${'d'}`).replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'f' ? '2' :'3')}`), payLoad, 6, true, 2).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

}
