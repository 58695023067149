import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/Services/global.service';
import { ReportsHelp } from 'src/assets/ts/reports-help';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'evin-reports-help',
  templateUrl: './reports-help.component.html',
  styleUrls: ['./reports-help.component.css']
})
export class ReportsHelpComponent extends BaseComponent implements OnInit {

  hlpTxt: any;

  constructor(public glblSvc: GlobalService, public router: Router) { super(glblSvc); }

  ngOnInit(): void {    
    this.chseHlpTxt(this.router.url);
  }

  printHelp() { window.print(); }

  chseHlpTxt(path: any) {
    if (path.includes('trans-counts'))
      this.hlpTxt = ReportsHelp.txnCounts;
    else if (path.includes('dmn-actvty'))
      this.hlpTxt = ReportsHelp.dmnActvty;
    else if (path.includes('act-rate'))
      this.hlpTxt = ReportsHelp.actRate;
    else if (path.includes('user-activity'))
      this.hlpTxt = ReportsHelp.usrActvty;
    else if (path.includes('abnormal-stock'))
      this.hlpTxt = ReportsHelp.abnrmlStk;
    else if (path.includes('discards'))
      this.hlpTxt = ReportsHelp.dscrds;
    else if (path.includes('stock-availability'))
      this.hlpTxt = ReportsHelp.stkAvlblty;
    else if (path.includes('stks-rprts'))
      this.hlpTxt = ReportsHelp.StkReport;
    else if (path.includes('consumption'))
      this.hlpTxt = ReportsHelp.Consumption;
    else if (path.includes('stock-trends'))
      this.hlpTxt = ReportsHelp.stkTrnds;
    else if (path.includes('ord-res-time'))
      this.hlpTxt = ReportsHelp.indntRspnseTmeRprt;
    else if (path.includes('rep-asset-status'))
      this.hlpTxt = ReportsHelp.cceMngmntStsRprt;
    else if (path.includes('power-availability'))
      this.hlpTxt = ReportsHelp.pwrAvlblty;
    else if (path.includes('rspns-tm-rpar'))
      this.hlpTxt = ReportsHelp.rspnsTmeToRpr;
    else if (path.includes('up-time'))
      this.hlpTxt = ReportsHelp.upTme;
    else if (path.includes('discrepancies'))
      this.hlpTxt = ReportsHelp.indntDscrpncy;
    else if (path.includes('temp-excursion'))
      this.hlpTxt = ReportsHelp.tmprtrExcrsn;
    else
      window.close();
  }

}
