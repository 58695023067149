import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

 declare let $: any;
@Component({
  selector: 'evin-stock-availability',
  templateUrl: './stock-availability.component.html',
  styleUrls: ['./stock-availability.component.css']
})
export class StockAvailabilityComponent extends BaseComponent implements OnInit {

  isMatBdge = 'badge'; isFacBdge = 'badge'; lstMatTags: any[] = []; lstFacTags: any[] = []; lstAllMats: any[] = []; lstFcltys: any[] = [];
  slctdMat: any[] = []; slctdMatName: any = '11'; lstSlctdMatName: any = []; isSbmtd = false; lstSlctdFacTags: any[] = []; lstStrs: any[] = [];
  lstSlctdStores: any[] = []; lstFacIds: any[]= []; slctdFac: any[] = []; slctdFacId: any[] = []; slctMetric = '1'; lblAvlblty: any = '100';
  isLocOptn = 'state'; stsLst: any[] = []; blkLst: any[] = []; dstLst: any[] = []; lstTxnTyps: any[] = []; shwStDrpdwn = false; totalItems: any;
  slctdStateId: any; slctdDistrictId: any; slctdBlockId: any;  lstMAT: any[] = []; lstFAC: any[] = []; lstGrid: any[] = []; isDtCng:string= '';
  pstT = 'Monthly'; dpSDVal: any = new Date(); dpEDVal: any = new Date();  crntPage = 1; pageEntrs = ''; pageSize = environment.tblPageSize;
  pLSD = ''; plED = ''; isMnthChng = false; isDayChng = false; txtFD = ''; txtED = ''; pstType = 'monthly'; tab = 'o'; lstDataDDL: any[] = [];
  maxToDate: Date = new Date(); crntDate: Date = new Date(); fD2 = ''; eD2 = '';  lstX: any[] = []; lstY: any[] = []; lstTxn: any[] = [];
  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true }; ddlStngs = { singleSelection: false, idField: 'storeId', textField: 'storeName',
  selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; stkAvlbltyFrm: FormGroup = this.fb.group({});
  matDropdownSettings = { singleSelection: true, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true }; usrLvl = ''; txnType: any; frmDate: any = ''; tooDate: any = ''; frmDates: any = '';
  tooDates: any = ''; slctTxnType: any =''; slctTxnTypes: any =''; noRcrdTxt = environment.TblNoRcrds; ttlCount = 0; mBDate: any; monSplit: any;
  lstMatsTag: any; lstMatsName: any; lstFacsTag: any; yBDate : any; lstFacsName: any; lstDX : any[] = []; lstDY : any[] = [];
  lstGridDate : any[] =[]; dt2days = new Date(); lstDts: any[] = []; lstDDts: any[] = []; yrMin : any; ttlItms: any; expDrpnDly: any = '1';
  ttlDayItms: any; lblTextOfDate:any = 'Max 12 months will be accepted';  crntDPage = 1; pageEntrsD = ''; isShow: boolean = false;
  isRprtsHlpShw = environment.isRprtsHlpShw; expDrpn: any = '1'; isWekChng = false;

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product),
      this.lstFacTags = s.filter(f => f.badgeTypeId === environment.tagTypes.facility)}),
      this.glblSvc.sbjctStrList.subscribe(s => { this.lstFacIds = s; }), 
      this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst,
        this.lstTxnTyps = s.txnTypeLst, this.dstLst = s.dstLst, this.lstAllMats = s.allMats; });
  }

  ngOnInit(): void {
    this.stkAvlbltyFrm = this.fb.group({ isMatBdge: ['badge'], isFacBdge: ['badge'], productId: ['', Validators.required], userName: [''],
    storeTags: [[], Validators.required], storeIds: [[], Validators.required], isLocOptn: ['state'], pstT: ['Monthly'],  state: [null],
    block: ['null'], district: [null], dpSDVal: ['', Validators.required], dpEDVal: ['', Validators.required], metricId: [null], fromDate: [''],
    toDate: [''], labelAvailibility: [''] }); localStorage.setItem('isPgLd', '0'); this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.stkAvlbltyFrm.get('country')?.setValue(cntryId);
    }
    this.dmnLocation(), setTimeout(() => { this.onAS(); }, 1000);
  }

  get fc(){ return this.stkAvlbltyFrm.controls };
  get fgrv() { return this.stkAvlbltyFrm.getRawValue(); };

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.stkAvlbltyFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.stkAvlbltyFrm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.stkAvlbltyFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.stkAvlbltyFrm.get('district')?.disable(), this.stkAvlbltyFrm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.stkAvlbltyFrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.stkAvlbltyFrm.get('district')?.disable(), this.stkAvlbltyFrm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.stkAvlbltyFrm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.stkAvlbltyFrm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => { this.stkAvlbltyFrm.get('district')?.disable(), this.stkAvlbltyFrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.stkAvlbltyFrm.get('state')?.setValue('null'), this.stkAvlbltyFrm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }
  
  onChngDT(dtVal: any, ctrl: string) {
    if (localStorage.getItem('isPgLd') == '0') {
      if (this.pstT == 'Monthly') {
        if (this.isMnthChng) {
          dtVal ='', this.dpEDVal ='',  
          this.stkAvlbltyFrm.get('fromDate')?.setValue(''), this.stkAvlbltyFrm.get('toDate')?.setValue(''), this.stkAvlbltyFrm.get('dpSDVal')?.setValue(''),
          this.stkAvlbltyFrm.get('dpEDVal')?.setValue('');
          this.isMnthChng = false;
        }
        else{
          if(ctrl == 'sd' && dtVal !=null){
            this.stkAvlbltyFrm.get('dpSDVal')?.setValue(dtVal);
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)); this.yBDate = new Date(dt1.setMonth(dt1.getMonth() -1));
            this.dpSDVal  = dtVal;  this.yrMin = dtVal;
           this.stkAvlbltyFrm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.MY));
           let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(this.yBDate, this.dtF.ymd));
           if (exDate < toDayDate )
           this.yBDate;
           else
             this.yBDate = new Date(this.crntDate);
             
            this.stkAvlbltyFrm.get('dpEDVal')?.setValue('');
          }
          else if(ctrl == 'ed' && dtVal !=null){
            this.dpEDVal = dtVal;
           this.stkAvlbltyFrm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
           this.stkAvlbltyFrm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
          }
        }
      }
      if (this.pstT == 'Weekly') {
        if (this.isWekChng) {
          dtVal='', this.dpEDVal = '', this.dpSDVal= '';
          this.stkAvlbltyFrm.get('fromDate')?.setValue(''), this.stkAvlbltyFrm.get('toDate')?.setValue(''), this.stkAvlbltyFrm.get('dpSDVal')?.setValue(''),
          this.stkAvlbltyFrm.get('dpEDVal')?.setValue('');
          this.isWekChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal = '';
          this.stkAvlbltyFrm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.stkAvlbltyFrm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 41));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);
            this.stkAvlbltyFrm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.stkAvlbltyFrm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.stkAvlbltyFrm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
         }
        }
      }
      if (this.pstT == 'Daily') {
        if (this.isDayChng) {
          dtVal='', this.dpEDVal ='', this.dpSDVal='';
          this.stkAvlbltyFrm.get('fromDate')?.setValue(''), this.stkAvlbltyFrm.get('toDate')?.setValue(''), this.stkAvlbltyFrm.get('dpSDVal')?.setValue(''),
          this.stkAvlbltyFrm.get('dpEDVal')?.setValue('');
          this.isDayChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal ='';
          this.stkAvlbltyFrm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.stkAvlbltyFrm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 30));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);
            this.stkAvlbltyFrm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.stkAvlbltyFrm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.stkAvlbltyFrm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
         }
        }
      }
    }
    let dtFrmt = this.pstT == 'Monthly' ? this.dtF.MY : this.dtF.DMY_;
    this.txtFD = this.glblSvc.setDtFrmt(this.dpSDVal, dtFrmt), this.txtED = this.glblSvc.setDtFrmt(this.dpEDVal, dtFrmt);
  }

  onTabClk(tabType: string) {
    this.tab = tabType, this.lstGrid = [], this.crntPage=1, this.crntDPage = 1, this.isSbmtd = true, this.GenRprt();
  }

  setPage(pagNo: number, type: any): void {
    if ( type == 'dm') 
      this.crntPage = pagNo, this.GenRprt();
    if ( type == 'dd')
      this.crntDPage = pagNo, this.GetDailyData('', 'g');
  }

  onChngState(evnt: any) {
    if (evnt)
      this.shwStDrpdwn = this.stsLst.filter((a: { name: any; }) => a.name == evnt.target.value).length > 0;
  }
  
  onClkRdbs(ctrl: string, val: any, ctrlId: string) {
    this.stkAvlbltyFrm.get(ctrl)?.setValue(val), setTimeout(() => { $(`#${ctrlId}`).val(''); }, 200);
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.stkAvlbltyFrm.get('district')?.setValue(null),
          this.stkAvlbltyFrm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [],this.stkAvlbltyFrm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.stkAvlbltyFrm.get('district')?.enable(),
                this.stkAvlbltyFrm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.stkAvlbltyFrm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.stkAvlbltyFrm.get('district')?.setValue('null'), this.stkAvlbltyFrm.get('block')?.setValue('null'),
          this.stkAvlbltyFrm.get('district')?.disable(), this.stkAvlbltyFrm.get('block')?.disable();
      else
        this.stkAvlbltyFrm.get('block')?.setValue('null'), this.stkAvlbltyFrm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.stkAvlbltyFrm.get('state')?.enable(), this.stkAvlbltyFrm.get('district')?.disable(), this.stkAvlbltyFrm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.stkAvlbltyFrm.get('state')?.disable(), this.stkAvlbltyFrm.get('district')?.enable(), this.stkAvlbltyFrm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.stkAvlbltyFrm.get('state')?.disable(), this.stkAvlbltyFrm.get('district')?.disable(), this.stkAvlbltyFrm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.stkAvlbltyFrm.get('state')?.disable(), this.stkAvlbltyFrm.get('district')?.disable(), this.stkAvlbltyFrm.get('block')?.disable();
  }

  onChngPstT() {
    this.lblTextOfDate = this.pstT=='Monthly' ? 'Max 12 months will be accepted' : (this.pstT=='Daily' ? 
      'Max 31 days will be accepted' :'Max 42 days will be accepted'); this.stkAvlbltyFrm.get('dpSDVal')?.setValue('');
   this.pstType = this.pstT.toLowerCase(), this.isMnthChng = this.pstT == 'Monthly', this.isDayChng = this.pstT == 'Daily', this.dpSDVal='', this.dpEDVal='';
  }

  GenRprt(type: string = 'g'){
    let formCtrls = this.stkAvlbltyFrm.getRawValue();
    (formCtrls?.metricId == '1' && this.lblAvlblty == '100') ? (this.stkAvlbltyFrm.get('labelAvailibility')?.setValue(null),
      this.lblAvlblty = null) :(formCtrls?.metricId == '3' && this.lblAvlblty == null ? (this.lblAvlblty = '100',
      this.stkAvlbltyFrm.get('labelAvailibility')?.setValue('100')): (this.stkAvlbltyFrm.get('labelAvailibility')?.value, this.lblAvlblty));
    // formCtrls?.metricId == '1' ? formCtrls.labelAvailibility?.value = null: this.lblAvlblty = '100';
    // this.lstTxnTyps.filter((e => {if(e.id == this.stkAvlbltyFrm?.value.metricId) { this.slctTxnType = e.name} }));
    if ((this.fc.storeTags.value != '' || this.fc.storeIds.value != '') && this.fc.dpSDVal.value != '' && this.fc.dpEDVal.value != '') {
      if (formCtrls.fromDate == null || formCtrls.fromDate == '' || formCtrls.fromDate == undefined)
        this.stkAvlbltyFrm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      else {
        if (this.dpSDVal)
          this.stkAvlbltyFrm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      }
      if (formCtrls.toDate == null || formCtrls.toDate == '' || formCtrls.toDate == undefined)
        this.stkAvlbltyFrm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
      else {
        if (this.dpEDVal && this.pstT == 'Monthly') {
          if (this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd_) == this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd_)) {
            this.stkAvlbltyFrm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd));
          }
          else {
            let dt = this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd), slctDte: any = dt.split('-');
            let dts = this.GetMonthDates(+slctDte[1], +slctDte[0]), dtdts = dts.split('...');
            this.stkAvlbltyFrm.get('toDate')?.setValue(dtdts[1]);
          }
        } else {
          this.stkAvlbltyFrm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
        }
      }
      let payLoad: any = this.stkAvlbltyFrm.getRawValue(), fT: any[] = [], mN: any[] = [], sN: any[] = [], lstMatsTags: any[] = [],
        lstMatsNames: any[] = [], lstFacsTags: any[] = [], lstFacsNames: any[] = []; this.isDtCng = this.pstT;
      this.frmDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt(new Date(payLoad.fromDate), this.dtF.MY)) :
        (this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.dmy))), this.tooDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt
          (new Date(payLoad.toDate), this.dtF.MY)) : (this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.dmy))),
        this.lstSlctdMatName?.forEach((e: any) => { lstMatsNames.push(e.name) }), this.lstMatsName = lstMatsNames;
      this.lstSlctdFacTags?.forEach((e: any) => { lstFacsTags.push(e.name) }), this.lstFacsTag = lstFacsTags;
      this.lstSlctdStores?.forEach((e: any) => { lstFacsNames.push(e.storeName) }), this.lstFacsName = lstFacsNames;
      payLoad.fromDate = payLoad.fromDate != '' ? payLoad.fromDate : null, payLoad.toDate = payLoad.toDate != '' ? payLoad.toDate : null,
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
      payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.email = this.usrLgnDtls.email,
      payLoad.productId = (payLoad.productId != '' && payLoad.productId != null && payLoad.productId != undefined) ? +payLoad.productId : null,
      payLoad.storeTags = payLoad.storeTags != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT;
      payLoad.storeIds = payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeId) }) : [], payLoad.storeIds = sN;
      payLoad.userName = this.usrLgnDtls.userName, payLoad.metricId = (payLoad.metricId != '' && payLoad.metricId != null &&
      payLoad.metricId != undefined) ? +payLoad.metricId : null, payLoad.labelAvailibility = (payLoad.labelAvailibility != '' &&
      payLoad.labelAvailibility != null && payLoad.labelAvailibility != undefined) ? +payLoad.labelAvailibility : null,
        ['pstT', 'dpSDVal', 'dpEDVal', 'state', 'district', 'block', 'isMatBdge', 'isFacBdge', 'isLocOptn'].forEach(e => { delete payLoad[e] });
      if (type == 'g')
        this.isShow = false, this.onGen(payLoad);
      else 
        this.tab == 'f' ? (payLoad.exportLevel = this.expDrpn !='' ? this.expDrpn : '') : '', this.onEE(payLoad);
    }
    else
      this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
  }

  onGen(payLoad: any) {
    this.lstGrid = [], this.lstGridDate = [], this.lstX = [], this.lstY = [], this.lstMAT = [], this.lstFAC = [], this.ttlCount = 0;
    let tc: any[] = [], crntPgIndx = this.crntPage - 1;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.stkAvlblty, environment.apiMthds.stkAvbltyRpt.
        replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`).replace('ppp', `${this.chsnDmnId}`).
        replace('ddd', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).replace('ttt', `${this.tab == 'o' ? '1' : '3'}`), payLoad, 4).then(() => {
            if (this.result) {
              if (this.result.status == 'OK') {
                if (this.result?.data) {
                  if (this.tab == 'o')
                    this.lstGrid = this.result?.data.overviewStockAvailability ?? [], this.lstTxn = this.result?.data?.txnCountDTo ?? [], this.lstDts = [];
                  else {
                    this.ttlItms = this.result.data?.totalListSize ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.ttlItms);
                    this.lstGrid = this.result?.data.stockAvailabilityByStore ?? []; this.lstTxn = this.result?.data?.txnCountDTo ?? [], this.lstDts = [];
                    if (this.lstGrid.length > 0)
                      this.lstGrid[0].dateWiseStockAvailability.forEach((e: any, i: number) => { this.lstDts.push(e.date) });
                    this.lstTxn.forEach(a => { tc.push(a.count) });
                    if (this.lstTxn.length > 0)
                      this.ttlCount = tc.reduce((x, value) => { x += value; return x });
                  }
                }
                else
                  this.glblSvc.onTstr('w', this.result.message);
              }
              else
                this.glblSvc.onTstr('i', this.result.message);
            }
          });
  }

  onEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.stkAvlblty, environment.apiMthds.stkavilbltyExpt.
      replace('ddd', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).replace('ttt', `${this.tab == 'o' ? '1' :
        (this.tab == 'm' ? '2' :'3')}`).replace('eee', this.usrLgnDtls?.email), payLoad, 4).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  onCncl(){
    this.lstSlctdMatName = [...[]],this.lstSlctdFacTags = [...[]], this.lstSlctdStores = [...[]], this.lblAvlblty = null,
    this.slctdMat = [], this.slctdMatName = '11', this.slctdFac = [], this.slctdFacId = [], this.slctMetric = '1',
    this.dpEDVal ='', this.dpSDVal='', this.lblTextOfDate = 'Max 12 months will be accepted',
      this.stkAvlbltyFrm.get('dpSDVal')?.setValue(''), this.stkAvlbltyFrm.get('dpEDVal')?.setValue(''), this.expDrpn = '1',
      this.stkAvlbltyFrm.get('fromDate')?.setValue(''), this.stkAvlbltyFrm.get('toDate')?.setValue(''), this.expDrpnDly = '1',
    this.stkAvlbltyFrm.get('isMatBdge')?.setValue('badge'), this.stkAvlbltyFrm.get('isFacBdge')?.setValue('badge'), 
    this.stkAvlbltyFrm.get('productTags')?.setValue([]), this.stkAvlbltyFrm.get('productId')?.setValue('11'),
    this.stkAvlbltyFrm.get('storeTags')?.setValue([]), this.stkAvlbltyFrm.get('storeIds')?.setValue([]),
    this.stkAvlbltyFrm.get('metricId')?.setValue('1'), this.stkAvlbltyFrm.get('typeId')?.setValue('1'),
    this.stkAvlbltyFrm.get('labelAvailibility')?.setValue(null);
    this.stkAvlbltyFrm.get('pstT')?.setValue('Monthly'); this.dmnLocation(), setTimeout(() => { this.onAS()}, 500);

  }

  onClkRdb(ctrl: string) {
    // if (ctrl == 'productTags')
    // this.fc?.productId.setValue([]),
    //   this.fc?.productId?.clearValidators(), this.fc?.productTag?.setValidators([Validators.required]), this.lstSlctdMatName = [];
    // else if(ctrl == 'productId')
    // this.fc?.productTags.setValue([]),
    //   this.fc?.productTag?.clearValidators(), this.fc?.productId?.setValidators([Validators.required]),
    // this.fc?.productTag?.updateValueAndValidity(), this.fc?.productId?.updateValueAndValidity(), this.lstSlctdMatTags = [];
    // else 
    if(ctrl == 'storeTags')
    this.fc?.storeIds.setValue([]),
    this.fc?.storeIds?.clearValidators(), this.fc?.storeTags?.setValidators([Validators.required]), this.lstSlctdStores = [...[]];
    else
    this.fc?.storeTags.setValue([]), this.fc?.storeTags?.clearValidators(), 
    this.fc?.storeIds?.setValidators([Validators.required]),
    this.fc?.storeTags?.updateValueAndValidity(), this.fc?.storeIds?.updateValueAndValidity(), this.lstSlctdFacTags = [...[]];
  }
  
  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      } else
        if (ctrl == 'n') {
          (this.lstSlctdMatName.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatName.push(item);
        } else {
          if (this.lstSlctdStores.filter((f: { storeId: any; }) => f.storeId === item.storeId).length == 0)
            this.lstSlctdStores.push(item);
        }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'n')
        this.lstSlctdMatName = this.lstSlctdMatName.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstSlctdStores.filter(f => f.storeId != item.storeId);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else if (ctrl == 'n')
        this.lstSlctdMatName = this.lstAllMats;
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstFacIds;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else if (ctrl == 'n')
        this.lstSlctdMatName = [];
      else if (ctrl == 'c')
        this.lstSlctdStores = [];
    }
  }

  // LoadStrName() {
  //     let frmCtrls = this.stkAvlbltyFrm.getRawValue(), payLoad = { stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state,districtId: 
  //     frmCtrls.district == 'null' ? 0 : +frmCtrls.district }; this.slctdFacId = [], this.lstFacIds = [...[]], this.lstSlctdStores = [...[]],
  //     this.FetchData(environment.apiCalls.post, environment.apiPaths.ccp, environment.apiMthds.ccpStore, payLoad, 0, true, 2).then(() => {
  //       if (this.result) {
  //         if (this.result.status == 'OK') {
  //           if (this.result.data)
  //             this.lstFacIds = this.result.data != null ? this.result.data : [];
  //           else
  //             this.glblSvc.onTstr('W', this.result.message);
  //         }
  //       }
  //     });
  // }

  GetDailyData(dtt: any, type: string = 'g') {
    if (this.pstType == 'monthly' || this.pstType == 'weekly') {
      let dtEND: any, maxDate: any, sm: any, frmDte: any = '';
      if (dtt != '') {
        sm = dtt, this.glblSvc.sbjctExpt.next(dtt);
      } else {
        this.glblSvc.sbjctExpt.subscribe(s => { sm = s });
      }
      if (this.pstType == 'monthly') {
        dtEND = this.glblSvc.setDtFrmt(this.dt2days, this.dtF.ymd); let mn = sm.split("-");
        let mnth = this.mnths.filter(m => m.name == mn[0]), v = this.lstMnths.find(month => month.val == mnth[0].val),
          fromDateDay = mn[1] + '-' + v?.val + '-01'; let toDateDay = mn[1] + '-' + v?.val + '-' + v?.days; frmDte = fromDateDay;
        if (dtEND < toDateDay) {
          maxDate = dtEND;
        } else {
          maxDate = toDateDay;
        }
      }
      if (this.pstType == 'weekly') {
        frmDte = '', maxDate = '';
        let dt = sm.split('('), frTo: any = dt[1], frToDly: any; frTo = frTo.substring(0, frTo.length-1), frToDly = frTo.split(' '),
          frmDte = frToDly[0], maxDate = frToDly[2];
      }
      let payLoad: any = this.stkAvlbltyFrm.getRawValue(), fT: any[] = [], mT: any[] = [], mN: any[] = [], sN: any[] = [];
      this.frmDates = this.pstType == 'weekly' ? frmDte: this.glblSvc.setDtFrmt(frmDte, this.dtF.dmy), this.tooDates = this.pstType == 'weekly' ?
        maxDate: this.glblSvc.setDtFrmt(maxDate, this.dtF.dmy), payLoad.fromDate = this.pstType == 'weekly' ? this.glblSvc.setDtFrmt(frmDte, 
          this.dtF.ymd): frmDte , payLoad.toDate = this.pstType == 'weekly' ? this.glblSvc.setDtFrmt(maxDate, this.dtF.ymd) : maxDate,
        payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
        payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.email = this.usrLgnDtls.email
        payLoad.productId = (payLoad.productId != '' && payLoad.productId != null && payLoad.productId != undefined) ? +payLoad.productId : null,
       payLoad.storeTags = payLoad.storeTags != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT;
       payLoad.storeIds = payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeId) }) : [], payLoad.storeIds = sN;
      payLoad.userName = this.usrLgnDtls.userName, payLoad.metricId = (payLoad.metricId != '' && payLoad.metricId != null &&
        payLoad.metricId != undefined) ? +payLoad.metricId : null,  payLoad.labelAvailibility = (payLoad.labelAvailibility != '' &&
        payLoad.labelAvailibility != null && payLoad.labelAvailibility != undefined) ? +payLoad.labelAvailibility : null,
        ['pstT', 'dpSDVal', 'dpEDVal', 'state', 'district', 'block', 'isMatBdge', 'isFacBdge', 'isLocOptn'].forEach(e => { delete payLoad[e] });
      if (type == 'g')
        this.isShow = true, this.onGenDaysData(payLoad);
      else
      this.tab == 'f' ? (payLoad.exportLevel = this.expDrpnDly !='' ? this.expDrpnDly : '') : '', this.onDaysEE(payLoad);

    }
  }

  onGenDaysData(payLoad: any) {
    let tc: any[] = [], crntPgIndx = this.crntDPage - 1; this.lstGridDate = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.stkAvlblty,
      environment.apiMthds.stkAvbltyRpt.replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`).
      replace('ppp', `${this.chsnDmnId}`).replace('ddd', 'D').replace('ttt', `${this.tab == 'o' ? '1' : '3'}`), payLoad, 4).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result?.data) {
              this.ttlDayItms = this.result.data?.totalListSize ?? 0, this.pageEntrsD = this.GetPageSizeTxt(this.crntDPage, this.pageSize, this.ttlDayItms);
              if (this.tab == 'o')
                this.lstGridDate = this.result?.data.overviewStockAvailability ?? [], this.lstTxn = this.result?.data?.txnCountDTo ?? [], this.lstDDts = [];
              else {
                this.lstGridDate = this.result?.data.stockAvailabilityByStore ?? []; this.lstTxn = this.result?.data?.txnCountDTo ?? [], this.lstDDts = [];
                if (this.lstGridDate.length > 0)
                  this.lstGridDate[0].dateWiseStockAvailability.forEach((e: any, i: number) => { this.lstDDts.push(e.date); });
                this.lstTxn.forEach(a => { tc.push(a.count) });
                if (this.lstTxn.length > 0)
                  this.ttlCount = tc.reduce((x, value) => { x += value; return x });
              }
            }
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
      });
  }

  onDaysEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.stkAvlblty, environment.apiMthds.stkavilbltyExpt.
      replace('ddd', `${'d'}`).replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'm' ? '2' : '3')}`), payLoad, 4).then(() => {
        if (this.result)
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
      });
  }

}
