import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-cnfg-inventory',
  templateUrl: './cnfg-inventory.component.html',
  styleUrls: ['./cnfg-inventory.component.css']
})

export class CnfgInventoryComponent extends BaseComponent implements OnInit {

  slctdCnsmPrd = 'none'; slctdCmptOpt = "None"; lstMatTags: any[] = []; rsnsLst: any[] = []; crtMat = false; rsnsLst1: any[] = [];
  shwMatTabl = false; matTagsLst: any[] = []; shwMatTabl1 = false; shwMatTabl2 = false; shwMatTabl3 = false; shwMatTabl4 = false;
  shwMatTabl5 = false; shwMatTabl6 = false; lstFacTags: any[] = []; dropdownSettings = {}; slctdIncldFac: any[] = []; EDOSMD = false;
  slctdDefauFacTag: any[] = []; apiRspns: any; defaultStrTags: any[] = []; isChk = false; stIssue: any[] = []; stReceipt: any[] = []; stStkCnt: any[] = []; stDscrd: any[] = [];
  stTrnsfr: any[] = []; payLoad = {}; issStTxt = ''; RcptStTxt = ''; StkCntStTxt = ''; DscrdsStTxt = ''; TrnsfrStTxt = ''; rsnsRcpt: any[] = [];
  rsnsIssue: any[] = []; RDBchckd = true; RDBchckd1 = true; RDBchckd2 = true; RDBchckd3 = true; RDBchckd4 = true; mstrsData: any;
  txnDataExpt = false; userBadges: any[] = []; lstTblData: any; rsnsStkCnt: any[] = []; rsnsDscrd: any[] = []; lstHrs: any[] = [];
  rsnsTnsfr: any[] = []; resultData: any; lstTblAddData: any; stkTxt = ''; trnsfrTxt = ''; dscrdTxt = ''; issTxt = '';
  RcptTxt = ''; noRcrdTxt = environment.TblNoRcrds; issStTxtTS = ''; RcptStTxtTS = ''; StkCntStTxtTS = ''; DscrdsStTxtTS = ''; TrnsfrStTxtTS = '';
  lstSTIssueTS: any[] = []; lstStRcptTS: any[] = []; lstStstkCntTS: any[] = []; lstStDsrcdTS: any[] = []; lstStTrnsfrTS: any[] = [];
  Issmndt = false; Rcptmndt = false; Stkmndt = false; Dscrdmndt = false; Tnsfrmndt = false; IssmndtRsn = false; RcptmndtRsn = false; StkmndtRsn = false;
  DscrdmndtRsn = false; TnsfrmndtRsn = false; IssmndtTS = false; RcptmndtTS = false; StkmndtTS = false; DscrdmndtTS = false; TnsfrmndtTS = false;
  usrDrpdwn = {}; userLst: any[] = []; usrBdges: any[] = []; owners: any[] = []; defaultUsrTags: any[] = []; defaultUsers: any[] = [];
  shwMatIssRsnsTab = false; shwMatTrnsfrRsnsTab = false; shwMatDscrdIssRsnsTab = false; shwMatSCIssRsnsTab = false; shwMatRcptRsnsTab = false;
  matBdgeId = ''; issBdgeTxt = ''; stkBdgeTxt = ''; trnsfrBdgeTxt = ''; dscrdBdgeTxt = ''; RcptBdgeTxt = ''; txnRsnCnt = 0; txnMatRsnCnt = 0;
  txnStsCnt = 0; usrPrmsn: any = null; cnfgInvPrmsn = true; cnfgData: any = JSON.parse(<string>localStorage.getItem('lgnCnfgData'));
  enbleAddBtn = false; tempData: any[] = []; tempAddmtrls: any[] = []; tempAddStatus: any[] = []; matChngEvnt: any = ''; duration = false;
  prd: any = '1'; isStkMatBdge = 'badge'; lstSlctdMatNames: any[] = []; lstSlctdMatTags: any[] = []; lstSlctSmMtrls: any[] = [];
  lstSlctdActMatTags: any[] = []; slctdStkMat: any[] = []; slctdStkMatName: any[] = []; lstMatTags1: any[] = []; smMtrlList: any[] = [];
  lstAllMats: any[] = []; lstMatTblTags: any[] = []; smMtrlTblList: any[] = [];
  IssmndtRsnCMC = false; RcptmndtRsnCMC = false; StkmndtRsnCMC = false; DscrdmndtRsnCMC = false; TnsfrmndtRsnCMC = false; cnsumsnDtls: any[] = [];
  msdStngs = {
    singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3,
    allowSearchFilter: true
  };

  cnfgInvFrm = this.fb.group({
    configJson: this.fb.group({
      transaction_reasons: this.fb.group({
        issue: this.fb.group({
          reasons_for_issue: [''],
          default_reason_for_issue: [''],
          make_this_field_mandatory: [false],
          configure_by_material_tags: this.fb.group({
            specify_the_material_tags_and_corresponding_reasons: [false],
            material_tags: this.fb.array([]),// [{ material_tag_id: [null], reason: [''], default_reason: [''], }]
            material_tag_id: [''], // added extra
            matsLst: [[]], // added extra
            make_this_field_mandatory: [false],
          }),
          lstRsn: [''], // added extra
          issTxt: [''], //added extra
        }),
        receipt: this.fb.group({
          reasons_for_receipt: [''],
          default_reason_for_receipt: [''],
          make_this_field_mandatory: [false],
          configure_by_material_tags: this.fb.group({
            specify_the_material_tags_and_corresponding_reasons: [false],
            material_tags: this.fb.array([]),// [{ material_tag_id: [null], reason: [''], default_reason: [''], }]
            material_tag_id: [''], // added extra
            matsLst: [[]], // added extra
            make_this_field_mandatory: [false],
          }),
          lstRsn: [''], //added extra
          RcptTxt: [''], //added extra
        }),
        stock_count: this.fb.group({
          reasons_for_stock_count: [''],
          default_reason_for_stock_count: [''],
          make_this_field_mandatory: [false],
          configure_by_material_tags: this.fb.group({
            specify_the_material_tags_and_corresponding_reasons: [false],
            material_tags: this.fb.array([]),// [{ material_tag_id: [null], reason: [''], default_reason: [''], }]
            material_tag_id: [''], //added extra
            matsLst: [[]], // added extra
            make_this_field_mandatory: [false],
          }),
          lstRsn: [''], //added extra
          stkTxt: [''], //added extra
        }),
        discards: this.fb.group({
          reasons_for_discards: [''],
          default_reason_for_discards: [''],
          make_this_field_mandatory: [false],
          configure_by_material_tags: this.fb.group({
            specify_the_material_tags_and_corresponding_reasons: [false],
            material_tags: this.fb.array([]),// [{ material_tag_id: [null], reason: [''], default_reason: [''], }]
            material_tag_id: [''], //added extra
            matsLst: [[]], // added extra
            make_this_field_mandatory: [false],
          }),
          lstRsn: [''], //added extra
          dscrdTxt: [''], //added extra
        }),
        transfer: this.fb.group({
          reasons_for_transfer: [''],
          default_reason_for_transfer: [''],
          make_this_field_mandatory: [false],
          configure_by_material_tags: this.fb.group({
            specify_the_material_tags_and_corresponding_reasons: [false],
            material_tags: this.fb.array([]),// [{ material_tag_id: [null], reason: [''], default_reason: [''], }]
            material_tag_id: [''], //added extra
            matsLst: [[]], // added extra
            make_this_field_mandatory: [false],
          }),
          lstRsn: [''], //added extra
          trnsfrTxt: [''], //added extra
        }),
        incoming_returns: this.fb.group({
          reasons_for_incoming_returns: [''],
          default_reason_for_incoming_returns: [''],
          make_this_field_mandatory: [false],
          configure_by_material_tags: this.fb.group({
            specify_the_material_tags_and_corresponding_reasons: [false],
            material_tags: this.fb.array([]),// [{ material_tag_id: [null], reason: [''], default_reason: [''], }]
            material_tag_id: [''], //added extra
            matsLst: [[]], // added extra
          }),
          lstRsn: [''] //added extra
        }),
        outgoing_returns: this.fb.group({
          reasons_for_outgoing_returns: [''],
          default_reason_for_outgoing_returns: [''],
          make_this_field_mandatory: [false],
          configure_by_material_tags: this.fb.group({
            specify_the_material_tags_and_corresponding_reasons: [false],
            material_tags: this.fb.array([]), // [{ material_tag_id: [null], reason: [''], default_reason: [''], }]
            material_tag_id: [''], //added extra 
            matsLst: [[]], // added extra
          }),
          lstRsn: [''] //added extra
        }),
      }),
      material_status: this.fb.group({
        issue: this.fb.group({
          status: this.fb.group({
            status_for_issue: [''],
            status_for_temperature_sensitive_materials: [''],
            make_this_field_mandatory: [false],
            issStTxt: [''] // added extra
          })
        }),
        receipt: this.fb.group({
          status: this.fb.group({
            status_for_receipt: [''],
            status_for_temperature_sensitive_materials: [''],
            make_this_field_mandatory: [false],
            RcptStTxt: [''] // added extra
          })
        }),
        stock_count: this.fb.group({
          status: this.fb.group({
            status_for_stock_count: [''],
            status_for_temperature_sensitive_materials: [''],
            make_this_field_mandatory: [false],
            StkCntStTxt: [''] // added extra
          })
        }),
        discard: this.fb.group({
          status: this.fb.group({
            status_for_discard: [''],
            status_for_temperature_sensitive_materials: [''],
            make_this_field_mandatory: [false],
            DscrdsStTxt: [''] // added extra
          })
        }),
        transfer: this.fb.group({
          status: this.fb.group({
            status_for_transfer: [''],
            status_for_temperature_sensitive_materials: [''],
            make_this_field_mandatory: [false],
            TrnsfrStTxt: [''] // added extra
          })
        }),
        incoming_returns: this.fb.group({
          status: this.fb.group({
            status_for_incoming_returns: [''],
            status_for_temperature_sensitive_materials: [''],
            make_this_field_mandatory: [false],
          })
        }),
        outgoing_returns: this.fb.group({
          status: this.fb.group({
            status_for_outgoing_returns: [''],
            status_for_temperature_sensitive_materials: [''],
            make_this_field_mandatory: [false],
          })
        }),
      }),
      returns_policy: this.fb.array([]), // [{ store_tags: [1,2], return_policy: { incoming_return_duration_in_days: [null], outgoing_return_duration_in_days: [null], } }],
      permissions: this.fb.group({
        stock_management_visibility: this.fb.group({
          allow_stock_management_of_receiving_stores_to_be_visible_to_issuing_stores_during_issue_and_transfer_transactions: [false]
        })
      }),
      transaction_data_export: this.fb.group({
        transaction_data_export_schedule: this.fb.group({
          enable_transaction_data_export: [false],
          enabled_transaction_data_export: this.fb.group({
            everyday_hours: [''],
            group_of_user_tags: [[]],
            group_of_users: [[]]
          })
        })
      }),
      consumption_ratess: this.fb.group({
        min_max_range: [''],
        range: ['1'],
        isStkMatBdge: ['badge'],
        stkMtrlNme: [[]],
        stkMtrlBdge: [[]]
      }),
      // consumption_rates: this.fb.group({
      //   consumption_rate_computation: this.fb.group({
      //     none: [false],
      //     manual: this.fb.group({
      //       value: [false],
      //       period_type: [''],
      //     }),
      //     automatic: this.fb.group({
      //       value: [false],
      //       frequency_computation: [''],
      //       min_transaction_period: [null],
      //       max_transaction_period: [null],
      //       transaction_to_exclude: this.fb.group({
      //         exclude_discards: [false],
      //         exclude_issues_net_utilization: this.fb.group({
      //           value: [false],
      //           reason_codes: [''],
      //         }),
      //         subtract_income_return_quantities: this.fb.group({
      //           value: [false],
      //           reason_codes: [''],
      //         }),
      //       })
      //     })
      //   }),
      //   display: this.fb.group({
      //     show_days_weeks_months_of_stock: [false],
      //     show_stock_out_prediction: [false],
      //   }),
      //   min_max_range: [''],
      //   range: ['1'],
      //   isStkMatBdge: ['badge'],
      //   stkMtrlNme: [''],
      //   stkMtrlBdge: [''],
      // }), //*
      forecasting_and_optimization: this.fb.group({
        computation_options: this.fb.group({
          value: ['None'],
          demand_forecast: this.fb.group({
            min_transaction_period: [null],
            max_transaction_period: [null],
            order_periodicity: [null],
            no_of_order_periods: [null],
            display: this.fb.group({
              show_demand_forecast: [false]
            }),
          }),
          optimal_order_quantity: this.fb.group({
            stock_management_model: [''],
            min_transaction_period: [null],
            max_transaction_period: [null],
            order_periodicity: [null],
            no_of_order_periods: [null],
            lead_time_average: this.fb.group({
              min_no_of_orders: [null],
              max_no_of_orders: [null],
              max_no_of_order_periods: [false],
              exclude_order_processing: [false],
            }),
            display: this.fb.group({
              show_demand_forecast: [false],
              show_optimal_order_quantity: [false],
            }),
          }),
        }),
      }), //*
      min_max_configuration: this.fb.group({
        min_max_value_type: [''],
      }),
      stock_management_dashboard: this.fb.group({
        stock_management_dashboard_visibility: this.fb.group({
          enable_display_of_stock_management_dashboard: [false],
          default_store_tag: [[]], // [1,2,3]
        }),
      }),
      manual_transaction_upload: this.fb.group({
        enable_manual_upload_of_stock_management_metadata_along_with_transactions: [false],
      }),
      date_of_actual_transaction: this.fb.group({
        issue: this.fb.group({
          enable_capturing_date_of_actual_transaction: this.fb.group({
            date_of_actual_transaction: ['None'],
          }),
        }),
        receipt: this.fb.group({
          enable_capturing_date_of_actual_transaction: this.fb.group({
            date_of_actual_transaction: ['None'],
          }),
        }),
        stock_count: this.fb.group({
          enable_capturing_date_of_actual_transaction: this.fb.group({
            date_of_actual_transaction: ['None'],
          }),
        }),
        discards: this.fb.group({
          enable_capturing_date_of_actual_transaction: this.fb.group({
            date_of_actual_transaction: ['None'],
          }),
        }),
        transfer: this.fb.group({
          enable_capturing_date_of_actual_transaction: this.fb.group({
            date_of_actual_transaction: ['None'],
          }),
        }),
        incoming_returns: this.fb.group({
          enable_capturing_date_of_actual_transaction: this.fb.group({
            date_of_actual_transaction: ['None'],
          }),
        }),
        outgoing_returns: this.fb.group({
          enable_capturing_date_of_actual_transaction: this.fb.group({
            date_of_actual_transaction: ['None'],
          }),
        }),
      }),
    }),
    configTypeId: [+environment.cnfgTypes.inventory], updatedBy: [+this.usrLgnDtls?.userId]
  });

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility),
      this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product), this.matTagsLst = this.lstMatTags,
      this.userBadges = s.filter((f: any) => f.badgeTypeId == environment.tagTypes.user);
    }), this.updateMatTgs(this.lstMatTags);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.lstAllMats = s.allMats }), this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstMatTags1 = s.filter(f => f.badgeTypeId == environment.tagTypes.product);
    });
  }

  ngOnInit(): void {
    this.select2ShowHide(), this.LoadUsers(), this.dropdowns(), this.LoadData(), this.getRsns(), this.getStatus(), this.getSMMtrlsByName(),
      this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_sm_ac') ? (this.cnfgInvPrmsn = e.action) : '') });
    }
    this.lstMatTblTags = this.lstMatTags1;
  }

  getSMMtrlsByName() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.dashboard, environment.apiMthds.dbSmMtrlByName, null, 5).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result?.data) {
            this.smMtrlList = this.result?.data, this.smMtrlTblList = this.smMtrlList;
          }
        }
      }
    })
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdMatNames.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatNames.push(item);
      }
      else if (ctrl == 'm') {
        if (this.lstSlctSmMtrls.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctSmMtrls.push(item);
      }
      else if (ctrl == 'n') {
        if (this.lstSlctdMatTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
      else {
        if (this.lstSlctdActMatTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdActMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdMatNames = this.lstSlctdMatNames.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'm')
        this.lstSlctSmMtrls = this.lstSlctSmMtrls.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'n')
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter((f: { id: any; }) => f.id != item.id);
      else
        this.lstSlctdActMatTags = this.lstSlctdActMatTags.filter((f: { id: any; }) => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdMatNames = this.lstAllMats;
      else if (ctrl == 'm')
        this.lstSlctSmMtrls = this.smMtrlList;
      else if (ctrl == 'n')
        this.lstSlctdMatTags = this.lstMatTags;
      else
        this.lstSlctdActMatTags = this.lstMatTags;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdMatNames = [];
      else if (ctrl == 'm')
        this.lstSlctSmMtrls = [];
      else if (ctrl == 'n')
        this.lstSlctdMatTags = [];
      else
        this.lstSlctdActMatTags = [];
    }
  }

  onClkRdb(evnt: any) {
    this.isStkMatBdge = evnt.target.value;
    this.slctdStkMat = [], this.slctdStkMatName = [], this.lstSlctdMatNames = []; this.lstSlctdMatTags = []; this.lstSlctSmMtrls = []; this.lstSlctdActMatTags = [];
  }

  getRsns() {
    this.glblSvc.sbjctMstrData.subscribe(s => {
      this.mstrsData = s;
      this.rsnsStkCnt = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.stk_edit && f.badgeId == null),
        this.rsnsDscrd = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.discard && f.badgeId == null),
        this.rsnsTnsfr = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.transfer && f.badgeId == null),
        this.rsnsRcpt = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.stk_in && f.badgeId == null),
        this.rsnsIssue = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.issue && f.badgeId == null);
    });
  }

  LoadUsers() {
    this.userLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.str, environment.apiMthds.getUsrsByPrnth.
      replace('pranthid', `${this.chsnDmnId}`), null).then(() => {
        if (this.result) {
          if (this.result.data)
            this.userLst = this.result.data != null ? this.result.data : [];
        }
      })
  }

  dropdowns() {
    this.dropdownSettings = {
      singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
      itemsShowLimit: 3, allowSearchFilter: true
    }, this.usrDrpdwn = {
      singleSelection: false, idField: 'id', textField: 'userName',
      selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true
    };
  }

  onItemSelect(item: any, indx: any, type: any) {
    if (type == 'store_tags') {
      let stData: any[] = this.GetFormArrayData('').at(indx).get('store_tags')?.value;
      if (stData.filter(f => f === item.id).length === 0) // select
        stData.push(item.id);
      else // unselect
        this.GetFormArrayData('').at(indx).get('store_tags')?.setValue(stData.filter(f => f != item.id));
    }
    else
      this.slctdDefauFacTag.push(item?.id)
  }

  onSelectAll(items: any, indx: any, type: any) {
    if (type == 'store_tags') {
      let stData: any[] = [];
      items.forEach((a: any) => {
        if (stData.filter(f => f === a.id).length === 0) // select
          stData.push(a.id),
            this.GetFormArrayData('').at(indx).get('store_tags')?.setValue(stData);
        else // unselect
          this.GetFormArrayData('').at(indx).get('store_tags')?.setValue(stData.filter(f => f != a.id));
      })
    }
    else
      items.filter((a: any) => this.slctdDefauFacTag.push(a?.id))
  }

  onItemSelectOld(item: any, indx: any, type: any) {
    if (type == 'store_tags')
      this.slctdIncldFac.push(item.name), this.GetFormArrayData('').at(indx).get('store_tags')?.setValue(this.slctdIncldFac); // this.cnfgInvFrm.get('configJson')?.returns_policy
    else
      this.slctdDefauFacTag.push(item?.id);
  }

  onChng(item: any, type: string, evntType: any) {
    if (type == 'default_store_tag') {
      if (evntType == 'os')
        this.slctdDefauFacTag.push(item.id)
      else if (evntType == 'osa')
        this.slctdDefauFacTag = [], item.filter((a: any) => this.slctdDefauFacTag.push(a.id));
      else if (evntType == 'ds')
        this.slctdDefauFacTag = this.slctdDefauFacTag.filter(s => s != item.id)
      else
        this.slctdDefauFacTag = [];
    }
    if (type == 'owner') {
      if (evntType == 'os')
        this.owners.push(item.id)
      else if (evntType == 'osa')
        this.owners = [], this.owners = item.filter((a: any) => this.owners.push(a.id));
      else if (evntType == 'ds')
        this.owners = this.owners.filter(s => s != item.id)
      else
        this.owners = [];
    }
    if (type == 'usrBdge') {
      if (evntType == 'os')
        this.usrBdges.push(item.id)
      else if (evntType == 'osa')
        this.usrBdges = [], this.usrBdges = item.filter((a: any) => this.usrBdges.push(a.id));
      else if (evntType == 'ds')
        this.usrBdges = this.usrBdges.filter(s => s != item.id)
      else
        this.usrBdges = [];
    }
  }

  updateMatTgs(lstMatTags: any[]) {
    this.cnfgInvFrm.patchValue({
      configJson: {
        transaction_reasons: {
          issue: { configure_by_material_tags: { matsLst: lstMatTags } },
          receipt: { configure_by_material_tags: { matsLst: lstMatTags } },
          stock_count: { configure_by_material_tags: { matsLst: lstMatTags } },
          discards: { configure_by_material_tags: { matsLst: lstMatTags } },
          transfer: { configure_by_material_tags: { matsLst: lstMatTags } },
          incoming_returns: { configure_by_material_tags: { matsLst: lstMatTags } },
          outgoing_returns: { configure_by_material_tags: { matsLst: lstMatTags } }
        }
      }
    });
  }

  LoadData() {
    let allMstrs = JSON.parse(<string>localStorage.getItem('lgnCnfgData')),
      allRsnsMstrs: any = JSON.parse(<string>localStorage.getItem('lgnMstrsData'));
    this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType +
      environment.cnfgTypes.inventory, null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              if (allMstrs?.cnfgInvData)
                allMstrs.cnfgInvData = this.result.data?.configJson ?? {}, localStorage.setItem('lgnCnfgData', JSON.stringify(allMstrs)),
                  this.glblSvc.sbjctCnfgData.next(allMstrs);
              if (localStorage.getItem('isCnfgInv') == '1')
                localStorage.removeItem('isCnfgInv'), window.location.reload();
              let itms1: any[] = [], itms2: any[] = [], itms3: any[] = [], itms4: any[] = [], itms5: any[] = [], itms6: any[] = [],
                itms7: any[] = [];
              this.apiRspns = this.result.data, this.cnfgInvFrm.patchValue(this.apiRspns),
                this.apiRspns.configJson.transaction_reasons.issue.reasons_for_issue.split(',').filter((item: any) => { itms1.push(item.trim()) }),
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('issue')?.get('lstRsn')?.setValue(itms1.filter(b => {
                  return b != null && b != ''
                }));

              this.apiRspns.configJson.transaction_reasons.receipt.reasons_for_receipt.split(',').filter((item: any) => { itms2.push(item.trim()) }),
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('receipt')?.get('lstRsn')?.setValue(itms2.filter(b => {
                  return b != null && b != ''
                }));
              this.apiRspns.configJson.transaction_reasons.discards.reasons_for_discards.split(',').filter((item: any) => { itms3.push(item.trim()) }),
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('discards')?.get('lstRsn')?.setValue(itms3.filter(b => {
                  return b != null && b != ''
                }));
              this.apiRspns.configJson.transaction_reasons.incoming_returns.reasons_for_incoming_returns.split(',').filter((item: any) => { itms4.push(item.trim()) }),
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('incoming_returns')?.get('lstRsn')?.setValue(itms4.filter(b => {
                  return b != null && b != ''
                }));
              this.apiRspns.configJson.transaction_reasons.outgoing_returns.reasons_for_outgoing_returns.split(',').filter((item: any) => { itms5.push(item.trim()) }),
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('outgoing_returns')?.get('lstRsn')?.setValue(itms5.filter(b => {
                  return b != null && b != ''
                }));
              this.apiRspns.configJson.transaction_reasons.stock_count.reasons_for_stock_count.split(',').filter((item: any) => { itms6.push(item.trim()) }),
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('stock_count')?.get('lstRsn')?.setValue(itms6.filter(b => {
                  return b != null && b != ''
                }));
              this.apiRspns.configJson.transaction_reasons.transfer.reasons_for_transfer.split(',').filter((item: any) => { itms7.push(item.trim()) }),
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('transfer')?.get('lstRsn')?.setValue(itms7.filter(b => {
                  return b != null && b != ''
                }));
              this.GetFormArrayData('issue').controls.length = 0;
              this.apiRspns.configJson.transaction_reasons.issue.configure_by_material_tags.material_tags.forEach((e: any) => {
                let mats: any[] = []; Object.assign(e, { items1: [] }),
                  e.reason?.split(',').filter((item: any) => { e.items1.push(item.trim()) }),
                  this.lstMatTags.filter(b => {
                    if (e.material_tag_id == b?.id)
                      e.material_tag_id = b.name;
                  }),
                  mats = this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('issue')?.get('configure_by_material_tags')?.get('matsLst')?.value.filter((f: any) => f.name != e.material_tag_id)
                this.GetFormArrayData('issue').controls.push(this.onAddFormCtrls(e.material_tag_id, e.reason, e.default_reason,
                  e.items1?.filter((b: any) => { return b != null && b != '' })));
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('issue')?.get('configure_by_material_tags')?.get('matsLst')?.setValue(mats);
              });
              this.GetFormArrayData('receipt').controls.length = 0;
              this.apiRspns.configJson.transaction_reasons.receipt.configure_by_material_tags.material_tags.forEach((e: any) => {
                let mats: any[] = []; Object.assign(e, { items1: [] }),
                  e.reason?.split(',').filter((item: any) => { e.items1.push(item.trim()) }),
                  this.lstMatTags.filter(b => {
                    if (e.material_tag_id == b?.id)
                      e.material_tag_id = b.name;
                  }),
                  mats = this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('receipt')?.get('configure_by_material_tags')?.get('matsLst')?.value.filter((f: any) => f.name != e.material_tag_id)
                this.GetFormArrayData('receipt').controls.push(this.onAddFormCtrls(e.material_tag_id, e.reason, e.default_reason,
                  e.items1?.filter((b: any) => { return b != null && b != '' })));
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('receipt')?.get('configure_by_material_tags')?.get('matsLst')?.setValue(mats);
              });
              this.GetFormArrayData('discards').controls.length = 0;
              this.apiRspns.configJson.transaction_reasons.discards.configure_by_material_tags.material_tags.forEach((e: any) => {
                let mats: any[] = []; Object.assign(e, { items1: [] }),
                  e.reason?.split(',').filter((item: any) => { e.items1.push(item.trim()) }),
                  this.lstMatTags.filter(b => {
                    if (e.material_tag_id == b?.id)
                      e.material_tag_id = b.name;
                  }),
                  mats = this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('discards')?.get('configure_by_material_tags')?.get('matsLst')?.value.filter((f: any) => f.name != e.material_tag_id)
                this.GetFormArrayData('discards').controls.push(this.onAddFormCtrls(e.material_tag_id, e.reason, e.default_reason,
                  e.items1?.filter((b: any) => { return b != null && b != '' })));
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('discards')?.get('configure_by_material_tags')?.get('matsLst')?.setValue(mats);
              });
              this.GetFormArrayData('incoming_returns').controls.length = 0;
              this.apiRspns.configJson.transaction_reasons.incoming_returns.configure_by_material_tags.material_tags.forEach((e: any) => {
                let mats: any[] = []; Object.assign(e, { items1: [] }),
                  e.reason?.split(',').filter((item: any) => { e.items1.push(item.trim()) }),
                  this.lstMatTags.filter(b => {
                    if (e.material_tag_id == b?.id)
                      e.material_tag_id = b.name;
                  }),
                  mats = this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('incoming_returns')?.get('configure_by_material_tags')?.get('matsLst')?.value.filter((f: any) => f.name != e.material_tag_id)
                this.GetFormArrayData('incoming_returns').controls.push(this.onAddFormCtrls(e.material_tag_id, e.reason, e.default_reason,
                  e.items1?.filter((b: any) => { return b != null && b != '' })));
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('incoming_returns')?.get('configure_by_material_tags')?.get('matsLst')?.setValue(mats);
              });
              this.GetFormArrayData('outgoing_returns').controls.length = 0;
              this.apiRspns.configJson.transaction_reasons.outgoing_returns.configure_by_material_tags.material_tags.forEach((e: any) => {
                let mats: any[] = []; Object.assign(e, { items1: [] }),
                  e.reason?.split(',').filter((item: any) => { e.items1.push(item.trim()) }),
                  this.lstMatTags.filter(b => {
                    if (e.material_tag_id == b?.id)
                      e.material_tag_id = b.name;
                  }),
                  mats = this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('outgoing_returns')?.get('configure_by_material_tags')?.get('matsLst')?.value.filter((f: any) => f.name != e.material_tag_id)
                this.GetFormArrayData('outgoing_returns').controls.push(this.onAddFormCtrls(e.material_tag_id, e.reason, e.default_reason,
                  e.items1?.filter((b: any) => { return b != null && b != '' })));
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('outgoing_returns')?.get('configure_by_material_tags')?.get('matsLst')?.setValue(mats);
              });
              this.GetFormArrayData('stock_count').controls.length = 0;
              this.apiRspns.configJson.transaction_reasons.stock_count.configure_by_material_tags.material_tags.forEach((e: any) => {
                let mats: any[] = []; Object.assign(e, { items1: [] }),
                  e.reason?.split(',').filter((item: any) => { e.items1.push(item.trim()) }),
                  this.lstMatTags.filter(b => {
                    if (e.material_tag_id == b?.id)
                      e.material_tag_id = b.name;
                  }),
                  mats = this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('stock_count')?.get('configure_by_material_tags')?.get('matsLst')?.value.filter((f: any) => f.name != e.material_tag_id)
                this.GetFormArrayData('stock_count').controls.push(this.onAddFormCtrls(e.material_tag_id, e.reason, e.default_reason,
                  e.items1?.filter((b: any) => { return b != null && b != '' })));
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('stock_count')?.get('configure_by_material_tags')?.get('matsLst')?.setValue(mats);
              });
              this.GetFormArrayData('transfer').controls.length = 0;
              this.apiRspns.configJson.transaction_reasons.transfer.configure_by_material_tags.material_tags.forEach((e: any) => {
                let mats: any[] = []; Object.assign(e, { items1: [] }),
                  e.reason?.split(',').filter((item: any) => { e.items1.push(item.trim()) }),
                  this.lstMatTags.filter(b => {
                    if (e.material_tag_id == b?.id)
                      e.material_tag_id = b.name;
                  }),
                  mats = this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('transfer')?.get('configure_by_material_tags')?.get('matsLst')?.value.filter((f: any) => f.name != e.material_tag_id)
                this.GetFormArrayData('transfer').controls.push(this.onAddFormCtrls(e.material_tag_id, e.reason, e.default_reason,
                  e.items1?.filter((b: any) => { return b != null && b != '' })));
                this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('transfer')?.get('configure_by_material_tags')?.get('matsLst')?.setValue(mats);
              });
              if (this.apiRspns.configJson.transaction_data_export.transaction_data_export_schedule.enable_transaction_data_export) {
                this.txnDataExpt = true; this.cnfgInvFrm.get('configJson')?.get('transaction_data_export')?.get('transaction_data_export_schedule')?.
                  get('enabled_transaction_data_export')?.get('everyday_hours')?.setValue(this.apiRspns.configJson?.transaction_data_export?.
                    transaction_data_export_schedule?.enabled_transaction_data_export?.everyday_hours?.toString())
              }
              else
                this.txnDataExpt = false;
              this.GetFormArrayData('').controls.length = 0; // let slctd1: any[] = [], matTag1 = [];
              this.apiRspns.configJson.returns_policy.forEach((a: any) => {
                this.GetFormArrayData('').push(this.onCreateRtrns_plcyLst(a?.store_tags, a?.return_policy?.incoming_return_duration_in_days, a?.return_policy?.outgoing_return_duration_in_days))
                // a?.store_tags.forEach((p: any) => {
                //   matTag1 = this.lstFacTags.filter(f => f.id == p)[0], slctd1.push(matTag1);
                // });
                // this.GetFormArrayData('').push(this.onCreateRtrns_plcyLst(slctd1, a?.return_policy?.incoming_return_duration_in_days, a?.return_policy?.outgoing_return_duration_in_days))
              });
              let slctd: any[] = [], matTag = [], data: any[] = [];
              data = this.apiRspns.configJson.stock_management_dashboard.stock_management_dashboard_visibility.default_store_tag;
              if (data != undefined && data != null && data.length != 0) {
                data.forEach((s: any) => {
                  if (this.lstFacTags.filter(f => f.id == s).length > 0)
                    matTag = this.lstFacTags.filter(f => f.id == s)[0], slctd.push(matTag != undefined ? matTag : {});
                }); this.slctdDefauFacTag = data; this.defaultStrTags = slctd;
              }
              // this.cnfgInvFrm.get('configJson')?.get('stock_management_dashboard')?.get('stock_management_dashboard_visibility')?.get('default_store_tag')?.setValue(slctd);
              let slctd2: any[] = [], matTag2 = [], data1: any[] = [];
              data1 = this.apiRspns.configJson?.transaction_data_export?.transaction_data_export_schedule?.enabled_transaction_data_export?.group_of_user_tags;
              if (data1 != undefined && data1 != null && data1.length != 0) {
                data1.forEach((s: any) => {
                  if (this.userBadges.filter(f => f.id == s).length > 0)
                    matTag2 = this.userBadges.filter(f => f.id == s)[0], slctd2.push(matTag2 != undefined ? matTag2 : {});
                });
                this.usrBdges = data1; this.defaultUsrTags = slctd2;
              }
              let slctd3: any[] = [], matTag3 = [], data2: any[] = [];
              data2 = this.apiRspns.configJson?.transaction_data_export?.transaction_data_export_schedule?.enabled_transaction_data_export?.group_of_users;
              if (data2 != undefined && data2 != null && data2.length != 0) {
                data2.forEach((s: any) => {
                  if (this.userLst.filter(f => f.id == s).length > 0)
                    matTag3 = this.userLst.filter(f => f.id == s)[0], slctd3.push(matTag3 != undefined ? matTag3 : {});
                });
                // data2.forEach((s: any) => { matTag3 = this.userLst.filter(f => f.id == s)[0], slctd3.push(matTag3 != undefined ? matTag3 : {}); }); 
                this.owners = data2; this.defaultUsers = slctd3;
              }
              if (this.apiRspns?.configJson?.transaction_reasons?.issue.configure_by_material_tags?.specify_the_material_tags_and_corresponding_reasons)
                this.shwMatTabl = true;
              else
                this.shwMatTabl = false;
              if (this.apiRspns?.configJson?.transaction_reasons?.stock_count.configure_by_material_tags?.specify_the_material_tags_and_corresponding_reasons)
                this.shwMatTabl2 = true;
              else
                this.shwMatTabl2 = false;
              if (this.apiRspns?.configJson?.transaction_reasons?.outgoing_returns.configure_by_material_tags?.specify_the_material_tags_and_corresponding_reasons)
                this.shwMatTabl6 = true;
              else
                this.shwMatTabl6 = false;
              if (this.apiRspns?.configJson?.transaction_reasons?.incoming_returns.configure_by_material_tags?.specify_the_material_tags_and_corresponding_reasons)
                this.shwMatTabl5 = true;
              else
                this.shwMatTabl5 = false;
              if (this.apiRspns?.configJson?.transaction_reasons?.discards.configure_by_material_tags?.specify_the_material_tags_and_corresponding_reasons)
                this.shwMatTabl3 = true;
              else
                this.shwMatTabl3 = false;
              if (this.apiRspns?.configJson?.transaction_reasons?.transfer.configure_by_material_tags?.specify_the_material_tags_and_corresponding_reasons)
                this.shwMatTabl4 = true;
              else
                this.shwMatTabl4 = false;
              if (this.apiRspns?.configJson?.transaction_reasons?.receipt.configure_by_material_tags?.specify_the_material_tags_and_corresponding_reasons)
                this.shwMatTabl1 = true;
              else
                this.shwMatTabl1 = false;

              if (this.apiRspns?.configJson?.transaction_reasons?.issue.configure_by_material_tags?.material_tags.length !=0) {
                let IssuRsns: any[] = [];
                  IssuRsns = allRsnsMstrs?.rsnsLst.map((o: any) => (o.reasonType == environment.cnfgTrnsRsnType.issue) && 
                    (this.apiRspns?.configJson?.transaction_reasons?.issue.configure_by_material_tags?.material_tags.includes(o.badgeId)) ? 
                      Object.assign({}, o, { isReasonMandatory : true }) : o), allRsnsMstrs.rsnsLst = IssuRsns.length != 0 ? IssuRsns : [],
                  localStorage.setItem('lgnMstrsData', JSON.stringify(allRsnsMstrs));
              }

              if (this.apiRspns?.configJson?.transaction_reasons?.receipt.configure_by_material_tags?.material_tags.length !=0) {
                let stkInRsns: any[] = [];
                  stkInRsns = allRsnsMstrs?.rsnsLst.map((o: any) => (o.reasonType == environment.cnfgTrnsRsnType.stk_in) && 
                    (this.apiRspns?.configJson?.transaction_reasons?.receipt.configure_by_material_tags?.material_tags.includes(o.badgeId)) ? 
                      Object.assign({}, o, { isReasonMandatory : true }) : o), allRsnsMstrs.rsnsLst = stkInRsns.length != 0 ? stkInRsns : [],
                  localStorage.setItem('lgnMstrsData', JSON.stringify(allRsnsMstrs));
              }

              if (this.apiRspns?.configJson?.transaction_reasons?.stock_count.configure_by_material_tags?.material_tags.length !=0) {
                let stkEdRsns: any[] = [];
                  stkEdRsns = allRsnsMstrs?.rsnsLst.map((o: any) => (o.reasonType == environment.cnfgTrnsRsnType.stk_edit) && 
                    (this.apiRspns?.configJson?.transaction_reasons?.stock_count.configure_by_material_tags?.material_tags.includes(o.badgeId)) ? 
                      Object.assign({}, o, { isReasonMandatory : true }) : o), allRsnsMstrs.rsnsLst = stkEdRsns.length != 0 ? stkEdRsns : [],
                  localStorage.setItem('lgnMstrsData', JSON.stringify(allRsnsMstrs));
              }

              if (this.apiRspns?.configJson?.transaction_reasons?.discards.configure_by_material_tags?.material_tags.length !=0) {
                let dscdRsns: any[] = [];
                  dscdRsns = allRsnsMstrs?.rsnsLst.map((o: any) => (o.reasonType == environment.cnfgTrnsRsnType.discard) && 
                    (this.apiRspns?.configJson?.transaction_reasons?.discards.configure_by_material_tags?.material_tags.includes(o.badgeId)) ? 
                      Object.assign({}, o, { isReasonMandatory : true }) : o), allRsnsMstrs.rsnsLst = dscdRsns.length !=0 ? dscdRsns : [],
                  localStorage.setItem('lgnMstrsData', JSON.stringify(allRsnsMstrs));
              }

              if (this.apiRspns?.configJson?.transaction_reasons?.transfer.configure_by_material_tags?.material_tags.length !=0) {
                let trnsRsns: any[] = [];
                trnsRsns = allRsnsMstrs?.rsnsLst.map((o: any) => (o.reasonType == environment.cnfgTrnsRsnType.transfer) && 
                    (this.apiRspns?.configJson?.transaction_reasons?.transfer.configure_by_material_tags?.material_tags.includes(o.badgeId)) ? 
                      Object.assign({}, o, { isReasonMandatory : true }) : o), allRsnsMstrs.rsnsLst = trnsRsns.length !=0 ? trnsRsns : [],
                  localStorage.setItem('lgnMstrsData', JSON.stringify(allRsnsMstrs));
              }
              
              // this.prd = this.result.data.configJson?.min_max_configuration?.range;

              // if (this.result.data.configJson?.consumption_rates?.isStkMatBdge == 'badge') {
                //   this.isStkMatBdge = 'badge'
                // } else
                //   this.isStkMatBdge = 'name';
                // this.cnsumsnDtls = this.result.data.configJson.consumption_rates ?? [];
                this.cnsumsnDtls = this.result.data.configJson.consumption_rates.hasOwnProperty('consumption_rate_computation') == true ? [] : this.result.data.configJson.consumption_rates;
                setTimeout(() => {
                  let lstTblMts: any[] = [], lstTblMtsTags: any[] = [],  tags: any[] = [], nmes: any[] = [], lstFreeMtrlTags: any[] = [], lstFreeMtrlNme: any[] = [];
                    this.cnsumsnDtls.forEach(e=>{tags.push(e.stkMtrlBdge)}), this.cnsumsnDtls.forEach(e=>{nmes.push(e.stkMtrlNme)}),
                    lstTblMtsTags = Array.prototype.concat(...tags), lstTblMts = Array.prototype.concat(...nmes),
                    this.lstMatTags1.forEach(e => { if (lstTblMtsTags.filter(f => f.id === e.id).length == 0) lstFreeMtrlTags.push(e); });  
                    this.smMtrlList.forEach(e => { if (lstTblMts.filter(f => f.id === e.id).length == 0) lstFreeMtrlNme.push(e); });  
                    this.lstMatTblTags = lstFreeMtrlTags, this.smMtrlTblList = lstFreeMtrlNme;
                }, 500);
                this.prd = '1'; this.isStkMatBdge = 'badge';
            }
          }
        }
      });
  }

  onChngPrdtTag($event: any, type: any) {
    if ($event) {
      this.crtMat = this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get(type)?.get('configure_by_material_tags')?.get('matsLst')?.value
        .filter((a: any) => a.name == $event.value).length > 0;
      if (this.crtMat)
        this.GetFormArrayData(type).push(this.onAddFormCtrls($event.value, '', '', [])),
          this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get(type)?.get('configure_by_material_tags')?.get('matsLst')?.setValue(
            this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get(type)?.get('configure_by_material_tags')?.get('matsLst')?.value.filter((f: any) => f.name != $event.value)),
          this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get(type)?.get('configure_by_material_tags')?.get('material_tag_id')?.setValue('');
    }
  }

  onSaveBdgeData(ctrl: string) {
    let type = ((ctrl == 'Issue' || ctrl == 'IssueCMC') ? environment.cnfgTrnsRsnType.issue : ((ctrl == 'Receipt' || ctrl == 'ReceiptCMC') ?
      environment.cnfgTrnsRsnType.stk_in : ((ctrl == 'Stock Count' || ctrl == 'Stock CountCMC') ? environment.cnfgTrnsRsnType.stk_edit : 
      ((ctrl == 'Discard' || ctrl == 'DiscardCMC') ? environment.cnfgTrnsRsnType.discard : ((ctrl == 'Transfer' || ctrl == 'TransferCMC') ?
      environment.cnfgTrnsRsnType.transfer : ''))))), actT = (ctrl == 'Issue' ? 'lstIssBdgeRsn' : (ctrl == 'Receipt' ? 'lstRcptBdgeRsn' :
      (ctrl == 'Stock Count' ? 'lstScBdgeRsn' : (ctrl == 'Discard' ? 'lstDsrcdBdgeRsn' : 'lstTrsfrBdgeRsn')))), actType = (ctrl == 'Issue' ?
      'lstIssue' : (ctrl == 'Receipt' ? 'lstRcpt' : (ctrl == 'Stock Count' ? 'lstStkCnt' : (ctrl == 'Discard' ? 'lstDscrd' :
      (ctrl == 'Transfer' ? 'lstTrnsfr' : (ctrl == 'IssueCMC' ? 'lstIssBdgeRsn' : (ctrl == 'ReceiptCMC' ? 'lstRcptBdgeRsn' : 
      (ctrl == 'Stock CountCMC' ? 'lstScBdgeRsn' : (ctrl == 'DiscardCMC' ? 'lstDsrcdBdgeRsn' : (ctrl == 'TransferCMC' ? 'lstTrsfrBdgeRsn' : '')))))))))),
      payLoad: any[] = [], reasons: any[] = [];
    // if (this.txnMatRsnCnt != 0) {
    // this.lstTblData[actT].filter((f: any) => {
    if (type != '' && actType != '') {
      this.tempAddmtrls.filter((f: any) => {
        reasons.push({
          badgeId: this.matBdgeId,
          isDefault: false,
          masterReason: {
            isDefault: false,
            isReasonMandatory: this.lstTblData[actType].length != 0 ? this.lstTblData[actType][0].isReasonMandatory : false,
            name: f.reasonName,
            pranthId: 0,
            reasonType: type,
            updatedBy: this.usrLgnDtls?.userId
          },
          pranthId: 1,
          reasonId: f.reasonId ? f.reasonId : 0,
          updatedBy: this.usrLgnDtls?.userId
        });
      }), payLoad = reasons;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.bdgeRsn, environment.apiMthds.svRupdate +
        `?pranthId=${this.chsnDmnId}`, payLoad, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              this.tempAddmtrls = [];
              // this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('issue')?.get('configure_by_material_tags')?.get('material_tag_id')?.setValue(['']);
              this.glblSvc.onTstr('s', this.result.message), this.txnMatRsnCnt = 0;
              let allMstrs = JSON.parse(<string>localStorage.getItem('lgnMstrsData'));
              if (allMstrs.rsnsLst)
                this.glblSvc.getAPI(environment.apiPaths.rsnType, environment.apiMthds.get_all, 2, 1).subscribe(s => {
                  if (s)
                    allMstrs.rsnsLst = s?.data ?? [], localStorage.setItem('lgnMstrsData', JSON.stringify(allMstrs)),
                      this.glblSvc.sbjctMstrData.next(allMstrs)
                });
              setTimeout(() => { this.onMatTagChng(this.matChngEvnt, ctrl); }, 1000); //setTimeout(() => { this.getStatus();}, 1000); 
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
    // }
    }
  }

  onMatTagChngOld(evnt: any, type: any) {
    let ctrl = (type == 'Issue' ? environment.cnfgTrnsRsnType.issue : (type == 'Receipt' ? environment.cnfgTrnsRsnType.stk_in :
      (type == 'Stock Count' ? environment.cnfgTrnsRsnType.stk_edit : (type == 'Discard' ? environment.cnfgTrnsRsnType.discard :
        environment.cnfgTrnsRsnType.transfer)))),
      actT = (type == 'Issue' ? 'lstIssBdgeRsn' : (type == 'Receipt' ? 'lstRcptBdgeRsn' :
        (type == 'Stock Count' ? 'lstScBdgeRsn' : (type == 'Discard' ? 'lstDsrcdBdgeRsn' : 'lstTrsfrBdgeRsn'))))
    this.matBdgeId = '';
    if (type == 'Issue') {
      if (evnt.target.value != '')
        this.shwMatIssRsnsTab = true, this.matBdgeId = evnt.target.value;
      else
        this.shwMatIssRsnsTab = false;
    }
    if (type == 'Transfer') {
      if (evnt.target.value != '')
        this.shwMatTrnsfrRsnsTab = true, this.matBdgeId = evnt.target.value;
      else
        this.shwMatTrnsfrRsnsTab = false;
    }
    if (type == 'Discard') {
      if (evnt.target.value != '')
        this.shwMatDscrdIssRsnsTab = true, this.matBdgeId = evnt.target.value;
      else
        this.shwMatDscrdIssRsnsTab = false;
    }
    if (type == 'Stock Count') {
      if (evnt.target.value != '')
        this.shwMatSCIssRsnsTab = true, this.matBdgeId = evnt.target.value;
      else
        this.shwMatSCIssRsnsTab = false;
    }
    if (type == 'Receipt') {
      if (evnt.target.value != '')
        this.shwMatRcptRsnsTab = true, this.matBdgeId = evnt.target.value;
      else
        this.shwMatRcptRsnsTab = false;
    }

    if (this.matBdgeId != '') {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.bdgeRsn, environment.apiMthds.getRsnsByBdge.
        replace('bbb', `${this.matBdgeId}`).replace('ppp', `${this.chsnDmnId}`).
        replace('ttt', `${ctrl}`), null, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              // if (this.result.data) { 
              this.lstTblData[actT] = this.result.data != null ? this.result.data : [];
              // }
            }
          }
        });
    }
  }

  onMatTagChng(evnt: any, type: any) {
    // this.matChngEvnt =''; 
    let evntValue = '';
    if (evnt.target == undefined)
      evntValue = evnt, this.matChngEvnt = evnt;
    else
      this.matChngEvnt = evnt.target.value, evntValue = evnt.target.value;

      let actT = ((type == 'Issue' || type == 'IssueCMC') ? 'lstIssBdgeRsn' : ((type == 'Receipt' || type == 'ReceiptCMC') ? 'lstRcptBdgeRsn' :
      ((type == 'Stock Count' || type == 'Stock CountCMC') ? 'lstScBdgeRsn' : ((type == 'Discard' || type == 'DiscardCMC') ? 'lstDsrcdBdgeRsn' :
      ((type == 'Transfer' || type == 'TransferCMC') ? 'lstTrsfrBdgeRsn' : ''))))),
      ctrl = ((type == 'Issue' || type == 'IssueCMC') ? environment.cnfgTrnsRsnType.issue : ((type == 'Receipt' || type == 'ReceiptCMC') ?
      environment.cnfgTrnsRsnType.stk_in : ((type == 'Stock Count' || type == 'Stock CountCMC') ? environment.cnfgTrnsRsnType.stk_edit :
      ((type == 'Discard' || type == 'DiscardCMC') ? environment.cnfgTrnsRsnType.discard : ((type == 'Transfer' || type == 'TransferCMC') ? 
      environment.cnfgTrnsRsnType.transfer : '')))));
    if (this.mstrsData && evnt && ctrl != '' && actT != '' && type != '') {
      if (type == 'Issue' || type == 'IssueCMC') {
        if (evntValue != '')
          this.shwMatIssRsnsTab = true, this.matBdgeId = evntValue;
        else
          this.shwMatIssRsnsTab = false;
      }
      if (type == 'Transfer' || type == 'TransferCMC') {
        if (evntValue != '')
          this.shwMatTrnsfrRsnsTab = true, this.matBdgeId = evntValue;
        else
          this.shwMatTrnsfrRsnsTab = false;
      }
      if (type == 'Discard' || type == 'DiscardCMC') {
        if (evntValue != '')
          this.shwMatDscrdIssRsnsTab = true, this.matBdgeId = evntValue;
        else
          this.shwMatDscrdIssRsnsTab = false;
      }
      if (type == 'Stock Count' || 'Stock CountCMC') {
        if (evntValue != '')
          this.shwMatSCIssRsnsTab = true, this.matBdgeId = evntValue;
        else
          this.shwMatSCIssRsnsTab = false;
      }
      if (type == 'Receipt' || type == 'ReceiptCMC') {
        if (evntValue != '')
          this.shwMatRcptRsnsTab = true, this.matBdgeId = evntValue;
        else
          this.shwMatRcptRsnsTab = false;
      }
      this.lstTblData[actT] = evntValue ? this.mstrsData.rsnsLst.filter((a: any) => a.reasonType == ctrl && a.badgeId == evntValue) : [];
      if (type == 'Issue' || type == 'IssueCMC')
        this.IssmndtRsnCMC = this.lstTblData[actT][0]?.isReasonMandatory ?? false;
      if (type == 'Receipt' || type == 'ReceiptCMC')
        this.RcptmndtRsnCMC = this.lstTblData[actT][0]?.isReasonMandatory ?? false;
      if (type == 'Stock Count' || type == 'Stock CountCMC')
        this.StkmndtRsnCMC = this.lstTblData[actT][0]?.isReasonMandatory ?? false;
      if (type == 'Discard' || type == 'DiscardCMC')
        this.DscrdmndtRsnCMC = this.lstTblData[actT][0]?.isReasonMandatory ?? false;
      if (type == 'Transfer' || type == 'TransferCMC')
        this.TnsfrmndtRsnCMC = this.lstTblData[actT][0]?.isReasonMandatory ?? false;
    }
  }

  onAddDelBdRsn(ctrl: string, rowVal: any, actType: string, isMndt: Boolean = false, isBdg: boolean = false) {
    let type = ((ctrl == 'Issue' || ctrl == 'IssueCMC') ? environment.cnfgTrnsRsnType.issue : ((ctrl == 'Receipt' || ctrl == 'ReceiptCMC') ?
      environment.cnfgTrnsRsnType.stk_in : ((ctrl == 'Stock Count' || ctrl == 'Stock CountCMC') ? environment.cnfgTrnsRsnType.stk_edit :
      ((ctrl == 'Discard' || ctrl == 'DiscardCMC')? environment.cnfgTrnsRsnType.discard : ((ctrl == 'Transfer' || ctrl == 'TransferCMC') ?
      environment.cnfgTrnsRsnType.transfer : ''))))), actT = (ctrl == 'Issue' ? 'lstIssBdgeRsn' : (ctrl == 'Receipt' ? 'lstRcptBdgeRsn' :
      (ctrl == 'Stock Count' ? 'lstScBdgeRsn' : (ctrl == 'Discard' ? 'lstDsrcdBdgeRsn' : (ctrl == 'Transfer' ? 'lstTrsfrBdgeRsn' :
      (ctrl == 'IssueCMC' ? 'lstIssBdgeRsn' : (ctrl == 'ReceiptCMC' ? 'lstRcptBdgeRsn' : (ctrl == 'Stock CountCMC' ? 'lstScBdgeRsn' : 
      (ctrl == 'DiscardCMC' ? 'lstDsrcdBdgeRsn' : (ctrl == 'TransferCMC' ? 'lstTrsfrBdgeRsn' : ''))))))))));
    if (actType == 'a' && rowVal != '' && type != '' && actT != '') {
      isMndt = this.lstTblData[actT].length == 0 ? false : isMndt; // this.lstTblData[actT].length == 0 ? isMndt = false : isMndt = isMndt;
      if (this.lstTblData[actT].filter((f: any) => f.reasonName == rowVal).length == 0) {
        this.tempAddmtrls = [...this.lstTblData[actT]];
        if (isBdg)
          this.tempAddmtrls.push({ reasonName: rowVal.trim(), reasonType: type, isReasonMandatory: isMndt, isDefault: false, badgeId: this.matBdgeId });
        else
          this.tempAddmtrls.push({ reasonName: rowVal.trim(), reasonType: type, isReasonMandatory: isMndt, isDefault: false });
        this.onSaveBdgeData(ctrl);
        // this.lstTblData[actT].push({ reasonName: rowVal, reasonType: type, isReasonMandatory: isMndt, isDefault: false }), this.txnMatRsnCnt++;
      }
      else
        this.glblSvc.onTstr('w', 'Tag is already exists');
    }
    else {
      if (rowVal.reasonId) {
        if (confirm('Are you sure, you want to delete.?')) {
          this.FetchData(environment.apiCalls.del, environment.apiPaths.bdgeRsn, environment.apiMthds.delById + rowVal.reasonId +
            `?pranthId=${this.chsnDmnId}`, null, 2, true, 1).then(() => {
              if (this.result) {
                if (this.result.status == 'OK') {
                  // this.lstTblData[actT] = this.lstTblData[actT].filter((f: any) => f.reasonName != rowVal.reasonName);
                  this.glblSvc.onTstr('s', this.result.message);
                  let allMstrs = JSON.parse(<string>localStorage.getItem('lgnMstrsData'));
                  if (allMstrs.rsnsLst)
                    this.glblSvc.getAPI(environment.apiPaths.rsnType, environment.apiMthds.get_all, 2, 1).subscribe(s => {
                      if (s)
                        allMstrs.rsnsLst = s?.data ?? [], localStorage.setItem('lgnMstrsData', JSON.stringify(allMstrs)),
                          this.glblSvc.sbjctMstrData.next(allMstrs)
                    });
                  setTimeout(() => { this.onMatTagChng(this.matChngEvnt, ctrl); }, 500);

                  // this.onMatTagChng(evnt, ctrl)
                }
                else
                  this.glblSvc.onTstr('e', this.result.message);
              }
            });
        }
      }
      else
        // this.lstTblData[actT] = this.lstTblData[actT].filter((f: any) => f.name != rowVal.name);
        this.txnMatRsnCnt--;
    }
  }

  GetFormArrayData(type: string): FormArray {
    if (type == '')
      return this.cnfgInvFrm.get('configJson')?.get('returns_policy') as FormArray;
    // else if (type == 'stock')
    //   return this.cnfgInvFrm.get('configJson')?.get('stock_management_dashboard')?.get('stock_management_dashboard_visibility')?.get('default_store_tag') as FormArray;
    else
      return this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get(type)?.get('configure_by_material_tags')?.get('material_tags') as FormArray;
  }

  CnfgMatTag($event: any, type: any) {
    if ($event.target.checked)
      type == 'issue' ? this.shwMatTabl = true : type == 'receipt' ? this.shwMatTabl1 = true :
        type == 'stock_count' ? this.shwMatTabl2 = true : type == 'discards' ? this.shwMatTabl3 = true :
          type == 'transfer' ? this.shwMatTabl4 = true : type == 'incoming_returns' ? this.shwMatTabl5 = true : this.shwMatTabl6 = true;
    else
      type == 'issue' ? this.shwMatTabl = false : type == 'receipt' ? this.shwMatTabl1 = false :
        type == 'stock_count' ? this.shwMatTabl2 = false : type == 'discards' ? this.shwMatTabl3 = false :
          type == 'transfer' ? this.shwMatTabl4 = false : type == 'incoming_returns' ? this.shwMatTabl5 = false : this.shwMatTabl6 = false;
    // switch (type) {
    //   case 'issue':
    //     this.shwMatTabl = false, 
    // }
  }

  onAsgnRsns($event: any, txt: any, indx: any, ctrl: string) {
    let evnt = $event.target.value.trim(), itms: any[] = [];
    evnt.split(',').filter((item: any) => { itms.push(item.trim()) })
    if (indx != null) {
      this.GetFormArrayData(txt).at(indx).get('lstRsn')?.setValue(itms.filter(b => {
        return b != null && b != ''
      }))
      let lstAr: any[] = this.GetFormArrayData(txt).at(indx).get('lstRsn')?.value;
      this.GetFormArrayData(txt).at(indx).get('reason')?.setValue(lstAr.toString());
    }
    else {
      this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get(txt)?.get('lstRsn')?.setValue(itms.filter(b => {
        return b != null && b != ''
      }));
      let lstAr: any[] = this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get(txt)?.get('lstRsn')?.value;
      this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get(txt)?.get(ctrl)?.setValue(lstAr.toString());
    }
  }

  onAddHrs($event: any) {
    let evnt = $event.target.value.trim(), itms: any[] = [], lstAr: any[] = [];
    evnt.split(',').filter((item: any) => { itms.push(item.trim()) });
    lstAr = itms.filter(b => { return b != null && b != '' }); this.lstHrs = lstAr;
    this.cnfgInvFrm.get('configJson')?.get('transaction_data_export')?.get('transaction_data_export_schedule')?.get('enabled_transaction_data_export')?.
      get('everyday_hours')?.setValue(lstAr.toString());
  }

  onDelMatTags(indx: any, type: string, matTag: any) {
    this.GetFormArrayData(type).removeAt(indx),
      this.cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get(type)?.get('configure_by_material_tags')?.get('matsLst')?.value.push(
        this.lstMatTags.filter(f => f.name == matTag)[0])
  }

  onAddFormCtrls(matTag: any, reason: any, default_reason: any, lstRsn: any) {
    return this.fb.group({ material_tag_id: matTag, reason: reason, default_reason: default_reason, lstRsn: [lstRsn] })
  }

  onChngCmptOpt($event: any) {
    // if ($event.target.value != 'None')
    //   confirm("Demand forecast can be enabled only if 'Consumption rate computation' is set as 'Automatic'. Please set 'Consumption rate computation' as 'Automatic' before enabling Demand forecast.");
  }

  onAddRP() {
    this.GetFormArrayData('').push(this.onCreateRtrns_plcyLst('', [''], ['']));
  }

  onCreateRtrns_plcyLst(store_tags: any, incoming_return_duration_in_days: any, outgoing_return_duration_in_days: any) {
    return this.fb.group({ store_tags: [store_tags], return_policy: this.fb.group({ incoming_return_duration_in_days: incoming_return_duration_in_days, outgoing_return_duration_in_days: outgoing_return_duration_in_days }) })
  }

  onDelRtrn_plcy(rpIndx: any) {
    this.GetFormArrayData('').removeAt(rpIndx)
  }

  onSave() {
    let payLoad = this.cnfgInvFrm.getRawValue(), IsumatBdgs: any[] = [], IsubdgsWthOutDup: any[] = [], IsumatBdgIds: any[] = [],
      stkInmatBdgs: any[] = [], stkInbdgsWthOutDup: any[] = [], stkInmatBdgIds: any[] = [], stkEdmatBdgs: any[] = [], stkEdbdgsWthOutDup: any[] = [],
      stkEdmatBdgIds: any[] = [], stkDcdmatBdgs: any[] = [], stkDcdbdgsWthOutDup: any[] = [], stkDcdmatBdgIds: any[] = [], trnsmatBdgs: any[] = [],
      trnsbdgsWthOutDup: any[] = [], trnsmatBdgIds: any[] = [];
    // Issues tab
    IsumatBdgs = this.mstrsData?.rsnsLst?.filter((e: any) => (e.reasonType == environment.cnfgTrnsRsnType.issue && e.isReasonMandatory == true));
    IsubdgsWthOutDup = [...new Map(IsumatBdgs.map((m) => [m.badgeId, m])).values()]; IsubdgsWthOutDup?.forEach((e: any) => { IsumatBdgIds.push(e.badgeId) });
    // stock-In
    stkInmatBdgs = this.mstrsData?.rsnsLst?.filter((e: any) => (e.reasonType == environment.cnfgTrnsRsnType.stk_in && e.isReasonMandatory == true));
    stkInbdgsWthOutDup = [...new Map(stkInmatBdgs.map((m) => [m.badgeId, m])).values()]; stkInbdgsWthOutDup?.forEach((e: any) => { stkInmatBdgIds.push(e.badgeId) });
    // stock-edit
    stkEdmatBdgs = this.mstrsData?.rsnsLst?.filter((e: any) => (e.reasonType == environment.cnfgTrnsRsnType.stk_edit && e.isReasonMandatory == true));
    stkEdbdgsWthOutDup = [...new Map(stkEdmatBdgs.map((m) => [m.badgeId, m])).values()]; stkEdbdgsWthOutDup?.forEach((e: any) => { stkEdmatBdgIds.push(e.badgeId) });
    // stock-discards
    stkDcdmatBdgs = this.mstrsData?.rsnsLst?.filter((e: any) => (e.reasonType == environment.cnfgTrnsRsnType.discard && e.isReasonMandatory == true));
    stkDcdbdgsWthOutDup = [...new Map(stkDcdmatBdgs.map((m) => [m.badgeId, m])).values()]; stkDcdbdgsWthOutDup?.forEach((e: any) => { stkDcdmatBdgIds.push(e.badgeId) });
    // Transfer
    trnsmatBdgs = this.mstrsData?.rsnsLst?.filter((e: any) => (e.reasonType == environment.cnfgTrnsRsnType.transfer && e.isReasonMandatory == true));
    trnsbdgsWthOutDup = [...new Map(trnsmatBdgs.map((m) => [m.badgeId, m])).values()]; trnsbdgsWthOutDup?.forEach((e: any) => { trnsmatBdgIds.push(e.badgeId) });

    delete payLoad.configJson.transaction_reasons.issue.configure_by_material_tags.material_tag_id,
      delete payLoad.configJson.transaction_reasons.receipt.configure_by_material_tags.material_tag_id,
      delete payLoad.configJson.transaction_reasons.stock_count.configure_by_material_tags.material_tag_id,
      delete payLoad.configJson.transaction_reasons.discards.configure_by_material_tags.material_tag_id,
      delete payLoad.configJson.transaction_reasons.transfer.configure_by_material_tags.material_tag_id,
      delete payLoad.configJson.transaction_reasons.incoming_returns.configure_by_material_tags.material_tag_id,
      delete payLoad.configJson.transaction_reasons.outgoing_returns.configure_by_material_tags.material_tag_id,
      delete payLoad.configJson.transaction_reasons.issue.configure_by_material_tags.matsLst,
      delete payLoad.configJson.transaction_reasons.receipt.configure_by_material_tags.matsLst,
      delete payLoad.configJson.transaction_reasons.stock_count.configure_by_material_tags.matsLst,
      delete payLoad.configJson.transaction_reasons.discards.configure_by_material_tags.matsLst,
      delete payLoad.configJson.transaction_reasons.transfer.configure_by_material_tags.matsLst,
      delete payLoad.configJson.transaction_reasons.incoming_returns.configure_by_material_tags.matsLst,
      delete payLoad.configJson.transaction_reasons.outgoing_returns.configure_by_material_tags.matsLst,
      delete payLoad.configJson.transaction_reasons.issue.lstRsn,
      delete payLoad.configJson.transaction_reasons.receipt.lstRsn,
      delete payLoad.configJson.transaction_reasons.stock_count.lstRsn,
      delete payLoad.configJson.transaction_reasons.discards.lstRsn,
      delete payLoad.configJson.transaction_reasons.transfer.lstRsn,
      delete payLoad.configJson.transaction_reasons.incoming_returns.lstRsn,
      delete payLoad.configJson.transaction_reasons.outgoing_returns.lstRsn,
      delete payLoad.configJson.consumption_ratess,
      payLoad.configJson.consumption_rates = this.cnsumsnDtls ?? [],
      payLoad.configJson.transaction_reasons.issue.make_this_field_mandatory = this.lstTblData.lstIssue?.filter((f: any) => f.isReasonMandatory == true).length > 0 ?? false,
      payLoad.configJson.transaction_reasons.receipt.make_this_field_mandatory = this.lstTblData.lstRcpt?.filter((f: any) => f.isReasonMandatory == true).length > 0 ?? false,
      payLoad.configJson.transaction_reasons.stock_count.make_this_field_mandatory = this.lstTblData.lstStkCnt?.filter((f: any) => f.isReasonMandatory == true).length > 0 ?? false,
      payLoad.configJson.transaction_reasons.discards.make_this_field_mandatory = this.lstTblData.lstDscrd?.filter((f: any) => f.isReasonMandatory == true).length > 0 ?? false,
      payLoad.configJson.transaction_reasons.transfer.make_this_field_mandatory = this.lstTblData.lstTrnsfr?.filter((f: any) => f.isReasonMandatory == true).length > 0 ?? false,
      payLoad.configJson.material_status.issue.status.make_this_field_mandatory = this.lstTblData.lstSTIssue?.filter((f: any) => f.isStatusMandatory == true).length > 0 ?? false,
      payLoad.configJson.material_status.receipt.status.make_this_field_mandatory = this.lstTblData.lstStRcpt?.filter((f: any) => f.isStatusMandatory == true).length > 0 ?? false,
      payLoad.configJson.material_status.stock_count.status.make_this_field_mandatory = this.lstTblData.lstStstkCnt?.filter((f: any) => f.isStatusMandatory == true).length > 0 ?? false,
      payLoad.configJson.material_status.discard.status.make_this_field_mandatory = this.lstTblData.lstStDsrcd?.filter((f: any) => f.isStatusMandatory == true).length > 0 ?? false,
      payLoad.configJson.material_status.transfer.status.make_this_field_mandatory = this.lstTblData.lstStTrnsfr?.filter((f: any) => f.isStatusMandatory == true).length > 0 ?? false,
      payLoad.configJson.transaction_reasons.issue.configure_by_material_tags.make_this_field_mandatory = IsubdgsWthOutDup[0]?.isReasonMandatory ?? false,
      payLoad.configJson.transaction_reasons.issue.configure_by_material_tags.material_tags = IsumatBdgIds ?? [],
      payLoad.configJson.transaction_reasons.receipt.configure_by_material_tags.make_this_field_mandatory = stkInbdgsWthOutDup[0]?.isReasonMandatory ?? false,
      payLoad.configJson.transaction_reasons.receipt.configure_by_material_tags.material_tags = stkInmatBdgIds ?? [],
      payLoad.configJson.transaction_reasons.stock_count.configure_by_material_tags.make_this_field_mandatory = stkEdbdgsWthOutDup[0]?.isReasonMandatory ?? false,
      payLoad.configJson.transaction_reasons.stock_count.configure_by_material_tags.material_tags = stkEdmatBdgIds ?? [],
      payLoad.configJson.transaction_reasons.discards.configure_by_material_tags.make_this_field_mandatory = stkDcdbdgsWthOutDup[0]?.isReasonMandatory ?? false,
      payLoad.configJson.transaction_reasons.discards.configure_by_material_tags.material_tags = stkDcdmatBdgIds ?? [],
      payLoad.configJson.transaction_reasons.transfer.configure_by_material_tags.make_this_field_mandatory = trnsbdgsWthOutDup[0]?.isReasonMandatory ?? false,
      payLoad.configJson.transaction_reasons.transfer.configure_by_material_tags.material_tags = trnsmatBdgIds ?? [],
      payLoad.configJson.transaction_reasons.issue.configure_by_material_tags.material_tags.filter((a: any) => {
        this.lstMatTags.filter(b => {
          if (a.material_tag_id == b.name)
            a.material_tag_id = b?.id;
        }), delete a.lstRsn
      });
    payLoad.configJson.transaction_reasons.receipt.configure_by_material_tags.material_tags.filter((a: any) => {
      this.lstMatTags.filter(b => {
        if (a.material_tag_id == b.name)
          a.material_tag_id = b?.id;
      }), delete a.lstRsn
    });
    payLoad.configJson.transaction_reasons.stock_count.configure_by_material_tags.material_tags.filter((a: any) => {
      this.lstMatTags.filter(b => {
        if (a.material_tag_id == b.name)
          a.material_tag_id = b.id;
      }), delete a.lstRsn
    });
    payLoad.configJson.transaction_reasons.discards.configure_by_material_tags.material_tags.filter((a: any) => {
      this.lstMatTags.filter(b => {
        if (a.material_tag_id == b.name)
          a.material_tag_id = b.id;
      }), delete a.lstRsn
    });
    payLoad.configJson.transaction_reasons.transfer.configure_by_material_tags.material_tags.filter((a: any) => {
      this.lstMatTags.filter(b => {
        if (a.material_tag_id == b.name)
          a.material_tag_id = b.id;
      }), delete a.lstRsn
    });
    payLoad.configJson.transaction_reasons.incoming_returns.configure_by_material_tags.material_tags.filter((a: any) => {
      this.lstMatTags.filter(b => {
        if (a.material_tag_id == b.name)
          a.material_tag_id = b.id;
      }), delete a.lstRsn
    });
    payLoad.configJson.transaction_reasons.outgoing_returns.configure_by_material_tags.material_tags.filter((a: any) => {
      this.lstMatTags.filter(b => {
        if (a.material_tag_id == b.name)
          a.material_tag_id = b.id;
      }), delete a.lstRsn
    });
    payLoad.configJson.transaction_data_export.transaction_data_export_schedule.enabled_transaction_data_export.everyday_hours = this.lstHrs;

    payLoad.configJson.returns_policy.filter((a: any) => {
      /* this.lstFacTags.filter(b => {
        a.store_tags.filter((str: any) => {
          a.store_tags.push(str.id)
          // if (str == b.name)
          //   a.store_tags.push(b.id)
        })
      }), */
      a.return_policy.incoming_return_duration_in_days = a.return_policy.incoming_return_duration_in_days != '' ? + a.return_policy.incoming_return_duration_in_days : null,
        a.return_policy.outgoing_return_duration_in_days = a.return_policy.outgoing_return_duration_in_days != '' ? + a.return_policy.outgoing_return_duration_in_days : null
    });
    payLoad.configJson.stock_management_dashboard.stock_management_dashboard_visibility.default_store_tag = this.slctdDefauFacTag,
      payLoad.configJson.transaction_data_export.transaction_data_export_schedule.enabled_transaction_data_export.group_of_user_tags = this.usrBdges,
      payLoad.configJson.transaction_data_export.transaction_data_export_schedule.enabled_transaction_data_export.group_of_users = this.owners,
      payLoad.configJson.forecasting_and_optimization.computation_options.demand_forecast.min_transaction_period = +payLoad.configJson.forecasting_and_optimization.computation_options.demand_forecast.min_transaction_period,
      payLoad.configJson.forecasting_and_optimization.computation_options.demand_forecast.max_transaction_period = +payLoad.configJson.forecasting_and_optimization.computation_options.demand_forecast.max_transaction_period,
      payLoad.configJson.forecasting_and_optimization.computation_options.demand_forecast.order_periodicity = +payLoad.configJson.forecasting_and_optimization.computation_options.demand_forecast.order_periodicity,
      payLoad.configJson.forecasting_and_optimization.computation_options.demand_forecast.no_of_order_periods = +payLoad.configJson.forecasting_and_optimization.computation_options.demand_forecast.no_of_order_periods,
      payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.min_transaction_period = +payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.min_transaction_period,
      payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.max_transaction_period = +payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.max_transaction_period,
      payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.order_periodicity = +payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.order_periodicity,
      payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.no_of_order_periods = +payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.no_of_order_periods,
      payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.lead_time_average.max_no_of_orders = +payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.lead_time_average.max_no_of_orders,
      payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.lead_time_average.min_no_of_orders = +payLoad.configJson.forecasting_and_optimization.computation_options.optimal_order_quantity.lead_time_average.min_no_of_orders;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.systemConfiguration, environment.apiMthds.saveRupdate, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.message == 'Record updated successfully')
          this.glblSvc.onTstr('s', this.result.message), this.LoadData();
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  onSaveData(ctrl: string) {
    let type = (ctrl == 'Issue' ? environment.cnfgTrnsRsnType.issue : (ctrl == 'Receipt' ? environment.cnfgTrnsRsnType.stk_in :
      (ctrl == 'Stock Count' ? environment.cnfgTrnsRsnType.stk_edit : (ctrl == 'Discard' ? environment.cnfgTrnsRsnType.discard :
        environment.cnfgTrnsRsnType.transfer)))),
      actT = (ctrl == 'Issue' ? 'lstIssue' : (ctrl == 'Receipt' ? 'lstRcpt' :
        (ctrl == 'Stock Count' ? 'lstStkCnt' : (ctrl == 'Discard' ? 'lstDscrd' : 'lstTrnsfr')))), payLoad: any[] = [], reasons: any[] = [];
    // if (this.txnRsnCnt != 0) {
    this.tempData.filter((f: any) => {
      reasons.push({
        id: f.reasonId ? f.reasonId : null,
        isDefault: false, //f.isDefault
        name: f.reasonName,
        reasonType: type,
        isReasonMandatory: this.lstTblData[actT].length != 0 ? this.lstTblData[actT][0]?.isReasonMandatory : false,
        updatedBy: this.usrLgnDtls?.userId,
      })
    }), payLoad = reasons;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.rsnType, environment.apiMthds.svRupdate, payLoad, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.tempData = []; setTimeout(() => { this.getStatus(); }, 1000);
          this.glblSvc.onTstr('s', this.result.message), this.txnRsnCnt = 0; this.cnfgInvFrm.get('issTxt')?.setValue(['']); //this.enbleAddBtn = false;
          let allMstrs = JSON.parse(<string>localStorage.getItem('lgnMstrsData'));
          if (allMstrs.rsnsLst)
            this.glblSvc.getAPI(environment.apiPaths.rsnType, environment.apiMthds.get_all, 2, 1).subscribe(s => {
              if (s)
                allMstrs.rsnsLst = s?.data ?? [], localStorage.setItem('lgnMstrsData', JSON.stringify(allMstrs)),
                  this.glblSvc.sbjctMstrData.next(allMstrs);
            });
        }
        else {
          this.glblSvc.onTstr('e', this.result.message);
          let allMstrs = JSON.parse(<string>localStorage.getItem('lgnMstrsData'));
          if (allMstrs.rsnsLst)
            this.glblSvc.getAPI(environment.apiPaths.rsnType, environment.apiMthds.get_all, 2, 1).subscribe(s => {
              if (s)
                allMstrs.rsnsLst = s?.data ?? [], localStorage.setItem('lgnMstrsData', JSON.stringify(allMstrs)),
                  this.glblSvc.sbjctMstrData.next(allMstrs), this.getStatus();
            });
        }
      }
    });
    // }
  }

  onChngRB(ctrl: string, item: any) {
    let actT = (ctrl == 'Issue' ? 'lstIssue' : (ctrl == 'Receipt' ? 'lstRcpt' :
      (ctrl == 'Stock Count' ? 'lstStkCnt' : (ctrl == 'Discard' ? 'lstDscrd' : 'lstTrnsfr'))));
    this.lstTblData[actT].filter((a: any) => {
      a.isDefault = false;
    }), item.isDefault = true;
    ctrl == 'Transfer' ? this.RDBchckd = false : ctrl == 'Discard' ? this.RDBchckd1 = false : ctrl == 'Stock Count' ?
      this.RDBchckd2 = false : ctrl == 'Receipt' ? this.RDBchckd3 = false : this.RDBchckd4 = false;
  }

  onChk(ctrl: string, IsMandtry: Boolean) {
    let actT = (ctrl == 'Issue' ? 'lstSTIssue' : (ctrl == 'Receipt' ? 'lstStRcpt' : (ctrl == 'Stock Count' ? 'lstStstkCnt' :
      (ctrl == 'Discard' ? 'lstStDsrcd' : (ctrl == 'IssueTS' ? 'lstSTIssueTS' : (ctrl == 'ReceiptTS' ? 'lstStRcptTS' :
        (ctrl == 'StkCntTS' ? 'lstStstkCntTS' : (ctrl == 'DiscrdTS' ? 'lstStDsrcdTS' : (ctrl == 'TnsfrTS' ? 'lstStTrnsfrTS' :
          'lstStTrnsfr')))))))));
    this.lstTblData[actT].forEach((e: any) => { e.isStatusMandatory = IsMandtry; }); // this.lstTblData[actT].filter((a: any) => { a.isStatusMandatory = IsMandtry; });
  }

  onChkRsn(ctrl: string, IsMandtry: Boolean) {
    let actT = (ctrl == 'Issue' ? 'lstIssue' : (ctrl == 'Receipt' ? 'lstRcpt' : (ctrl == 'Stock Count' ? 'lstStkCnt' : (ctrl == 'Discard' ?
      'lstDscrd' : (ctrl == 'Transfer' ? 'lstTrnsfr' : (ctrl == 'IssueCMC' ? 'lstIssBdgeRsn' : (ctrl == 'ReceiptCMC' ? 'lstRcptBdgeRsn' : 
      (ctrl == 'Stock CountCMC' ? 'lstScBdgeRsn' : (ctrl == 'DiscardCMC' ? 'lstDsrcdBdgeRsn' : (ctrl == 'TransferCMC' ? 'lstTrsfrBdgeRsn' : ''))))))))));

    if (actT != '')
      this.lstTblData[actT].forEach((e: any) => { e.isReasonMandatory = IsMandtry; }); // this.lstTblData[actT].filter((a: any) => { a.isReasonMandatory = IsMandtry; });
  }

  // enbleAddBtnFn(event: any) {
  //   let txt = event.target.value;
  //   if(txt.trim().length>0){
  //     this.enbleAddBtn = true;
  //    }
  //    else{
  //      this.enbleAddBtn = false;
  //    }
  // }

  onAddDel(ctrl: string, rowVal: any, actType: string, isMndt: Boolean = false) {
    let type = (ctrl == 'Issue' ? environment.cnfgTrnsRsnType.issue : (ctrl == 'Receipt' ? environment.cnfgTrnsRsnType.stk_in :
      (ctrl == 'Stock Count' ? environment.cnfgTrnsRsnType.stk_edit : (ctrl == 'Discard' ? environment.cnfgTrnsRsnType.discard :
        environment.cnfgTrnsRsnType.transfer)))),
      actT = (ctrl == 'Issue' ? 'lstIssue' : (ctrl == 'Receipt' ? 'lstRcpt' :
        (ctrl == 'Stock Count' ? 'lstStkCnt' : (ctrl == 'Discard' ? 'lstDscrd' : 'lstTrnsfr'))));
    if (actType == 'a' && rowVal != '') {
      this.lstTblData[actT].length == 0 ? isMndt = false : isMndt = isMndt;
      if (this.lstTblData[actT].filter((f: any) => f.reasonName == rowVal).length == 0) {
        this.tempData = [...this.lstTblData[actT]];
        this.tempData.push({ reasonName: rowVal.trim(), reasonType: type, isReasonMandatory: isMndt, isDefault: false })
        // this.lstTblData[actT].push({ reasonName: rowVal.trim(), reasonType: type, isReasonMandatory: isMndt, isDefault: false }),// this.txnRsnCnt++,
        this.onSaveData(ctrl);
      }
      else
        this.glblSvc.onTstr('w', 'Tag is already exists');
    }
    else {
      if (rowVal.reasonId) {
        if (confirm('Are you sure, you want to delete.?')) {
          this.FetchData(environment.apiCalls.del, environment.apiPaths.rsnType, environment.apiMthds.delById + rowVal.reasonId, null,
            2, true, 1).then(() => {
              if (this.result) {
                if (this.result.status == 'OK') {
                  // this.lstTblData[actT] = this.lstTblData[actT].filter((f: any) => f.reasonName != rowVal.reasonName);
                  this.glblSvc.onTstr('s', this.result.message);
                  let allMstrs = JSON.parse(<string>localStorage.getItem('lgnMstrsData'));
                  if (allMstrs.rsnsLst)
                    this.glblSvc.getAPI(environment.apiPaths.rsnType, environment.apiMthds.get_all, 2, 1).subscribe(s => {
                      if (s)
                        allMstrs.rsnsLst = s?.data ?? [], localStorage.setItem('lgnMstrsData', JSON.stringify(allMstrs)),
                          this.glblSvc.sbjctMstrData.next(allMstrs), this.getStatus();
                    });
                }
                else
                  this.glblSvc.onTstr('e', this.result.message);
              }
            });
        }
      }
      // else {
      //   this.lstTblData[actT] = this.lstTblData[actT].filter((f: any) => f.reasonName != rowVal.reasonName);
      // }
      this.txnRsnCnt--;
    }
  }

  onAddDelete(ctrl: string, rowVal: any, actType: string, isMndt: Boolean = false) {
    let type = (ctrl == 'Issue' ? environment.cnfgTrnsMatStsType.issue : (ctrl == 'Receipt' ? environment.cnfgTrnsMatStsType.stk_in :
      (ctrl == 'Stock Count' ? environment.cnfgTrnsMatStsType.stk_edit : (ctrl == 'Discard' ? environment.cnfgTrnsMatStsType.discard :
        (ctrl == 'IssueTS' ? environment.cnfgTrnsMatStsType.issueTSM : (ctrl == 'ReceiptTS' ?
          environment.cnfgTrnsMatStsType.stk_inTSM : (ctrl == 'StkCntTS' ? environment.cnfgTrnsMatStsType.stk_editTSM : (ctrl == 'DiscrdTS' ?
            environment.cnfgTrnsMatStsType.discardTSM : (ctrl == 'TnsfrTS' ? environment.cnfgTrnsMatStsType.transferTSM : environment.cnfgTrnsMatStsType.transfer))))))))),
      actT = (ctrl == 'Issue' ? 'lstSTIssue' : (ctrl == 'Receipt' ? 'lstStRcpt' : (ctrl == 'Stock Count' ? 'lstStstkCnt' :
        (ctrl == 'Discard' ? 'lstStDsrcd' : (ctrl == 'IssueTS' ? 'lstSTIssueTS' : (ctrl == 'ReceiptTS' ? 'lstStRcptTS' :
          (ctrl == 'StkCntTS' ? 'lstStstkCntTS' : (ctrl == 'DiscrdTS' ? 'lstStDsrcdTS' : (ctrl == 'TnsfrTS' ? 'lstStTrnsfrTS' : 'lstStTrnsfr')))))))));
    if (actType == 'a' && rowVal != '')
      if (this.lstTblData[actT].filter((f: any) => f.name == rowVal).length == 0)
        this.tempAddStatus = [...this.lstTblData[actT]],
          this.tempAddStatus.push({ name: rowVal.trim(), statusType: type, isStatusMandatory: isMndt }),
          // this.lstTblData[actT].push({ name: rowVal, statusType: type, isStatusMandatory: isMndt }),// this.txnStsCnt++,
          this.onSaveStatus(ctrl);
      else
        this.glblSvc.onTstr('w', 'Tag is already exists');
    else {
      if (rowVal.id) {
        if (confirm('Are you sure, you want to delete.?')) {
          this.FetchData(environment.apiCalls.del, environment.apiPaths.mstrSts, environment.apiMthds.delById + rowVal.id, null, 2, true, 1).then(() => {
            if (this.result) {
              if (this.result.status == 'OK') {
                setTimeout(() => { this.getStatus() }, 500),
                  // this.lstTblData[actT] = this.lstTblData[actT].filter((f: any) => f.name != rowVal.name);
                  this.glblSvc.onTstr('s', this.result.message);
              }
              else
                this.glblSvc.onTstr('e', this.result.message);
            }
          });
        }
      }
      else
        this.lstTblData[actT] = this.lstTblData[actT].filter((f: any) => f.name != rowVal.name);
      this.txnStsCnt--;
    }
  }

  clear(ctrl: string) {
    let actT = (ctrl == 'Issue' ? 'lstIssue' : (ctrl == 'Receipt' ? 'lstRcpt' :
      (ctrl == 'Stock Count' ? 'lstStkCnt' : (ctrl == 'Discard' ? 'lstDscrd' : 'lstTrnsfr'))));
    this.lstTblData[actT].filter((a: any) => a.rbVal = ''), this.lstTblData[actT].filter((a: any) => a.isDefault = false),
      ctrl == 'Transfer' ? this.RDBchckd = true : ctrl == 'Discard' ? this.RDBchckd1 = true : ctrl == 'Stock Count' ? this.RDBchckd2 = true :
        ctrl == 'Receipt' ? this.RDBchckd3 = true : this.RDBchckd4 = true;
  }

  getStatus() {
    let rsltData = {};
    this.FetchData(environment.apiCalls.get, environment.apiPaths.mstrSts, environment.apiMthds.getAll, null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.stIssue = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.issue),
              this.stReceipt = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.stk_in),
              this.stStkCnt = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.stk_edit),
              this.stDscrd = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.discard),
              this.stTrnsfr = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.transfer),
              this.lstSTIssueTS = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.issueTSM),
              this.lstStRcptTS = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.stk_inTSM),
              this.lstStstkCntTS = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.stk_editTSM),
              this.lstStDsrcdTS = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.discardTSM),
              this.lstStTrnsfrTS = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.transferTSM);
            if (this.rsnsTnsfr?.filter(a => a.isDefault == true).length > 0) this.RDBchckd = false; else this.RDBchckd = true;
            if (this.rsnsDscrd?.filter(a => a.isDefault == true).length > 0) this.RDBchckd1 = false; else this.RDBchckd1 = true;
            if (this.rsnsStkCnt?.filter(a => a.isDefault == true).length > 0) this.RDBchckd2 = false; else this.RDBchckd2 = true;
            if (this.rsnsRcpt?.filter(a => a.isDefault == true).length > 0) this.RDBchckd3 = false; else this.RDBchckd3 = true;
            if (this.rsnsIssue?.filter(a => a.isDefault == true).length > 0) this.RDBchckd4 = false; else this.RDBchckd4 = true;
            rsltData = {
              lstStstkCnt: this.stStkCnt, lstStDsrcd: this.stDscrd, lstStTrnsfr: this.stTrnsfr,
              lstSTIssue: this.stIssue, lstStRcpt: this.stReceipt, stkCnt: this.rsnsStkCnt,
              dscrd: this.rsnsDscrd, trnsfr: this.rsnsTnsfr, lstIssue: this.rsnsIssue, lstRcpt: this.rsnsRcpt,
              lstSTIssueTS: this.lstSTIssueTS, lstStRcptTS: this.lstStRcptTS, lstStstkCntTS: this.lstStstkCntTS,
              lstStDsrcdTS: this.lstStDsrcdTS, lstStTrnsfrTS: this.lstStTrnsfrTS
            }, this.setData(rsltData)
          }
        }
      }
    });
  }

  setData(data: any) {
    this.lstTblData = {
      lstStkCnt: data?.stkCnt ?? [], lstDscrd: data?.dscrd ?? [], lstTrnsfr: data?.trnsfr ?? [], lstRcptBdgeRsn: [],
      lstIssue: data?.lstIssue ?? [], lstRcpt: data?.lstRcpt ?? [], lstStstkCnt: data?.lstStstkCnt ?? [], lstStDsrcd: data?.lstStDsrcd ?? [],
      lstStTrnsfr: data?.lstStTrnsfr ?? [], lstSTIssue: data?.lstSTIssue ?? [], lstStRcpt: data?.lstStRcpt ?? [], lstIssBdgeRsn: [],
      lstSTIssueTS: data?.lstSTIssueTS ?? [], lstStRcptTS: data?.lstStRcptTS ?? [], lstStstkCntTS: data?.lstStstkCntTS ?? [], lstScBdgeRsn: [],
      lstStDsrcdTS: data?.lstStDsrcdTS ?? [], lstStTrnsfrTS: data?.lstStTrnsfrTS ?? [], lstDsrcdBdgeRsn: [], lstTrsfrBdgeRsn: []
    },
      this.IssmndtRsn = this.cnfgData?.cnfgInvData?.transaction_reasons?.issue?.make_this_field_mandatory ?? false, // this.IssmndtRsn = this.lstTblData.lstIssue.length == 0 ? false : this.lstTblData.lstIssue[0].isReasonMandatory,
      this.RcptmndtRsn = this.cnfgData?.cnfgInvData?.transaction_reasons?.receipt?.make_this_field_mandatory ?? false, // this.RcptmndtRsn = this.lstTblData.lstRcpt.length == 0 ? false : this.lstTblData.lstRcpt[0].isReasonMandatory,
      this.StkmndtRsn = this.cnfgData?.cnfgInvData?.transaction_reasons?.stock_count?.make_this_field_mandatory ?? false, // this.StkmndtRsn = this.lstTblData.lstStkCnt.length == 0 ? false : this.lstTblData.lstStkCnt[0].isReasonMandatory,
      this.DscrdmndtRsn = this.cnfgData?.cnfgInvData?.transaction_reasons?.discards?.make_this_field_mandatory ?? false, // this.DscrdmndtRsn = this.lstTblData.lstDscrd.length == 0 ? false : this.lstTblData.lstDscrd[0].isReasonMandatory,
      this.TnsfrmndtRsn = this.cnfgData?.cnfgInvData?.transaction_reasons?.transfer?.make_this_field_mandatory ?? false, // this.TnsfrmndtRsn = this.lstTblData.lstTrnsfr.length == 0 ? false : this.lstTblData.lstTrnsfr[0].isReasonMandatory,
      this.Issmndt = this.cnfgData?.cnfgInvData?.material_status?.issue?.status?.make_this_field_mandatory ?? false, // this.Issmndt = this.lstTblData.lstSTIssue.length == 0 ? false : this.lstTblData.lstSTIssue[0].isStatusMandatory,
      this.Rcptmndt = this.cnfgData?.cnfgInvData?.material_status?.receipt?.status?.make_this_field_mandatory ?? false, // this.Rcptmndt = this.lstTblData.lstStRcpt.length == 0 ? false : this.lstTblData.lstStRcpt[0].isStatusMandatory,
      this.Stkmndt = this.cnfgData?.cnfgInvData?.material_status?.stock_count?.status?.make_this_field_mandatory ?? false, // this.Stkmndt = this.lstTblData.lstStstkCnt.length == 0 ? false : this.lstTblData.lstStstkCnt[0].isStatusMandatory,
      this.Dscrdmndt = this.cnfgData?.cnfgInvData?.material_status?.discard?.status?.make_this_field_mandatory ?? false, // this.Dscrdmndt = this.lstTblData.lstStDsrcd.length == 0 ? false : this.lstTblData.lstStDsrcd[0].isStatusMandatory,
      this.Tnsfrmndt = this.cnfgData?.cnfgInvData?.material_status?.transfer?.status?.make_this_field_mandatory ?? false, // this.Tnsfrmndt = this.lstTblData.lstStTrnsfr.length == 0 ? false : this.lstTblData.lstStTrnsfr[0].isStatusMandatory,

      this.IssmndtTS = this.lstTblData.lstSTIssueTS.length == 0 ? false : this.lstTblData.lstSTIssueTS[0].isStatusMandatory, // this.IssmndtTS = this.cnfgData?.cnfgInvData?.material_status?.issue?.status?.make_this_field_mandatory ?? false,
      this.RcptmndtTS = this.lstTblData.lstStRcptTS.length == 0 ? false : this.lstTblData.lstStRcptTS[0].isStatusMandatory, // this.RcptmndtTS = this.cnfgData?.cnfgInvData?.material_status?.issue?.status?.make_this_field_mandatory ?? false,
      this.StkmndtTS = this.lstTblData.lstStstkCntTS.length == 0 ? false : this.lstTblData.lstStstkCntTS[0].isStatusMandatory, // this.StkmndtTS = this.cnfgData?.cnfgInvData?.material_status?.issue?.status?.make_this_field_mandatory ?? false,
      this.DscrdmndtTS = this.lstTblData.lstStDsrcdTS.length == 0 ? false : this.lstTblData.lstStDsrcdTS[0].isStatusMandatory, // this.DscrdmndtTS = this.cnfgData?.cnfgInvData?.material_status?.issue?.status?.make_this_field_mandatory ?? false,
      this.TnsfrmndtTS = this.lstTblData.lstStTrnsfrTS.length == 0 ? false : this.lstTblData.lstStTrnsfrTS[0].isStatusMandatory; // this.TnsfrmndtTS = this.cnfgData?.cnfgInvData?.material_status?.issue?.status?.make_this_field_mandatory ?? false;
  }

  onSaveStatus(ctrl: string) {
    let type = (ctrl == 'Issue' ? environment.cnfgTrnsMatStsType.issue : (ctrl == 'Receipt' ? environment.cnfgTrnsMatStsType.stk_in :
      (ctrl == 'Stock Count' ? environment.cnfgTrnsMatStsType.stk_edit : (ctrl == 'Discard' ? environment.cnfgTrnsMatStsType.discard :
        (ctrl == 'IssueTS' ? environment.cnfgTrnsMatStsType.issueTSM : (ctrl == 'ReceiptTS' ?
          environment.cnfgTrnsMatStsType.stk_inTSM : (ctrl == 'StkCntTS' ? environment.cnfgTrnsMatStsType.stk_editTSM : (ctrl == 'DiscrdTS' ?
            environment.cnfgTrnsMatStsType.discardTSM : (ctrl == 'TnsfrTS' ? environment.cnfgTrnsMatStsType.transferTSM : environment.cnfgTrnsMatStsType.transfer))))))))),
      actT = (ctrl == 'Issue' ? 'lstSTIssue' : (ctrl == 'Receipt' ? 'lstStRcpt' : (ctrl == 'Stock Count' ? 'lstStstkCnt' :
        (ctrl == 'Discard' ? 'lstStDsrcd' : (ctrl == 'IssueTS' ? 'lstSTIssueTS' : (ctrl == 'ReceiptTS' ? 'lstStRcptTS' :
          (ctrl == 'StkCntTS' ? 'lstStstkCntTS' : (ctrl == 'DiscrdTS' ? 'lstStDsrcdTS' : (ctrl == 'TnsfrTS' ? 'lstStTrnsfrTS' :
            'lstStTrnsfr'))))))))), payLoad: any[] = [], status: any[] = [];
    // if (this.txnStsCnt != 0) {
    this.tempAddStatus.filter((f: any) => {
      status.push({
        id: f.id ? f.id : null, remarks: f.name, name: f.name, statusType: type,
        isStatusMandatory: this.lstTblData[actT][0]?.isStatusMandatory, updatedBy: this.usrLgnDtls.userId
      })
    }), payLoad = status;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.mstrSts, environment.apiMthds.svRupdate, payLoad, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.getStatus();//setTimeout(() => { this.getStatus()}, 1000);
          this.glblSvc.onTstr('s', this.result.message), this.txnStsCnt = 0;
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
    // }
  }

  minMaxChange(evnt: any) {
    let value = evnt.target.value;
    if (value == "Duration of stock")
      this.duration = true;
    else
      this.duration = false;
  }

  minMaxDuration(evnt: any) {
    // this.prd = '1';
  }

  onActn(c: string, i: number = 0) {
    let payLoad = this.cnfgInvFrm.getRawValue();
    if ( c == 'a') {
      if ((this.lstSlctdMatTags.length !=0 || this.lstSlctSmMtrls.length !=0) && payLoad.configJson.consumption_ratess.min_max_range !='') {
        this.cnsumsnDtls?.push({ 
          mtrlNme: this.lstSlctSmMtrls.map(({ name }) => name),
          mtrlTagNme: this.lstSlctdMatTags.map(({ name }) => name),
          stkMtrlNme : this.lstSlctSmMtrls ?? [], stkMtrlBdge: this.lstSlctdMatTags ?? [], range: '1',
          PrdName: (this.prd != '' && this.prd  !=null) ? this.lsrPrd.filter(f => f.id == +this.prd)[0]?.name : '',
          prdId: this.prd ?? '', min_max_range: payLoad.configJson.consumption_ratess.min_max_range, isStkMatBdge: payLoad.configJson.consumption_ratess.isStkMatBdge,
        });
        this.prd = '1', this.slctdStkMatName = [], this.slctdStkMat = [], this.lstSlctdMatTags = [], this.lstSlctSmMtrls = [], this.isStkMatBdge = 'badge',
        this.cnfgInvFrm.get('configJson')?.get('consumption_ratess')?.get('min_max_range')?.setValue(''),
        this.cnfgInvFrm.get('configJson')?.get('consumption_ratess')?.get('stkMtrlNme')?.setValue([]),
        this.cnfgInvFrm.get('configJson')?.get('consumption_ratess')?.get('stkMtrlBdge')?.setValue([]),
        this.cnfgInvFrm.get('configJson')?.get('consumption_ratess')?.get('range')?.setValue('1');
      } else
        this.glblSvc.onTstr('w', 'Please Select the Material Tag/Name and Average/Peak');
    } else {
      this.cnsumsnDtls.splice(i, 1);
    }
    let lstTblMts: any[] = [], lstTblMtsTags: any[] = [],  tags: any[] = [], nmes: any[] = [], lstFreeMtrlTags: any[] = [], lstFreeMtrlNme: any[] = [];
        this.cnsumsnDtls.forEach(e=>{tags.push(e.stkMtrlBdge)}), this.cnsumsnDtls.forEach(e=>{nmes.push(e.stkMtrlNme)}),
        lstTblMtsTags = Array.prototype.concat(...tags), lstTblMts = Array.prototype.concat(...nmes),
        this.lstMatTags1.forEach(e => { if (lstTblMtsTags.filter(f => f.id === e.id).length == 0) lstFreeMtrlTags.push(e); });  
        this.smMtrlList.forEach(e => { if (lstTblMts.filter(f => f.id === e.id).length == 0) lstFreeMtrlNme.push(e); });  
        this.lstMatTblTags = lstFreeMtrlTags, this.smMtrlTblList = lstFreeMtrlNme;
  }
}