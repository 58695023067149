import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { ShipCreateMdlComponent } from 'src/app/Components/evin/Pop-ups/ship-create-mdl/ship-create-mdl.component';
import { CnclOrderComponent } from 'src/app/Components/evin/Pop-ups/cncl-order/cncl-order.component';
import { OrdrVarComponent } from 'src/app/Components/evin/Pop-ups/ordr-var/ordr-var.component';
import { OrdrParComponent } from 'src/app/Components/evin/Pop-ups/ordr-par/ordr-par.component';

declare let $: any;

@Component({
  selector: 'evin-orders-status',
  templateUrl: './orders-status.component.html',
  styleUrls: ['./orders-status.component.css']
})

export class OrdersStatusComponent extends BaseComponent implements OnInit {

  OrderHistory: any; noRcrdsTxt = environment.TblNoRcrds; tt = 'e-Demand low'; progressdata: any[] = []; allocateS = false;
  orderId: any; orderStatus: any[] = []; prgrsStatus: any[] = []; lstMats: any[] = []; lstShipments: any[] = []; isSb = false; defWdthVal = 25;
  midWdthVal = 12.5; dispatchForm = this.formBuilder.group({ DispatchComment: [] }); shwAllTab = false; userlgndtls : any = null;
  editFlag = false; ordrStatForm: FormGroup = new FormGroup({}); rsnsLst: any[] = []; isSbmtd = false; lstAllMats: any[] = [];
  MatsLst: any[] = []; edflg = true; rdflg = true; bbflg = true; irflg = true; lstTags: any[] = []; oldrsnsLst: any[] = []; paramTabName: any;
  lstAllComments: any[] = []; cmntStat = false; dtToDay = new Date(); isRsnMndtry = false; isoldRsnMndtry = false; isAlctSave = false;
  mmIdDtlsSI: boolean = true; mmIdDtlsEd: boolean = true; mmIdDtlsIh: boolean = true; mmIdDtlsAd: boolean = true; mmIdDtlsRi: boolean = true;
  mmIdDtlsDi: boolean = true; mmIdDtlsFi: boolean = true; mmIdDtlsCi: boolean = true; mmIdDtlsEe: boolean = true; mmIdDtlsAi: boolean = true;
  mmIdDtlsEc: boolean = true; mmIdDtlsEr: boolean = true; mmIdDtlsEi: boolean = true; isMinmax: boolean = false; isFleShwHde: boolean = true;
  isRsnAlw = false; cnfgData: any = JSON.parse(<string>localStorage.getItem('lgnCnfgData')); fileName: any = ''; fileFormat = ''; cargosId: any;
  btchEnble: any; isFlePthShwHde: boolean = true;

  constructor(public glblSvc: GlobalService,
    private ngbMdl: NgbModal,
    public dtPipe: DatePipe,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.rsnsLst = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.new_indent),
        this.oldrsnsLst = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.update_indent) }), this.ordrStatForm = this.formBuilder.group({
      bookingItems: this.formBuilder.array([]), srchMtrl: [], bookingBadge: [''], estimatedDateOfArvl: [], issuseRefNo: [], requiredByDate: [],
      comment: ['']});
    // this.isRsnMndtry = this.cnfgData?.cnfgOdrData?.order_reasons?.reasons_for_overriding_order_recommendation?.mandatory ?? false,
    //   this.isoldRsnMndtry = this.cnfgData?.cnfgOdrData?.order_reasons?.reasons_for_overriding_order_recommendation?.mandatory ?? false;
    if (this.rsnsLst?.filter(a => a.isReasonMandatory == true).length > 0)
      this.isRsnMndtry = true;
    else
      this.isRsnMndtry = false;
    if (this.oldrsnsLst?.filter(a => a.isReasonMandatory == true).length > 0)
      this.isoldRsnMndtry = true;
    else
      this.isoldRsnMndtry = false;
  }

  ngOnInit(): void {
    if (this.usrLgnDtls) {
      this.userlgndtls = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? 
      this.usrLgnDtls.domainPermissions?.Indents.filter((e: any) => { if (e.menuCode == 'mm_id_ids_dt_si') this.mmIdDtlsSI = e.action;
      if (e.menuCode == 'mm_id_ids_dt_ed') this.mmIdDtlsEd = e.action; if (e.menuCode == 'mm_id_ids_dt_ih') this.mmIdDtlsIh = e.action;
      if (e.menuCode == 'mm_id_ids_dt_ad') this.mmIdDtlsAd = e.action; if (e.menuCode == 'mm_id_ids_dt_ri') this.mmIdDtlsRi = e.action;
      if (e.menuCode == 'mm_id_ids_dt_di') this.mmIdDtlsDi = e.action; if (e.menuCode == 'mm_id_ids_dt_fi') this.mmIdDtlsFi = e.action;
      if (e.menuCode == 'mm_id_ids_dt_ci') this.mmIdDtlsCi = e.action; if (e.menuCode == 'mm_id_ids_dt_ee') this.mmIdDtlsEe = e.action;
      if (e.menuCode == 'mm_id_ids_dt_ai') this.mmIdDtlsAi = e.action; if (e.menuCode == 'mm_id_ids_dt_ec') this.mmIdDtlsEc = e.action;
      if (e.menuCode == 'mm_id_ids_dt_er') this.mmIdDtlsEr = e.action; if (e.menuCode == 'mm_id_ids_dt_ei') this.mmIdDtlsEi = e.action;}) : null;
    }
    ['isIndntAPICald', 'isRelsAPICald', 'isTrnsfrAPICald'].forEach(e => { localStorage.removeItem(e); });
    this.route.queryParams.subscribe(params => { this.paramTabName = params.type; }), this.glblSvc.sbjctOrdId.subscribe((s: any) => {
      this.orderId = s;
      if (s > 0)
        this.GetBadges(), this.LoadOrderStatus(this.orderId), this.LoadProductsByOrderId(this.orderId), this.LoadShipments(this.orderId),
          this.LoadProgressBar(this.orderId), this.loadComments(this.orderId), this.ordrStatForm = this.formBuilder.group({ srchMtrl: [],
            bookingItems: this.formBuilder.array([]), bookingBadge: [], estimatedDateOfArvl: [], issuseRefNo: [], requiredByDate: [],
            comment: [''] }), this.editFlag = false, this.allocateS = false;
      else
        this.routingToLocation('list', this.paramTabName); // this.router.navigate(['home/ord-ord'], { queryParams: { tab: 'list' } });
    });
  }

  routingToLocation(tabPath: any, typePath: any){
    if (this.paramTabName == 'i')
      this.glblSvc.sbjctTabsPage.next('indnt-list'),
      this.router.navigate(['home/ord-ord'], { queryParams: { tab: tabPath, type: typePath } });
    else if(this.paramTabName == 'r')
      this.glblSvc.sbjctTabsPage.next('rels-list'),
      this.router.navigate(['home/ord-rls'], { queryParams: { tab: tabPath, type: typePath } });
    else if(this.paramTabName == 't')
      this.glblSvc.sbjctTabsPage.next('trsf-list'),
      this.router.navigate(['home/ord-transfers'], { queryParams: { tab: tabPath, type: typePath } });
    else  
      this.router.navigate(['home/ord-invalid'], { queryParams: { tab: tabPath, type: typePath } });
  }
  
  onConfrim(orderID: any){
    let payLoad = { bookingId: orderID, userId: this.usrLgnDtls.userId, comments: null };
    this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.cnfrmOrder, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')         
          this.glblSvc.onTstr('s', this.result.message), this.ngOnInit();
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  onReopen(orderID: any){
    if(confirm("Are you sure you want to reopen the indent ?")){
      let payLoad = { bookingId: orderID, userId: this.usrLgnDtls.userId, comments: null };
      this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.reopenOrder, payLoad).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.message == 'Please check the Order Status..')         
            this.glblSvc.onTstr('w', this.result.message);
          else if(this.result.status == 'OK')
            this.glblSvc.onTstr('s', this.result.message),  this.ngOnInit();
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }  
  }

  GetBadges() {
    this.lstTags = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.badge, environment.apiMthds.getBadges, null,
        2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.lstTags = this.result.data;
      }
    });
  }

  orderS() {
    /* // this.progressdata.filter(f => {
    //   if (f.active == true)
    //     $("#progress-bar-container li.active::before").css("background", '#28a745')
    // });
    // for (let i = 0; i < this.progressdata.length; i++) {
    //   if (name == this.progressdata[i].label) {
    let val = ((this.progressdata.filter(f => f.active == true).length - 1) * this.defWdthVal)  //+ this.midWdthVal;
    // $(this).addClass("active").prevAll().addClass("active");
    $(this).nextAll().removeClass("active");
    // $("#line-progress").css("width", "this.orderStatus[i].progress");
    $("#line-progress").css("width", `${val}%`);
    return;
    //   }
    // } */
    if (this.orderStatus[0].status != 'Cancelled') {
      let val = ((this.progressdata?.filter(f => f.active == true).length - 1) * this.defWdthVal)  //+ this.midWdthVal;
      $(this).nextAll().removeClass("active"), $("#line").css("width", `80%`), $("#line").css("background", `#fff`),
        $("#line-progress").css("width", `${val}%`);
      return;
    }
    else {
      let defWV = (this.progressdata.length == 2 ? 20 : (this.progressdata.length == 3 ? 40 : (this.progressdata.length == 4 ? 60 : 80))),
        val = (this.progressdata.length == 2 ? 50 : (this.progressdata.length == 3 ? 75 : (this.progressdata.length == 4 ? 85 : 90)));
      $(this).nextAll().removeClass("active"), $("#line").css("width", `${defWV}%`), $("#line").css("background", `#ff0000`),
        $("#line-progress").css("width", `${val}%`);
      return;
    }
  }

  updateOrderDetails() {
    let payLoad = this.ordrStatForm.getRawValue();
    payLoad.userId = this.usrLgnDtls.userId, payLoad.bookingId = this.orderId,
      delete payLoad.bookingItems, delete payLoad.srchMtrl,
      payLoad.estimatedDateOfArvl = (payLoad.estimatedDateOfArvl != null && payLoad.estimatedDateOfArvl != '') ? this.glblSvc.setDtFrmt(payLoad.estimatedDateOfArvl, this.dtF.ymd) + this.tmF.zeroTM : '',
      payLoad.requiredByDate = (payLoad.requiredByDate != null && payLoad.requiredByDate != '') ? this.glblSvc.setDtFrmt(payLoad.requiredByDate, this.dtF.ymd) + this.tmF.zeroTM : '',
    this.FetchData(environment.apiCalls.post, environment.apiPaths.booking, environment.apiMthds.updateBookingDetails, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.glblSvc.onTstr('s', this.result.message)
          if (this.result.message === 'Order record updated successfully')
            this.LoadOrderStatus(this.orderId), this.GetMatrls().controls.length = 0, this.LoadProductsByOrderId(this.orderId),
              this.LoadShipments(this.orderId), this.LoadProgressBar(this.orderId), this.loadComments(this.orderId),
              setTimeout(() => { this.orderS(); }, 1000), this.ordrStatForm.get('estimatedDateOfArvl')?.setValue(''),
              this.ordrStatForm.get('bookingBadge')?.setValue(''), this.ordrStatForm.get('issuseRefNo')?.setValue(''),
              this.ordrStatForm.get('requiredByDate')?.setValue(''), this.edflg = true; this.rdflg = true; this.bbflg = true; this.irflg = true;
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  clearBSDate(ctrl: string) {
    this.ordrStatForm.get(ctrl)?.setValue('');
  }

  LoadOrderStatus(orderId: any) {
    if (orderId) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings, environment.apiMthds.getBookingsById.replace('ooo', orderId), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.orderStatus = this.result.data != null ? this.result.data : [], this.prgrsStatus = this.orderStatus[0].statusesForBar,
            localStorage.setItem('estimateArvl', this.orderStatus[0]?.estimatedDateOfArrival);
              this.LoadMatsbyStoreId();
            if (this.orderStatus.length != 0)
              this.updateForm(this.orderStatus[0])
          }
        }
      });
    }
  }

  updateForm(d: any) {
    let bkngBadg = d?.bookingBadge ? this.lstTags?.filter(a => a.name == d?.bookingBadge)[0]?.id : '';
    this.ordrStatForm.patchValue({ bookingBadge: bkngBadg, estimatedDateOfArvl: d?.estimatedDateOfArrival ? this.glblSvc.
      setDtFrmt(d?.estimatedDateOfArrival, this.dtF.dmy2) : null, issuseRefNo: d?.reference ? d?.reference : null, requiredByDate:
      d?.requiredByDate ? this.glblSvc.setDtFrmt(d?.requiredByDate, this.dtF.dmy2) : null });
  }

  LoadProductsByOrderId(orderId: any) {
    this.GetMatrls().controls.length = 0;
    if (orderId) {
      this.lstMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings, environment.apiMthds.getProductsByBookingId.replace('ooo', orderId), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              this.result.data.forEach((e: any) => { Object.assign(e, { lstB: [], lstAB: [], lstAB1: [], lstFB: [], shwAlloTab: false }); }),
                this.lstMats = this.result.data != null ? this.result.data : [];
              if (this.GetMatrls().controls?.length != this.lstMats.length)
                this.lstMats?.filter(mat => { this.GetPrdctsToolTip(mat);
                  this.lstMats.forEach(e => e.file_export_path != null ? this.isFlePthShwHde = true : this.isFlePthShwHde = false)
                  this.lstMats.forEach(e => e.file_export_path == null ? this.isFleShwHde = true : this.isFleShwHde = false)
                /* let ttlTip: string = this.GetPrdctsToolTip(mat.bookingitemid) ? this.GetPrdctsToolTip(mat.bookingitemid) : '';
                this.GetMatrls().push(this.onAddFormCtrls(mat.product_id, mat.product_name, mat.yet_to_ship, mat.total_stock,
                  mat.ordered_stock, mat.allocated_stock, mat.shipped_stock, mat.available_stock, mat.fulfilled_stock,
                  mat.is_batch_enabled, mat.bookingitemid, 0, mat.isedited, mat.cargo_item_id, ttlTip )); */
                });
            }
          }
        }
      });
    }
  }

  GetPrdctsToolTip(matData: any) {
    let ttText = 'No Data found';
    this.FetchData(environment.apiCalls.get, environment.apiPaths.booking,
        environment.apiMthds.getToolTipbyBkngId.replace('bbb', matData.bookingitemid), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data != null)
            ttText = `Recommended: ${this.result.data[0]?.recommendedQuantity}\nOriginally Ordered: ${this.result.data[0]?.originallyOrdered}\nReason for ordering discrepancy: ${this.result.data[0]?.reasonForOrderingDiscrepancy}\n\nModified: ${this.result.data[0]?.modifiedQuantity}\nReason for modifying order quantity: ${this.result.data[0]?.reasonForModifyingOrderQuantity}`;
          this.GetMatrls().push(this.onAddFormCtrls(matData.product_id, matData.product_name, matData.yet_to_ship, matData.total_stock,
            matData.ordered_stock, matData.allocated_stock, matData.shipped_stock, matData.available_stock, matData.fulfilled_stock,
            matData.is_batch_enabled, matData.bookingitemid, 0, matData.isedited, matData.cargo_item_id, ttText, matData.in_transit_stock,
            matData.ic_in_transit_stock, matData.ic_total_stock, matData.rc_min, matData.rc_max, matData.ic_min, matData.ic_max, 1,
            matData.is_min_max, this.result.data[0]?.recommendedQuantity, matData.handling_unit, matData.file_export_path)),
            this.ApplyValdtns();
        }
      }
    });
  }

  ApplyValdtns() {
    //valTT = this.ordrCreateForm.get('transType')?.value;
   this.GetMatrls().controls.forEach((e: any) => {
      // if(e.name == name){
      let minVal = 1;
      if (e.get('handlngUnt')?.value != null)
        minVal = +e?.get('handlngUnt')?.value;

        e.get('quantity')?.setValidators([Validators.required, Validators.min(minVal), Validators.minLength(1), Validators.max(99999999), Validators.maxLength(8) ]); // Validators.pattern(environment.patterns.numericsby5)
        e.get('quantity')?.updateValueAndValidity();

      //   if( e?.get('minMax'))
      //   e?.get('modifiedReason')?.setValidators([Validators.required]);
      //   else
      //   e?.get('modifiedReason')?.setValidators(null);

      //  e?.get('reason')?.updateValueAndValidity();
      // }
   });
 }

 onSlctRR(evnt: any, tIndx: number, ifNotOld: Boolean) {
  let rcvdRsn = evnt?.target?.value;
  if(ifNotOld){
    if (rcvdRsn != ''){
       let isShowRsnOthrs =`${this.rsnsLst?.filter(f => f.reasonName === rcvdRsn)[0].reasonName}`?.toLowerCase().includes('others');
      if(isShowRsnOthrs)
      this.GetMatrls().controls[tIndx-1].get('others')?.setValidators([Validators.required]);
      else
      this.GetMatrls().controls[tIndx-1].get('others')?.setValidators(null);
    }
  }
  else{
    if (rcvdRsn != ''){
      let isShowRsnOthrs = `${this.oldrsnsLst?.filter(f => f.reasonName === rcvdRsn)[0].reasonName}`.toLowerCase().includes('others')
      if(isShowRsnOthrs)
      this.GetMatrls().controls[tIndx-1].get('others')?.setValidators([Validators.required]);
      else
      this.GetMatrls().controls[tIndx-1].get('others')?.setValidators(null);
    }
    this.GetMatrls().controls[tIndx-1].get('others')?.updateValueAndValidity();
  }
}

  LoadShipments(orderId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.cargo, environment.apiMthds.getCargobyBookingId.replace('ooo', orderId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.lstShipments = this.result.data != null ? this.result.data?.filter((f: any) => f.cargoId != null) : [];
      }
    });
  }

  LoadMatsbyStoreId() {
    this.lstAllMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.invMats.
        replace('sss', this.orderStatus[0].issuingStoreId).replace('rrr', this.orderStatus[0].receivingStoreId).
        replace(environment.noPgLen, ''), 0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : []
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  slctdMat(evnt: any) {
    this.isMinmax = false;
    let matName = evnt.value; this.isMinmax = this.lstAllMats?.filter(f => f.productName == matName)[0]?.isMinMax, this.isRsnAlw = this.isMinmax;
    if (matName != '') {
      if (this.lstAllMats?.filter(f => f.productName == matName).length > 0) {
        let selected = this.lstAllMats?.filter(f => f.productName == matName)[0], avlbStk = selected.availableStock,
          total_stock = selected.totalStock, is_batch_enabled = selected.batchManagement, rc_min = selected.rc_min,
          rc_max = selected.rc_max, ic_min = selected.ic_min, ic_max = selected.ic_max, in_transit_stock = selected.in_transit_stock,
          ic_total_stock = selected.ic_total_stock, ic_in_transit_stock = selected.ic_in_transit_stock, recommanded_stock = selected.rcmndQuty,
          handlngUnt = selected.handlingQuantity ?? null;
        this.GetMatRcmnd(this.orderStatus[0].receivingStoreId, this.lstAllMats?.filter(f => f.productName == matName)[0].productId, matName,
          avlbStk, 0, total_stock, 0, 0, 0, 0, is_batch_enabled, 0, null, 0, in_transit_stock, ic_in_transit_stock, ic_total_stock, rc_min,
          rc_max, ic_min, ic_max, recommanded_stock, handlngUnt, null);
          this.ApplyValdtns();
      }
    }
  }

  GetMatRcmnd(strId: any, matId: any, matName: any, avlStock: any, yet_to_ship: any, total_stock: any, ordered_stock: any,
      allocated_stock: any, shipped_stock: any, fulfilled_stock: any, is_batch_enabled: any, bookingitemid: any, isedited: any,
      cargo_item_id: any, in_transit_stock: any, ic_in_transit_stock: any, ic_total_stock: any, rc_min: any, rc_max: any, ic_min: any,
      ic_max: any, recommanded_stock: any, handlngUnt: any, file_export_path: any) {
    if (strId && matId && matName && this.isMinmax) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings, environment.apiMthds.getRecmndMats.
          replace('sss', strId).replace('mmm', matId), null).then(() => {
        if (this.result) {
          this.GetMatrls().push(this.onAddFormCtrls(matId, matName, yet_to_ship, total_stock, ordered_stock, allocated_stock, shipped_stock,
            avlStock, fulfilled_stock, is_batch_enabled, bookingitemid, this.result.data?.recmndQuty ?? 0, isedited, cargo_item_id, '', in_transit_stock,
            ic_in_transit_stock, ic_total_stock, rc_min, rc_max, ic_min, ic_max, 1, this.isMinmax, this.result.data?.recmndQuty ?? 0, handlngUnt ?? null, file_export_path)), this.ordrStatForm.get('srchMtrl')?.setValue(''),
            this.MatsLst = this.MatsLst?.filter(f => f.productName != matName);
        }
      });
    }
    else {
      this.GetMatrls().push(this.onAddFormCtrls(matId, matName, yet_to_ship, total_stock, ordered_stock, allocated_stock, shipped_stock,
        avlStock, fulfilled_stock, is_batch_enabled, bookingitemid, 0, isedited, cargo_item_id, '', in_transit_stock,
        ic_in_transit_stock, ic_total_stock, rc_min, rc_max, ic_min, ic_max, 1, false, null, handlngUnt, file_export_path)), this.ordrStatForm.get('srchMtrl')?.setValue(''),
        this.MatsLst = this.MatsLst?.filter(f => f.productName != matName);
    }
  }

  LoadBatches(type: any, matData: any, indx: any) {
    let prodId, orderid;
    if (type == 'shipB')
      this.GetMatrls().at(indx).get('statusShi')?.setValue(this.GetMatrls().at(indx).get('statusShi')?.value == false ? true : false),
        prodId = matData.get('productId').value, orderid = matData.get('bookingItemId').value;
    if (type == 'fulfillOrder')
      prodId = matData.product_id, orderid = matData.bookingitemid;
    this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings,
        environment.apiMthds.getBatMatByBookingId.replace('ooo', orderid).replace('mmm', prodId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.lstMats[indx]['lstB'] = this.result.data != null ? this.result.data : [];
            if (this.lstMats[indx]['lstB'].length != this.GetListAllocB(indx).controls?.length)
              this.lstMats[indx]['lstB']?.filter((e: any) => { this.GetListAllocB(indx).push(this.onCreateAllocBats(e)); });
          }
        }
      }
    });
  }

  LoadFulfilBats(item: any, indx: any) {
    this.GetMatrls().at(indx).get('statusFulfill')?.setValue(this.GetMatrls().at(indx).get('statusFulfill')?.value == false ? true : false),
      this.FetchData(environment.apiCalls.get, environment.apiPaths.booking, environment.apiMthds.getFulfillOrderBats.
        replace('ccc', item?.get('cargo_item_id')?.value), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.lstMats[indx]['lstFB'] = this.result.data != null ? this.result.data : [];
            if (this.lstMats[indx]['lstFB'].length != this.getFulfillBats(indx).controls?.length)
              this.lstMats[indx]['lstFB']?.filter((e: any) => { this.getFulfillBats(indx).push(this.onCreateFulfillBats(e)); });
          }
        }
      }
    });
  }

  LoadProgressBar(orderId: any) {
    this.progressdata = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.bookingtracking, environment.apiMthds.getbookingstatusbar.replace('ooo', orderId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.progressdata = this.result.data != null ? this.result.data : []
        if (this.progressdata.length != 0)
          setTimeout(() => { this.orderS(); }, 1000);
      }
    });
  }

  openModal() {
    const modalRef = this.ngbMdl.open(ShipCreateMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    modalRef.componentInstance.orderStatusData = this.orderStatus[0];
    modalRef.result.then((result: any) => {
      if (result == 'done')
      this.routingToLocation('list', this.paramTabName); // setTimeout(() => { window.location.reload(); }, 1000);
    }, (r: any) => { console.log(r) });
  }

  LoadHstry() {
    this.FetchData(environment.apiCalls.get, '', environment.apiMthds.getOrderHis.replace('ooo', this.orderId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.OrderHistory = this.result.data != null ? this.result.data : [];
      }
    });
  }

  allocatebyBatch(mat: any, index: any) {
    this.GetMatrls().at(index).get('shwAlloTab')?.setValue(true),
      this.loadAllocateBatches('albButton', mat.get('productId')?.value, mat.get('bookingItemId')?.value, index, true);
  }

  GetListAllAB(tabIndex: number): FormArray {
    return this.GetMatrls().at(tabIndex).get('lstAB') as FormArray;
  }

  onCreateAllAB(e: any, indx: number): FormGroup {
    return this.formBuilder.group({ batch_no: e.batchNo, manufactured_date: e.manufacturedDate, batch_producer_name: e.manufactureName,
      expiry_date: e.expiryDate, total_stock: e.totalStock, allocated_stock: e.allocatedStock, available_stock: e.availableStock,
      quantity: [this.GetListAllAB(indx).controls.length == 0 ? this.GetMatrls().at(indx).get('ordrdStock')?.value : null,
      [Validators.max(e.availableStock)]], manufactureId: e.manufactureId });
  }

  GetListAllAB1(tabIndex: number): FormArray {
    return this.GetMatrls().at(tabIndex).get('lstAB1') as FormArray;
  }

  onCreateAllAB1(batchNo: any, expiryDate: any, quantity: any, manufactured_date: any, manfId: any): FormGroup {
    return this.formBuilder.group({ batch_no: batchNo, expiry_date: expiryDate, quantity: quantity, manufactured_date: manufactured_date, producerId: manfId });
  }

  saveAllocBatches(index: any, ordVal: any) {
    this.checkFormArrayValidations(this.GetMatrls().at(index).get('lstAB'));
    if (this.GetMatrls().at(index).get('lstAB')?.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill all mandatory fields');
    else {
      let data = this.GetMatrls().at(index).get('lstAB')?.value, alctBtchTtlVal = 0, isFrstBtch = false;
      data.forEach((e: any, i: number) => { alctBtchTtlVal += e.quantity ? +e.quantity : 0; if (i == 0) isFrstBtch = alctBtchTtlVal == 0; });
      if (isFrstBtch) {
        if (confirm(environment.cnfmMsg1)) {
          if (alctBtchTtlVal > ordVal)
            this.glblSvc.onTstr('i', 'Allocated cannot exceed Ordered value.');
          else
            this.SetAllctBtchs(index);
        }
      }
      else if (alctBtchTtlVal > ordVal)
        this.glblSvc.onTstr('i', 'Allocated cannot exceed Ordered value.');
      else
        this.SetAllctBtchs(index);
    }
  }

  SetAllctBtchs(index: any) {
    let data = this.GetMatrls().at(index).get('lstAB')?.value;
    this.GetMatrls().at(index).get('statusAll')?.setValue(this.GetMatrls().at(index).get('statusAll')?.value == false ? true : false);
    this.GetListAllAB1(index).controls.length = 0, data.forEach((e: any) => {
      this.GetListAllAB1(index).push(this.onCreateAllAB1(e.batch_no, e.expiry_date, e.quantity, e.manufactured_date, e.manufactureId));
    }), this.GetMatrls().at(index).get('shwAlloTab')?.setValue(false);
  }

  loadAllocateBatches(type: any, matName: any, bkngItemId: any, index: any, isAlct: boolean = false) {
    let apiPath = isAlct ? environment.apiPaths.booking : environment.apiPaths.bookings,
      apiMthd = isAlct ? environment.apiMthds.getAllocatebyBatch.replace('ppp', matName).replace('sss', this.orderStatus[0].issuingStoreId):
        environment.apiMthds.getBatMatByBookingId.replace('ooo', bkngItemId);
    // this.GetMatrls().at(index).get('statusAll')?.setValue(this.GetMatrls().at(index).get('statusAll')?.value == false ? true : false);
    
      // this.FetchData(environment.apiCalls.get, environment.apiPaths.booking, environment.apiMthds.getAllocatebyBatch.replace('ppp', matName).replace('sss', this.orderStatus[0].issuingStoreId), null).then(() => {
      // this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings, environment.apiMthds.getBatMatByBookingId.replace('ooo', bkngItemId), null).then(() => {
    this.GetListAllAB(index).controls.length = 0, this.FetchData(environment.apiCalls.get, apiPath, apiMthd, null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.lstMats[index]['lstAB'] = this.result.data != null ? this.result.data : [], this.lstMats[index]['lstAB1'] = this.lstMats[index]['lstAB'];
          if (type == 'alloBatches') {
            if (this.lstMats[index]['lstAB1'].length != this.GetListAllAB1(index).controls?.length) {
              this.lstMats[index]['lstAB1']?.filter((e: any) => {
                this.GetListAllAB1(index).push(this.onCreateAllAB1(e.batch_no, e.batch_expiry_date, e.quantity, e.manufactured_date, e.manufactureId))
                // this.GetListAllAB1(index).push(this.onCreateAllAB1(e.batchNo, e.expiryDate, e.quantity, e.manufactured_date))
              });
            }
            this.GetMatrls().at(index).get('statusAll')?.setValue(this.GetMatrls().at(index).get('statusAll')?.value == false ? true : false);
          }
          else {
            this.GetListAllAB(index).controls.length = 0;
            if (this.lstMats[index]['lstAB'].length != this.GetListAllAB(index).controls?.length)
              this.lstMats[index]['lstAB']?.filter((e: any) => {
                this.GetListAllAB(index).push(this.onCreateAllAB(e, index));
              })
            /* if (this.lstMats[index]['lstAB1'].length != this.GetListAllAB1(index).controls?.length) {
              this.lstMats[index]['lstAB1']?.filter((e: any) => {
                this.GetListAllAB1(index).push(this.onCreateAllAB1(e.batchNo, e.expiryDate, e.quantity, e.manufactured_date))
              });
            } */
            // if (type == 'alloBatches')
            //   this.GetMatrls().at(index).get('statusAll')?.setValue(this.GetMatrls().at(index).get('statusAll')?.value == false ? true : false);
          }
        }
      }
    });
  }

  allocate() {
    this.allocateS = true
    // this.lstMats?.filter((a: any, index: any) => {
    //   if (a.is_batch_enabled)
    //     this.loadAllocateBatches('alloBatches', a.product_id, index);
    // });
    // if (this.lstMats[index]['lstAB1'].length != this.GetListAllAB1(index).controls?.length) {
    //   this.lstMats[index]['lstAB1']?.filter((e: any) => {
    //     this.GetListAllAB1(index).push(this.onCreateAllAB1(e.batchNo, e.expiryDate, e.quantity, e.manufactured_date))
    //   });
    //   this.GetMatrls().at(index).get('statusAll')?.setValue(this.GetMatrls().at(index).get('statusAll')?.value == false ? true : false);
    // }
  }

  allocateOrder() {
    let payLoad = this.ordrStatForm.getRawValue(), mats: any[] = [], isNBAQVal = 0, isNBAlctdCnt = 0, isNBAlctdXcdAvlbCnt = 0;
    payLoad.bookingItems?.filter((items: any) => { let bats: any[] = [];
      if (items.avlStock != 0 && items.ordrdStock != 0 && (items.allctd == 0 || items.allctd == null) && items.isBtch == false)
        isNBAlctdCnt++;
      if (items.allctd != '' || items.allctd != null) {
        if (items.avlStock != 0 && items.ordrdStock != 0 && (items.avlStock < +items.allctd) && items.isBtch == false)
          isNBAlctdXcdAvlbCnt++;
      }
      items.lstAB1?.filter((b: any) => {
        if (b.quantity != null && b.quantity != '' && b.quantity != 0 && b.quantity != undefined)
          bats.push({ batchId: b.batch_no, producerId: b.producerId, quantity: (b.quantity != null && b.quantity != '') ? +b.quantity : 0,
            expiryDate: (b.expiry_date != null && b.expiry_date != '') ? this.glblSvc.setDtFrmt(new Date(b.expiry_date), this.dtF.ymd) : '',
            manufacturedDate: (b.manufactured_date != null && b.manufactured_date != '') ? this.glblSvc.setDtFrmt(new Date(b.manufactured_date), this.dtF.ymd) : ''
          });
      }), mats.push({ allocatedQuantity: (items.allctd != null && items.allctd != '') ? items.allctd : null, bookingItemId: items.bookingItemId,
          orderedQuantity: items.ordrdStock, productBatches: bats, productId: items.productId });
    });
    mats.forEach(e => {
      if (e.allocatedQuantity != null && e.orderedQuantity != null) {
        if (+e.allocatedQuantity > +e.orderedQuantity)
          ++isNBAQVal;
      }
    });
    if (isNBAQVal > 0)
      this.glblSvc.onTstr('i', 'Allocated cannot exceed Ordered value.');
    else if (isNBAlctdXcdAvlbCnt > 0)
      this.glblSvc.onTstr('i', 'Allocated cannot exceed Available stock.');
    else if (isNBAlctdCnt > 0)
      this.glblSvc.onTstr('i', 'Allocated should be more than 1.');
    else {
      payLoad = { userId: this.usrLgnDtls.userId, products: mats, pranthId: this.chsnDmnId };
      this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.dispatch.replace('bbb', this.orderId), payLoad).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message)
            if (this.result.message == 'Order has been updated successfully..')
              this.LoadOrderStatus(this.orderId), this.loadComments(this.orderId),
                this.GetMatrls().controls.length = 0, this.LoadProductsByOrderId(this.orderId), this.LoadShipments(this.orderId),
                this.LoadProgressBar(this.orderId), setTimeout(() => { this.orderS(); }, 1000), this.editFlag = false, this.allocateS = false;
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  GetMatrls(): FormArray {
    return this.ordrStatForm.get('bookingItems') as FormArray;
  }

  onAddFormCtrls(product_id: any, product_name: any, yet_to_ship: any, total_stock: any, ordered_stock: any, allocated_stock: any,
    shipped_stock: any, available_stock: any, fulfilled_stock: any, is_batch_enabled: any, bookingitemid: any, recVal: any, isedited: any,
    cargo_item_id: any, ttlTip: any, in_transit_stock: any, ic_in_transit_stock: any, ic_total_stock: any, rc_min: any, rc_max: any, ic_min: any,
    ic_max: any, ordMinVal: number = 0,  is_minMax: any, recommanded_stock: any, handlngUnt: any, file_export_path: any, fle: any = null,) {
    return this.formBuilder.group({ productId: [product_id, [Validators.required]], name: [product_name, [Validators.required]],
      yetToShip: yet_to_ship, ts: total_stock, ordrdStock: ordered_stock, allctd: [allocated_stock != null ? allocated_stock : null],
       statusFulfill: false, shiped: shipped_stock, avlStock: available_stock, fulfld: fulfilled_stock,
      quantity: [recVal == 0 ? ordered_stock : recVal, [Validators.required, Validators.minLength(1), Validators.min(ordMinVal == 0 ?
        allocated_stock : 1), Validators.maxLength(10), Validators.pattern(environment.patterns.numericsby5)]], others: [''], minMax: is_minMax, rcmndedStk: recommanded_stock,
      shwAlloTab: false, isBtch: is_batch_enabled, bookingItemId: bookingitemid, statusAll: false, statusShi: false, reason: [''],
      lstB: this.formBuilder.array([]), lstAB: this.formBuilder.array([]), lstAB1: this.formBuilder.array([]), ic_total_stock: ic_total_stock,
      lstFB: this.formBuilder.array([]), recommendedQuantity: [recommanded_stock], modifiedReason: [''], isedited: isedited, cargo_item_id: cargo_item_id,
      ttlToolTip: ttlTip, rc_min: rc_min ? +rc_min : null, rc_max: rc_max ? +rc_max : null, ic_min: ic_min ? +ic_min : null,
      ic_max: ic_max ? +ic_max : null, isRCShow: (rc_min && rc_max) ? (+rc_min > ordered_stock || +rc_max < ordered_stock) : false,
      isICShow: (ic_min && ic_max) ? (+ic_min > ordered_stock || +ic_max < ordered_stock) : false, in_transit_stock: in_transit_stock,
      ic_in_transit_stock: ic_in_transit_stock, handlngUnt: handlngUnt, isInvalidPttrn: null, file_export_path: file_export_path != null ? 
        file_export_path : null, file: fle, });
  }

  onDelMtrls(tabIndex: any, matName: any) {
    this.GetMatrls().removeAt(tabIndex), this.MatsLst.push(this.lstAllMats?.filter(f => f.productName == matName)[0]), this.MatsLst.sort((a, b) => a.productId - b.productId);
  }

  GetListAllocB(tabIndex: number): FormArray {
    return this.GetMatrls().at(tabIndex).get('lstB') as FormArray;
  }

  getFulfillBats(tabIndex: number): FormArray {
    return this.GetMatrls().at(tabIndex).get('lstFB') as FormArray;
  }

  onCreateAllocBats(e: any): FormGroup {
    return this.formBuilder.group({ batch_no: e.batch_no, batch_expiry_date: e.batch_expiry_date, quantity: e.quantity });
  }

  onCreateFulfillBats(e: any): FormGroup {
    return this.formBuilder.group({ batch_no: e.batch_no, batch_expiry_date: e.batch_expiry_date, quantity: e.fulfilled_stock });
  }

  onEdit() {
    this.MatsLst = [], this.editFlag = true, this.lstMats?.filter(a => { this.lstAllMats = this.lstAllMats.
      filter(f => f.productName != a.product_name) }), this.MatsLst = this.lstAllMats;
  }

  editProds() {
    this.checkFormArrayValidations(this.ordrStatForm.controls['bookingItems']);
    if (this.ordrStatForm.controls['bookingItems'].invalid)
      this.glblSvc.onTstr('w', 'Kindly fill all mandatory fields');
    else {
      let payLoad = this.ordrStatForm.getRawValue();
      payLoad.bookingItems.forEach((e: any) => { delete e.name, delete e.yetToShip, delete e.ts, delete e.ordrdStock, delete e.allctd,
        delete e.shiped, delete e.avlStock, delete e.fulfld, delete e.others, delete e.shwAlloTab, delete e.isBtch, delete e.lstB,
        delete e.lstAB, delete e.lstAB1, delete e.lstFB, delete e.statusFulfill, delete e.ttlToolTip, delete e.cargo_item_id, delete e.rcmndedStk,
        delete e.statusShi, delete e.statusAll, delete e.isedited, e.quantity = (e.quantity != null && e.quantity != '') ? +e.quantity : 0,
        e.recommendedQuantity = (e.recommendedQuantity != null && e.recommendedQuantity.toString() != '') ? +e.recommendedQuantity : null,
        e.modifiedReason = (e.modifiedReason != null && e.modifiedReason != '') ? e.modifiedReason : null
      }), payLoad.updatedDate = this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM,
        payLoad.userId = this.usrLgnDtls.userId, payLoad.bookingId = this.orderId, 
        payLoad.issuingStoreId = this.orderStatus[0].issuingStoreId, delete payLoad.srchMtrl, delete payLoad.bookingBadge,
        delete payLoad.estimatedDateOfArvl, delete payLoad.issuseRefNo, delete payLoad.requiredByDate, delete payLoad.comment,
        payLoad.pranthId = this.chsnDmnId;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.booking, environment.apiMthds.editBooking, payLoad).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message)
            if (this.result.message == 'Ordered Quantity updated successfully')
              this.LoadOrderStatus(this.orderId), this.loadComments(this.orderId),
                this.GetMatrls().controls.length = 0, this.LoadProductsByOrderId(this.orderId), this.LoadShipments(this.orderId),
                this.LoadProgressBar(this.orderId), setTimeout(() => { this.orderS(); }, 1000), this.editFlag = false, this.allocateS = false;
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  cancelAllocate() {
    this.allocateS = false; this.lstMats?.filter(a => { a.shwAlloTab = false }),
      this.GetMatrls().controls.length = 0;
    if (this.GetMatrls().controls.length == 0)
      this.lstMats?.filter(mat => {
        this.GetMatrls().push(this.onAddFormCtrls(mat.product_id, mat.product_name, mat.yet_to_ship, mat.total_stock, mat.ordered_stock,
          mat.allocated_stock, mat.shipped_stock, mat.available_stock, mat.fulfilled_stock, mat.is_batch_enabled, mat.bookingitemid, 0,
          mat.isedited, mat.cargo_item_id, '', mat.in_transit_stock, mat.ic_in_transit_stock, mat.ic_total_stock, mat.rc_min, mat.rc_max,
          mat.ic_min, mat.ic_max, mat, mat.is_min_max, mat.recommanded_stock, mat.handling_unit, mat.file_export_path)); }), this.GetMatrls()?.controls?.filter((a: any, indx: any) => this.cancel(indx));
          this.ApplyValdtns();
  }

  cancel(index: any) {
    this.GetMatrls().at(index).get('shwAlloTab')?.setValue(false);
  }

  canelEdit() {
    this.editFlag = false, this.GetMatrls().controls.length = 0;
    if (this.GetMatrls().controls.length == 0)
      this.lstMats?.filter(mat => {
        this.GetMatrls().push(this.onAddFormCtrls(mat.product_id, mat.product_name, mat.yet_to_ship, mat.total_stock, mat.ordered_stock,
          mat.allocated_stock, mat.shipped_stock, mat.available_stock, mat.fulfilled_stock, mat.is_batch_enabled, mat.bookingitemid, 0,
          mat.isedited, mat.cargo_item_id, '', mat.in_transit_stock, mat.ic_in_transit_stock, mat.ic_total_stock, mat.rc_min, mat.rc_max,
          mat.ic_min, mat.ic_max, 1, mat.is_min_max, mat.recommanded_stock, mat.handling_unit, mat.file_export_path));
          this.ApplyValdtns();
      });
  }

  dispatchBooking() {
    let payLoad = { userId: this.usrLgnDtls.userId, comments: this.dispatchForm.get('DispatchComment')?.value,
      pranthId: this.chsnDmnId, orderUpdatedAt: this.dtPipe.transform((new Date()), 'yyyy-MM-dd HH:mm:ss'), status: 3 };
    this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.updateBooking.replace('bbb', this.orderId), payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.message != 'Please allocate Fully')
          this.glblSvc.onTstr('s', this.result.message), $('#clsMDL')?.click(), this.LoadOrderStatus(this.orderId), this.loadComments(this.orderId),
            this.GetMatrls().controls.length = 0, this.LoadProductsByOrderId(this.orderId), this.LoadShipments(this.orderId), this.LoadProgressBar(this.orderId),
            setTimeout(() => { this.orderS(); }, 1000);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  onClkFulfill() {
    this.lstMats?.filter((a, index) => this.LoadBatches('fulfillOrder', a, index));
    let array = [this.lstMats, this.orderStatus]
    this.glblSvc.sbjctodrStst.next(array),
    this.routingToLocation('fulfill', this.paramTabName );
    // this.router.navigate(['home/ord-ord'], { queryParams: { tab: 'fulfill', type: this.paramTabName } });
  }

  loadComments(orderId: any) {
    this.lstAllComments = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.booking, environment.apiMthds.getComments.replace('type', 'Booking').replace('typeId', orderId), 0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllComments = this.result.data != null ? this.result.data : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  onRemove(tabIndex: any, item: any) {
    if (this.GetMatrls().length > 1) {
      if (this.lstMats.filter(e => e.product_id == item?.value.productId).length != 0) {
        if (this.lstMats.length > 1) {
          if (confirm(`Are you sure you want to delete this ${item?.value.name} item ?`)) {
            let payLoad = {
              bookingId: this.orderId, bookingItemId: item?.value.bookingItemId, isBatchEnabled: item?.value.isBtch, reason: item?.value.reason, userId: this.usrLgnDtls.userId
            }
            this.FetchData(environment.apiCalls.post, environment.apiPaths.dscrpncs, environment.apiMthds.dltOrder.replace('iii', this.usrLgnDtls.userId).
              replace('ppp', this.usrLgnDtls.pranthId), payLoad).then(() => {
                if (this.result) {
                  if (this.result.status == 'OK') {
                    this.glblSvc.onTstr('s', this.result.message);
                    this.LoadProductsByOrderId(this.orderId);
                  }
                  else
                    this.glblSvc.onTstr('e', this.result.message);
                }
              });
          }
        }
        else
          alert("Since only 1 item is present in this indent. Please cancel indent");
      }
      else {
        this.GetMatrls().removeAt(tabIndex), this.MatsLst.push(this.lstAllMats?.filter(f => f.productName == item?.value.name)[0]), this.MatsLst.sort((a, b) => a.productId - b.productId);
      }
    }
    else {
      alert("Since only 1 item is present in this indent. Please cancel indent");
    }
  }

  onEditQty(matItem: any, ifNotOld: Boolean) {
    matItem.get('modifiedReason')?.setValidators(null), matItem.get('reason')?.setValidators(null);
    if (ifNotOld) {
      if ((matItem?.get('ordrdStock')?.value != matItem?.get('quantity')?.value) && matItem?.get('minMax')?.value==true) {
        matItem.get('reason')?.setValidators([Validators.required]);
      }
      else
        matItem.get('reason')?.setValidators(null);
    }
    else {
      if (matItem?.get('ordrdStock')?.value != matItem?.get('quantity')?.value && matItem?.get('minMax')?.value==true) {
        matItem.get('modifiedReason')?.setValidators([Validators.required])
      }
      else
        matItem.get('modifiedReason')?.setValidators(null)
    }
    matItem.get('modifiedReason')?.updateValueAndValidity(), matItem.get('reason')?.updateValueAndValidity();matItem.get('isInvalidPttrn')?.setValue(false);
    let defVal = matItem?.get('quantity')?.value;
    matItem?.get('quantity')?.setValue(+defVal);
    if(matItem.value.handlngUnt != null && matItem.value.quantity != null)
    matItem.get('isInvalidPttrn').setValidators([Validators.required ,Validators.pattern(`${matItem.value.quantity% matItem.value.handlngUnt !=0}`)]);
    else
    matItem.get('isInvalidPttrn').setValidators(null);
    matItem?.get('isInvalidPttrn')?.updateValueAndValidity();

  }

  saveComments() {
    let payLoad = { bookingId: this.orderId, cargoId: null, comments: this.ordrStatForm.get('comment')?.value,
      createdBy: this.usrLgnDtls.userId,
      createdOn: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM, entryFrom: "Booking" }
    this.FetchData(environment.apiCalls.post, environment.apiPaths.booking, environment.apiMthds.saveComments, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.message == 'message added successfully') {
          this.glblSvc.onTstr('s', this.result.message), this.LoadOrderStatus(this.orderId), this.loadComments(this.orderId),
            this.GetMatrls().controls.length = 0, this.LoadProductsByOrderId(this.orderId), this.LoadShipments(this.orderId),
            this.LoadProgressBar(this.orderId), setTimeout(() => { this.orderS(); }, 1000), this.editFlag = false, 
            this.allocateS = false, this.ordrStatForm.get('comment')?.setValue(''), this.cmntStat = false;
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  cancelComment() {
    this.cmntStat = false, this.ordrStatForm.get('comment')?.setValue('');
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onInvc() {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.getInvcOrdr + this.orderId, {}).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.OpenFileInNewTab(this.result?.data?.downloadurlpath);
      }
    });
  }

  onCncl() {
    if (this.orderId) {
      const modalRef = this.ngbMdl.open(CnclOrderComponent, { scrollable: true, size: 'md', backdrop: 'static', animation: true });
      modalRef.componentInstance.bkngId = this.orderId;
      modalRef.componentInstance.page = 'Booking';
      modalRef.result.then((result: any) => {
        if (result == 'done')
        this.routingToLocation('sts', this.paramTabName), this.ngOnInit(), this.glblSvc.sbjctTabsPage.next('indnt-list');
      }, (r: any) => { console.log(r) });
    }
  }

  onFileSelectVar(event: any, tI: number) {
    if (event.target.files.length > 0) {
      this.GetMatrls().at(tI).get('file')?.setValue(''), this.fileName = '', this.cargosId = '', this.fileFormat = '';
      this.GetMatrls().at(tI).get('file')?.setValue(event.target.files);
      this.fileName = this.GetMatrls().at(tI).get('file')?.value, this.cargosId = this.GetMatrls().at(tI).get('cargo_item_id')?.value;
      this.btchEnble = this.GetMatrls().at(tI).get('isBtch')?.value;
      const file = event.target.files[0];      
      if (file.size > 5242880) {
        alert("File size must be less than 5MB");
        this.fileName = '';
      }
      else {
        this.fileFormat = file;
      }
    }
  }

  hasExtensionVAR(exts: any) {
    let fileName: any = this.fileName[0].name
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  }

  uploadFile(i: any, tI: number) {
    if (this.fileName == '')
      this.glblSvc.onTstr('w', 'Kindly select a file to upload');
    else if(this.hasExtensionVAR(['.pdf'])) {
      // this.uploadStatusResult = true,  this.uploadStatus = 'In Progress';
      const formData = new FormData();
      formData.append('file', this.fileFormat);     
      this.onFormData(environment.apiPaths.exprt, environment.apiMthds.relsFulFillUpld.replace('iii', this.cargosId ?? null).
        replace('fff', this.btchEnble ? '64' : '80'), formData, 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.returnCode == 1) {
            this.glblSvc.onTstr('s', this.result.message),this.fileName = '', this.fileFormat = '', this.ngOnInit();
          }
          else
            this.glblSvc.onTstr('w', this.result.message);
        }
      });
    } else {
      this.glblSvc.onTstr('w', 'Upload only PDF files'), this.ordrStatForm.reset();
    }   
  }

  openModalVAR(c:string, i: any) {
    if (c == 'v') {
      const modalRef = this.ngbMdl.open(OrdrVarComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
        modalRef.componentInstance.cargoId = i;
    } else {
      const modalRef = this.ngbMdl.open(OrdrParComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
      modalRef.componentInstance.cargoId = i;
    }
  }

}