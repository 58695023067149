import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';

import { ToastrModule } from 'ngx-toastr';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxMaskModule } from 'ngx-mask'; //, IConfig
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPrintModule } from 'ngx-print';
import { NgxPaginationModule } from 'ngx-pagination';
import { BreadcrumbModule } from 'angular-crumbs';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PopoverModule } from "ngx-smart-popover";
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';

import { LeafletModule } from 'src/app/OSM_LeafLet/leaflet.module';

import { GlobalService } from 'src/app/Services/global.service';
import { SearchPipe } from 'src/app/Pipes/search.pipe';

// base
import { LoginComponent } from 'src/app/Components/Global/Base/login/login.component';
import { HomeComponent } from 'src/app/Components/Global/Base/home/home.component';
import { HeaderComponent } from 'src/app/Components/Global/Base/header/header.component';
import { FooterComponent } from 'src/app/Components/Global/Base/footer/footer.component';
import { MenuComponent } from 'src/app/Components/Global/Base/menu/menu.component';
// base

// evin
import { BaseComponent } from 'src/app/Components/Global/base.component';
// evin

// Samples

// Samples

// eVIN
import { DashboardComponent } from 'src/app/Components/evin/Dashboard/dashboard/dashboard.component';
import { OverviewComponent } from 'src/app/Components/evin/Dashboard/dashboard/overview/overview.component';
import { DashboardOverviewComponent } from 'src/app/Components/evin/Dashboard/dashboard/dashboard-overview/dashboard-overview.component';
import { StockReportComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report.component';
import { StockDeviantComponent } from 'src/app/Components/evin/Inventory/stock-deviant/stock-deviant.component';
import { TransactionsComponent } from 'src/app/Components/evin/Inventory/transactions/transactions.component';
import { StockAdjustmentsComponent } from 'src/app/Components/evin/Inventory/stock-adjustments/stock-adjustments.component';
import { OrdersComponent } from 'src/app/Components/evin/Orders/orders/orders.component';
import { ReleasesComponent } from 'src/app/Components/evin/Orders/releases/releases.component';
import { ShipmentsComponent } from 'src/app/Components/evin/Orders/shipments/shipments.component';
import { DiscrepanciesComponent } from 'src/app/Components/evin/Orders/discrepancies/discrepancies.component';
import { DemandComponent } from 'src/app/Components/evin/Orders/demand/demand.component';
import { ApprovalComponent } from 'src/app/Components/evin/Orders/approval/approval.component';
import { StockReportMaterialComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report-material/stock-report-material.component';
import { StockReportViewComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report-view/stock-report-view.component';
import { OrdersStatusComponent } from 'src/app/Components/evin/Orders/orders-status/orders-status.component';
import { TransactionsListComponent } from 'src/app/Components/evin/Inventory/transactions/transactions-list/transactions-list.component';
import { CreateTransactionsComponent } from 'src/app/Components/evin/Inventory/transactions/create-transactions/create-transactions.component';
import { BulkUploadComponent } from 'src/app/Components/Global/bulk-upload/bulk-upload.component';
import { OrdersListComponent } from 'src/app/Components/evin/Orders/orders/orders-list/orders-list.component';
import { OrdersCreateComponent } from 'src/app/Components/evin/Orders/orders/orders-create/orders-create.component';
import { ShipmentsListComponent } from 'src/app/Components/evin/Orders/shipments/shipments-list/shipments-list.component';
import { ShipmentsStatusComponent } from 'src/app/Components/evin/Orders/shipments/shipments-status/shipments-status.component';
import { ReleasesListComponent } from 'src/app/Components/evin/Orders/releases/releases-list/releases-list.component';
import { ReleasesCreateComponent } from 'src/app/Components/evin/Orders/releases/releases-create/releases-create.component';
import { ShipmentsFulfillComponent } from 'src/app/Components/evin/Orders/shipments/shipments-fulfill/shipments-fulfill.component';
import { StkRprtMdlComponent } from 'src/app/Components/evin/Pop-ups/stk-rprt-mdl/stk-rprt-mdl.component';
import { ShipCreateMdlComponent } from 'src/app/Components/evin/Pop-ups/ship-create-mdl/ship-create-mdl.component';
import { OrderFulfillComponent } from 'src/app/Components/evin/Orders/orders/order-fulfill/order-fulfill.component';
import { AssetsComponent } from 'src/app/Components/evin/Assets/assets/assets.component';
import { AssetsListComponent } from 'src/app/Components/evin/Assets/assets/assets-list/assets-list.component';
import { AssetsDetailsComponent } from 'src/app/Components/evin/Assets/assets/assets-details/assets-details.component';
import { UsersComponent } from 'src/app/Components/evin/Setup/users/users.component';
import { UsersEditComponent } from 'src/app/Components/evin/Setup/users/users-edit/users-edit.component';
import { UsersDetailsComponent } from 'src/app/Components/evin/Setup/users/users-details/users-details.component';
import { MessageStatusComponent } from 'src/app/Components/evin/Setup/users/message-status/message-status.component';
import { CreateUserComponent } from 'src/app/Components/evin/Setup/users/create-user/create-user.component';
import { UsersViewComponent } from 'src/app/Components/evin/Setup/users/users-view/users-view.component';
import { SuBulkUploadComponent } from 'src/app/Components/evin/Setup/users/su-bulk-upload/su-bulk-upload.component';
import { MaterialsComponent } from 'src/app/Components/evin/Setup/materials/materials.component';
import { MaterialsEditComponent } from 'src/app/Components/evin/Setup/materials/materials-edit/materials-edit.component';
import { MaterialsDetailsComponent } from 'src/app/Components/evin/Setup/materials/materials-details/materials-details.component';
import { CreateMaterialComponent } from 'src/app/Components/evin/Setup/materials/create-material/create-material.component';
import { MaterialsViewComponent } from 'src/app/Components/evin/Setup/materials/materials-view/materials-view.component';
import { HandlingUnitsComponent } from 'src/app/Components/evin/Setup/handling-units/handling-units.component';
import { HandlingUnitsEditComponent } from 'src/app/Components/evin/Setup/handling-units/handling-units-edit/handling-units-edit.component';
import { HandlingUnitsDetailsComponent } from 'src/app/Components/evin/Setup/handling-units/handling-units-details/handling-units-details.component';
import { HandlingUnitsViewComponent } from 'src/app/Components/evin/Setup/handling-units/handling-units-view/handling-units-view.component';
import { CreateHandlingUnitsComponent } from 'src/app/Components/evin/Setup/handling-units/create-handling-units/create-handling-units.component';
import { SetupInventoryComponent } from 'src/app/Components/evin/Setup/setup-inventory/setup-inventory.component';
import { SetupDomainsComponent } from 'src/app/Components/evin/Setup/setup-domains/setup-domains.component';
import { SetupAssetsComponent } from 'src/app/Components/evin/Setup/setup-assets/setup-assets.component';
import { AssetsCreateComponent } from 'src/app/Components/evin/Setup/setup-assets/assets-create/assets-create.component';
import { AssetsViewComponent } from 'src/app/Components/evin/Setup/setup-assets/assets-view/assets-view.component';
import { BulletinBoardComponent } from 'src/app/Components/evin/Setup/bulletin-board/bulletin-board.component';
import { TransportersComponent } from 'src/app/Components/evin/Setup/transporters/transporters.component';
import { TransportersEditComponent } from 'src/app/Components/evin/Setup/transporters/transporters-edit/transporters-edit.component';
import { TransportersDetailsComponent } from 'src/app/Components/evin/Setup/transporters/transporters-details/transporters-details.component';
import { TransportersViewComponent } from 'src/app/Components/evin/Setup/transporters/transporters-view/transporters-view.component';
import { CreateTransportersComponent } from 'src/app/Components/evin/Setup/transporters/create-transporters/create-transporters.component';
import { ManufacturersComponent } from 'src/app/Components/evin/Setup/manufacturers/manufacturers.component';
// import { ManufacturersEditComponent } from 'src/app/Components/evin/Setup/manufacturers/manufacturers-edit/manufacturers-edit.component';
// import { ManufacturersDetailsComponent } from 'src/app/Components/evin/Setup/manufacturers/manufacturers-details/manufacturers-details.component';
import { ManufacturersViewComponent } from 'src/app/Components/evin/Setup/manufacturers/manufacturers-view/manufacturers-view.component';
import { CreateManufacturersComponent } from 'src/app/Components/evin/Setup/manufacturers/create-manufacturers/create-manufacturers.component';
import { FacilityGroupComponent } from 'src/app/Components/evin/Setup/facility-group/facility-group.component';
import { FacilityGroupEditComponent } from 'src/app/Components/evin/Setup/facility-group/facility-group-edit/facility-group-edit.component';
import { FacilityGroupCreateComponent } from 'src/app/Components/evin/Setup/facility-group/facility-group-create/facility-group-create.component';
import { FacilityGroupViewComponent } from 'src/app/Components/evin/Setup/facility-group/facility-group-view/facility-group-view.component';
import { FacilityComponent } from 'src/app/Components/evin/Setup/facility/facility.component';
import { FacilityCreateComponent } from 'src/app/Components/evin/Setup/facility/facility-create/facility-create.component';
import { FacilityDetailsComponent } from 'src/app/Components/evin/Setup/facility/facility-details/facility-details.component';
import { FacilityViewComponent } from 'src/app/Components/evin/Setup/facility/facility-view/facility-view.component';

import { DomainActivityComponent } from 'src/app/Components/evin/Reports/Reports-activity/domain-activity/domain-activity.component';
import { TransactionCountsComponent } from 'src/app/Components/evin/Reports/Reports-activity/transaction-counts/transaction-counts.component';
import { UserActivityComponent } from 'src/app/Components/evin/Reports/Reports-activity/user-activity/user-activity.component';
import { AssetCapacityComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-capacity/asset-capacity.component';
import { AssetStatusComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-status/asset-status.component';
import { PowerAvailabilityComponent } from 'src/app/Components/evin/Reports/Reports-assets/power-availability/power-availability.component';
import { SicknessRateComponent } from 'src/app/Components/evin/Reports/Reports-assets/sickness-rate/sickness-rate.component';
import { TempExcursionsComponent } from 'src/app/Components/evin/Reports/Reports-assets/temp-excursions/temp-excursions.component';
import { UpTimeComponent } from 'src/app/Components/evin/Reports/Reports-assets/up-time/up-time.component';
import { CstmRprtsComponent } from 'src/app/Components/evin/Reports/Custom-reports/cstm-rprts/cstm-rprts.component';
import { AbnormalStockComponent } from 'src/app/Components/evin/Reports/Reports-inventory/abnormal-stock/abnormal-stock.component';
import { ConsumptionComponent } from 'src/app/Components/evin/Reports/Reports-inventory/consumption/consumption.component';
import { DiscardsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/discards/discards.component';
import { StockAvailabilityComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-availability/stock-availability.component';
import { StockTrendsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-trends/stock-trends.component';
import { SupplyComponent } from 'src/app/Components/evin/Reports/Reports-inventory/supply/supply.component';
import { UtilizationComponent } from 'src/app/Components/evin/Reports/Reports-inventory/utilization/utilization.component';
import { OrderStatusComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-status/order-status.component';
import { OrderDiscrepanciesComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-discrepancies/order-discrepancies.component';
import { OrderDemandComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-demand/order-demand.component';
import { OrderResponseTimeComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-response-time/order-response-time.component';
import { RplnshRspnTimeComponent } from 'src/app/Components/evin/Reports/Reports-inventory/rplnsh-rspn-time/rplnsh-rspn-time.component';
import { RspnsTmRparComponent } from 'src/app/Components/evin/Reports/Reports-assets/rspns-tm-rpar/rspns-tm-rpar.component';
import { UaByUserComponent } from 'src/app/Components/evin/Reports/Reports-activity/user-activity/ua-by-user/ua-by-user.component';
import { UaByUsersComponent } from 'src/app/Components/evin/Reports/Reports-activity/user-activity/ua-by-users/ua-by-users.component';
import { TsOverviewComponent } from 'src/app/Components/evin/Reports/Reports-activity/transaction-counts/ts-overview/ts-overview.component';
import { TsMaterialComponent } from 'src/app/Components/evin/Reports/Reports-activity/transaction-counts/ts-material/ts-material.component';
import { TsFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-activity/transaction-counts/ts-facilitys/ts-facilitys.component';
import { TsLocationsComponent } from 'src/app/Components/evin/Reports/Reports-activity/transaction-counts/ts-locations/ts-locations.component';
import { AcOverviewComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-capacity/ac-overview/ac-overview.component';
import { AcLocationsComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-capacity/ac-locations/ac-locations.component';
import { AcFacilityCategoryComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-capacity/ac-facility-category/ac-facility-category.component';
import { AcAssetTypeComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-capacity/ac-asset-type/ac-asset-type.component';
import { AsOverviewComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-status/as-overview/as-overview.component';
import { AsLocationsComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-status/as-locations/as-locations.component';
import { AsManufactureComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-status/as-manufacture/as-manufacture.component';
import { AsModelComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-status/as-model/as-model.component';
import { PaOverviewComponent } from 'src/app/Components/evin/Reports/Reports-assets/power-availability/pa-overview/pa-overview.component';
import { PaFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-assets/power-availability/pa-facilitys/pa-facilitys.component';
import { PaLocationsComponent } from 'src/app/Components/evin/Reports/Reports-assets/power-availability/pa-locations/pa-locations.component';
import { RspnsTmRparOverviewComponent } from 'src/app/Components/evin/Reports/Reports-assets/rspns-tm-rpar/rspns-tm-rpar-overview/rspns-tm-rpar-overview.component';
import { RspnsTmRparFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-assets/rspns-tm-rpar/rspns-tm-rpar-facilitys/rspns-tm-rpar-facilitys.component';
import { RspnsTmRparLocationsComponent } from 'src/app/Components/evin/Reports/Reports-assets/rspns-tm-rpar/rspns-tm-rpar-locations/rspns-tm-rpar-locations.component';
import { SrOverviewComponent } from 'src/app/Components/evin/Reports/Reports-assets/sickness-rate/sr-overview/sr-overview.component';
import { SrLocationsComponent } from 'src/app/Components/evin/Reports/Reports-assets/sickness-rate/sr-locations/sr-locations.component';
import { TempExcursionOverviewComponent } from 'src/app/Components/evin/Reports/Reports-assets/temp-excursions/temp-excursion-overview/temp-excursion-overview.component';
import { TempExcursionAssetsComponent } from 'src/app/Components/evin/Reports/Reports-assets/temp-excursions/temp-excursion-assets/temp-excursion-assets.component';
import { TempExcursionLocationsComponent } from 'src/app/Components/evin/Reports/Reports-assets/temp-excursions/temp-excursion-locations/temp-excursion-locations.component';
import { AbnormalStockOverviewComponent } from 'src/app/Components/evin/Reports/Reports-inventory/abnormal-stock/abnormal-stock-overview/abnormal-stock-overview.component';
import { AbnormalStockMaterialsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/abnormal-stock/abnormal-stock-materials/abnormal-stock-materials.component';
import { AbnormalStockFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-inventory/abnormal-stock/abnormal-stock-facilitys/abnormal-stock-facilitys.component';
import { AbnormalStockLocationsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/abnormal-stock/abnormal-stock-locations/abnormal-stock-locations.component';
import { ConsumptionOverviewComponent } from 'src/app/Components/evin/Reports/Reports-inventory/consumption/consumption-overview/consumption-overview.component';
import { ConsumptionMaterialsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/consumption/consumption-materials/consumption-materials.component';
import { DiscardsOverviewComponent } from 'src/app/Components/evin/Reports/Reports-inventory/discards/discards-overview/discards-overview.component';
import { DiscardsMaterialsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/discards/discards-materials/discards-materials.component';
import { DiscardsFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-inventory/discards/discards-facilitys/discards-facilitys.component';
import { DiscardsLocationsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/discards/discards-locations/discards-locations.component';
import { RplnshRspnTimeOverviewComponent } from 'src/app/Components/evin/Reports/Reports-inventory/rplnsh-rspn-time/rplnsh-rspn-time-overview/rplnsh-rspn-time-overview.component';
import { RplnshRspnTimeMaterialsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/rplnsh-rspn-time/rplnsh-rspn-time-materials/rplnsh-rspn-time-materials.component';
import { RplnshRspnTimeFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-inventory/rplnsh-rspn-time/rplnsh-rspn-time-facilitys/rplnsh-rspn-time-facilitys.component';
import { RplnshRspnTimeLocationsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/rplnsh-rspn-time/rplnsh-rspn-time-locations/rplnsh-rspn-time-locations.component';
import { StockAvailabilityOverviewComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-availability/stock-availability-overview/stock-availability-overview.component';
import { StockAvailabilityMaterialsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-availability/stock-availability-materials/stock-availability-materials.component';
import { StockAvailabilityFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-availability/stock-availability-facilitys/stock-availability-facilitys.component';
import { StockAvailabilityLocationsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-availability/stock-availability-locations/stock-availability-locations.component';
import { StockTrendsOverviewComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-trends/stock-trends-overview/stock-trends-overview.component';
import { StockTrendsFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-trends/stock-trends-facilitys/stock-trends-facilitys.component';
import { StockTrendsLocationsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-trends/stock-trends-locations/stock-trends-locations.component';
import { SupplyOverviewComponent } from 'src/app/Components/evin/Reports/Reports-inventory/supply/supply-overview/supply-overview.component';
import { SupplyMaterialsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/supply/supply-materials/supply-materials.component';
import { SupplyFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-inventory/supply/supply-facilitys/supply-facilitys.component';
import { SupplyLocationsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/supply/supply-locations/supply-locations.component';
import { UtilizationOverviewComponent } from 'src/app/Components/evin/Reports/Reports-inventory/utilization/utilization-overview/utilization-overview.component';
import { UtilizationFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-inventory/utilization/utilization-facilitys/utilization-facilitys.component';
import { DemandOverviewComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-demand/demand-overview/demand-overview.component';
import { DemandMaterialsComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-demand/demand-materials/demand-materials.component';
import { DemandFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-demand/demand-facilitys/demand-facilitys.component';
import { DemandLocationsComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-demand/demand-locations/demand-locations.component';
import { DemandReceivingFacilityComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-demand/demand-receiving-facility/demand-receiving-facility.component';
import { OrderDiscrepanciesOverviewComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-discrepancies/order-discrepancies-overview/order-discrepancies-overview.component';
import { OrderDiscrepanciesMaterialsComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-discrepancies/order-discrepancies-materials/order-discrepancies-materials.component';
import { OrderDiscrepanciesFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-discrepancies/order-discrepancies-facilitys/order-discrepancies-facilitys.component';
import { OrderDiscrepanciesLocationsComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-discrepancies/order-discrepancies-locations/order-discrepancies-locations.component';
import { OrderRespTmOverviewComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-response-time/order-resp-tm-overview/order-resp-tm-overview.component';
import { OrderRespTmFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-response-time/order-resp-tm-facilitys/order-resp-tm-facilitys.component';
import { OrderRespTmLocationsComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-response-time/order-resp-tm-locations/order-resp-tm-locations.component';
import { ReportFilterComponent } from 'src/app/Components/evin/Reports/report-filter/report-filter.component';
import { UpTimeOverviewComponent } from 'src/app/Components/evin/Reports/Reports-assets/up-time/up-time-overview/up-time-overview.component';
import { UpTimeFacilitysComponent } from 'src/app/Components/evin/Reports/Reports-assets/up-time/up-time-facilitys/up-time-facilitys.component';
import { UpTimeLocationsComponent } from 'src/app/Components/evin/Reports/Reports-assets/up-time/up-time-locations/up-time-locations.component';

import { ConfgFormComponent } from 'src/app/Components/evin/Configuration/confg-form/confg-form.component';
import { DbActivityComponent } from 'src/app/Components/evin/Dashboard/db-activity/db-activity.component';
import { DbAssetsComponent } from 'src/app/Components/evin/Dashboard/db-assets/db-assets.component';
import { DbCustomDashboardComponent } from 'src/app/Components/evin/Dashboard/db-custom-dashboard/db-custom-dashboard.component';
import { DbInventoryComponent } from 'src/app/Components/evin/Dashboard/db-inventory/db-inventory.component';
import { DbOrdersComponent } from 'src/app/Components/evin/Dashboard/db-orders/db-orders.component';
import { DbActivityOverviewComponent } from 'src/app/Components/evin/Dashboard/db-activity/db-activity-overview/db-activity-overview.component';
import { ActiualDataOfTransactionComponent } from 'src/app/Components/evin/Dashboard/db-activity/actiual-data-of-transaction/actiual-data-of-transaction.component';
import { DbInvOverviewComponent } from 'src/app/Components/evin/Dashboard/db-inventory/db-inv-overview/db-inv-overview.component';
import { DbInvStockOutPredictionComponent } from 'src/app/Components/evin/Dashboard/db-inventory/db-inv-stock-out-prediction/db-inv-stock-out-prediction.component';
import { CnfgGeneralComponent } from 'src/app/Components/evin/Configuration/cnfg-general/cnfg-general.component';
import { CnfgAccountingComponent } from 'src/app/Components/evin/Configuration/cnfg-accounting/cnfg-accounting.component';
import { CnfgCapabilitiesComponent } from 'src/app/Components/evin/Configuration/cnfg-capabilities/cnfg-capabilities.component';
import { CnfgApprovalsComponent } from 'src/app/Components/evin/Configuration/cnfg-approvals/cnfg-approvals.component';
import { CnfgAssetsComponent } from 'src/app/Components/evin/Configuration/cnfg-assets/cnfg-assets.component';
import { CnfgBulletinBoardComponent } from 'src/app/Components/evin/Configuration/cnfg-bulletin-board/cnfg-bulletin-board.component';
import { CnfgCustomDashboardComponent } from 'src/app/Components/evin/Configuration/cnfg-custom-dashboard/cnfg-custom-dashboard.component';
import { CnfgDashboardComponent } from 'src/app/Components/evin/Configuration/cnfg-dashboard/cnfg-dashboard.component';
import { CnfgInventoryComponent } from 'src/app/Components/evin/Configuration/cnfg-inventory/cnfg-inventory.component';
import { CnfgOrdersComponent } from 'src/app/Components/evin/Configuration/cnfg-orders/cnfg-orders.component';
import { CnfgCustomReportsComponent } from 'src/app/Components/evin/Configuration/cnfg-custom-reports/cnfg-custom-reports.component';
import { CnfgEventSummaryComponent } from 'src/app/Components/evin/Configuration/cnfg-event-summary/cnfg-event-summary.component';
import { CnfgNotificationsComponent } from 'src/app/Components/evin/Configuration/cnfg-notifications/cnfg-notifications.component';
import { CnfgOptionsComponent } from 'src/app/Components/evin/Configuration/cnfg-options/cnfg-options.component';
import { CnfgStockRebalancingComponent } from 'src/app/Components/evin/Configuration/cnfg-stock-rebalancing/cnfg-stock-rebalancing.component';
import { CnfgTagsComponent } from 'src/app/Components/evin/Configuration/cnfg-tags/cnfg-tags.component';
import { CnfgBulletinBoardConfigureComponent } from 'src/app/Components/evin/Configuration/cnfg-bulletin-board/cnfg-bulletin-board-configure/cnfg-bulletin-board-configure.component';
import { CnfgBulletinBoardViewComponent } from 'src/app/Components/evin/Configuration/cnfg-bulletin-board/cnfg-bulletin-board-view/cnfg-bulletin-board-view.component';
import { CnfgCreateBulletinBoardComponent } from 'src/app/Components/evin/Configuration/cnfg-bulletin-board/cnfg-create-bulletin-board/cnfg-create-bulletin-board.component';
import { CnfgCreateCustomDashboardComponent } from 'src/app/Components/evin/Configuration/cnfg-custom-dashboard/cnfg-create-custom-dashboard/cnfg-create-custom-dashboard.component';
import { CnfgCustomDashboardViewComponent } from 'src/app/Components/evin/Configuration/cnfg-custom-dashboard/cnfg-custom-dashboard-view/cnfg-custom-dashboard-view.component';
import { CnfgEventSummaryActivityComponent } from 'src/app/Components/evin/Configuration/cnfg-event-summary/cnfg-event-summary-activity/cnfg-event-summary-activity.component';
import { CnfgEventSummaryAssetsComponent } from 'src/app/Components/evin/Configuration/cnfg-event-summary/cnfg-event-summary-assets/cnfg-event-summary-assets.component';
import { CnfgEventSummaryInventoryComponent } from 'src/app/Components/evin/Configuration/cnfg-event-summary/cnfg-event-summary-inventory/cnfg-event-summary-inventory.component';
import { CnfgEventSummarySupplyComponent } from 'src/app/Components/evin/Configuration/cnfg-event-summary/cnfg-event-summary-supply/cnfg-event-summary-supply.component';
import { CnfgNavigationComponent } from 'src/app/Components/evin/Configuration/cnfg-event-summary/cnfg-navigation/cnfg-navigation.component';
import { NotificationsAccessLogComponent } from 'src/app/Components/evin/Configuration/cnfg-notifications/notifications-access-log/notifications-access-log.component';
import { CnfgAddNotificationsComponent } from 'src/app/Components/evin/Configuration/cnfg-notifications/cnfg-add-notifications/cnfg-add-notifications.component';
import { CnfgEventNotificationsComponent } from 'src/app/Components/evin/Configuration/cnfg-notifications/cnfg-event-notifications/cnfg-event-notifications.component';
import { CnfgNotificationsBulletinBoardComponent } from 'src/app/Components/evin/Configuration/cnfg-notifications/cnfg-notifications-bulletin-board/cnfg-notifications-bulletin-board.component';
import { CnfgNotificationsGeneralComponent } from 'src/app/Components/evin/Configuration/cnfg-notifications/cnfg-notifications-general/cnfg-notifications-general.component';
import { CnfgSentNotificationsComponent } from 'src/app/Components/evin/Configuration/cnfg-notifications/cnfg-sent-notifications/cnfg-sent-notifications.component';
import { DmnActvtyNewComponent } from 'src/app/Components/evin/Reports/Reports-activity/dmn-actvty-new/dmn-actvty-new.component';
import { ManageComponent } from 'src/app/Components/evin/Manage/manage.component';
import { DomainComponent } from 'src/app/Components/evin/Manage/domain/domain.component';
import { DomainListComponent } from 'src/app/Components/evin/Manage/domain/domain-list/domain-list.component';
import { DomainCreateComponent } from 'src/app/Components/evin/Manage/domain/domain-create/domain-create.component';
import { DomainDetailsComponent } from 'src/app/Components/evin/Manage/domain/domain-details/domain-details.component';
import { ManageDataComponent } from 'src/app/Components/evin/Manage/manage-data/manage-data.component';
import { SystemConfigComponent } from 'src/app/Components/evin/Manage/system-config/system-config.component';
import { ForgotPasswordComponent } from 'src/app/Components/Global/Authentication/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from 'src/app/Components/Global/Authentication/change-password/change-password.component';
import { ResetPasswordComponent } from 'src/app/Components/Global/Authentication/reset-password/reset-password.component';
import { ConsumptionFacilitiesComponent } from 'src/app/Components/evin/Reports/Reports-inventory/consumption/consumption-facilities/consumption-facilities.component';
import { ConsumptionLocationsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/consumption/consumption-locations/consumption-locations.component';
import { StockReportMaterialStoreComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report-material-store/stock-report-material-store.component';
import { StockReportExpiresBeforeComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report-expires-before/stock-report-expires-before.component';
import { StockReportAllComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report-all/stock-report-all.component';
import { CnclOrderComponent } from 'src/app/Components/evin/Pop-ups/cncl-order/cncl-order.component';
import { PucComponent } from 'src/app/Components/Global/Base/puc/puc.component';
import { ActivityRateComponent } from 'src/app/Components/evin/Reports/Reports-activity/activity-rate/activity-rate.component';
import { LoginOldComponent } from 'src/app/Components/Global/Base/login-old/login-old.component';
import { StockViewsComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-views/stock-views.component';
import { StocksReportsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stocks-reports/stocks-reports.component';
import { ConsumptionNewComponent } from 'src/app/Components/evin/Reports/Reports-inventory/consumption/consumption-new/consumption-new.component';
import { TransfersComponent } from 'src/app/Components/evin/Orders/transfers/transfers.component';
import { TransfersCreateComponent } from 'src/app/Components/evin/Orders/transfers/transfers-create/transfers-create.component';
import { TransfersListComponent } from 'src/app/Components/evin/Orders/transfers/transfers-list/transfers-list.component';
import { UserExportsComponent } from 'src/app/Components/Global/User-account/user-exports/user-exports.component';
import { UserProfileComponent } from 'src/app/Components/Global/User-account/user-profile/user-profile.component';
import { OverviewDashboardComponent } from 'src/app/Components/evin/Dashboard/dashboard/overview-dashboard/overview-dashboard.component';
import { EStockReportComponent } from 'src/app/Components/evin/Inventory/stock-report/e-stock-report/e-stock-report.component';
import { FacilitySummaryComponent } from 'src/app/Components/evin/Setup/facility/facility-summary/facility-summary.component';
import { ReportsHelpComponent } from 'src/app/Components/Global/Base/reports-help/reports-help.component';
import { OvrwDashboardNewComponent } from 'src/app/Components/evin/Dashboard/dashboard/ovrw-dashboard-new/ovrw-dashboard-new.component';
import { SetupUpdateBatchComponent } from 'src/app/Components/evin/Setup/setup-inventory/setup-update-batch/setup-update-batch.component';
import { TxnExportsComponent } from 'src/app/Components/Global/User-account/txn-exports/txn-exports.component';
import { CnfgGnrlComponent } from 'src/app/Components/evin/Configuration/cnfg-gnrl/cnfg-gnrl.component';
import { ManufacturesEditComponent } from 'src/app/Components/evin/Setup/manufacturers/manufactures-edit/manufactures-edit.component';
import { ManufacturesDetailsComponent } from 'src/app/Components/evin/Setup/manufacturers/manufactures-details/manufactures-details.component';
import { OsmLeafletComponent } from 'src/app/Components/Global/osm-leaflet/osm-leaflet.component';
import { CcemanufacturersComponent } from './Components/evin/Setup/ccemanufacturers/ccemanufacturers.component';
import { CceManfcturersComponent } from './Components/evin/Setup/ccemanufacturers/cce-manfcturers/cce-manfcturers.component';
import { CceModelComponent } from './Components/evin/Setup/ccemanufacturers/cce-model/cce-model.component';
import { PrchsIndentComponent } from './Components/evin/Orders/prchs-indent/prchs-indent.component';
import { CreatePrchsIndentComponent } from './Components/evin/Orders/prchs-indent/create-prchs-indent/create-prchs-indent.component';
import { PrchsIndentSummaryComponent } from './Components/evin/Orders/prchs-indent/prchs-indent-summary/prchs-indent-summary.component';
import { PrchsIndentListComponent } from './Components/evin/Orders/prchs-indent/prchs-indent-list/prchs-indent-list.component';
import { CnsinWiseCnsumtonComponent } from './Components/evin/Reports/Reports-inventory/cnsin-wise-cnsumton/cnsin-wise-cnsumton.component';
import { PrchsIndentStatusComponent } from './Components/evin/Orders/prchs-indent/prchs-indent-status/prchs-indent-status.component';
import { PrchsIndntDtlsComponent } from './Components/evin/Orders/prchs-indent/prchs-indnt-dtls/prchs-indnt-dtls.component';
import { PrchsIndntFulfullComponent } from './Components/evin/Orders/prchs-indent/prchs-indnt-fulfull/prchs-indnt-fulfull.component';
import { PrchsIndntCnsgneeComponent } from './Components/evin/Orders/prchs-indent/prchs-indnt-cnsgnee/prchs-indnt-cnsgnee.component';
import { CoverageReportComponent } from './Components/evin/Reports/Reports-inventory/coverage-report/coverage-report.component';
import { WastageReportComponent } from './Components/evin/Reports/Reports-inventory/wastage-report/wastage-report.component';
import { MnftrTrnsctnsComponent } from './Components/evin/Orders/orders/mnftr-trnsctns/mnftr-trnsctns.component';
import { PrchsIndntFulfillMdlComponent } from './Components/evin/Orders/prchs-indent/prchs-indnt-fulfill-mdl/prchs-indnt-fulfill-mdl.component';
import { ParMdlComponent } from './Components/evin/Orders/prchs-indent/par-mdl/par-mdl.component';
import { OrdrVarComponent } from './Components/evin/Pop-ups/ordr-var/ordr-var.component';
import { OrdrParComponent } from './Components/evin/Pop-ups/ordr-par/ordr-par.component';
// eVIN

@NgModule({
  declarations: [
    BaseComponent,
    AppComponent,

    OsmLeafletComponent,

    LoginComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    DashboardComponent,
    OverviewComponent,
    StockReportComponent,
    StockDeviantComponent,
    TransactionsComponent,
    StockAdjustmentsComponent,
    OrdersComponent,
    ReleasesComponent,
    ShipmentsComponent,
    DiscrepanciesComponent,
    DemandComponent,
    ApprovalComponent,
    StockReportMaterialComponent,
    StockReportViewComponent,
    OrdersStatusComponent,
    TransactionsListComponent,
    CreateTransactionsComponent,
    BulkUploadComponent,
    OrdersListComponent,
    OrdersCreateComponent,
    ShipmentsListComponent,
    ShipmentsStatusComponent,
    ReleasesListComponent,
    ReleasesCreateComponent,
    ShipmentsFulfillComponent,
    StkRprtMdlComponent,
    ShipCreateMdlComponent,
    OrderFulfillComponent,
    AssetsComponent,
    AssetsListComponent,
    AssetsDetailsComponent,
    UsersComponent,
    UsersEditComponent,
    UsersDetailsComponent,
    MessageStatusComponent,
    CreateUserComponent,
    UsersViewComponent,
    SuBulkUploadComponent,
    MaterialsComponent,
    MaterialsEditComponent,
    MaterialsDetailsComponent,
    CreateMaterialComponent,
    MaterialsViewComponent,
    HandlingUnitsComponent,
    HandlingUnitsEditComponent,
    HandlingUnitsDetailsComponent,
    HandlingUnitsViewComponent,
    CreateHandlingUnitsComponent,
    SetupInventoryComponent,
    SetupDomainsComponent,
    SetupAssetsComponent,
    AssetsCreateComponent,
    AssetsViewComponent,
    BulletinBoardComponent,
    TransportersComponent,
    TransportersEditComponent,
    TransportersDetailsComponent,
    TransportersViewComponent,
    CreateTransportersComponent,
    ManufacturersComponent,
    // ManufacturersEditComponent,
    ManufacturesEditComponent,
    // ManufacturersDetailsComponent,
    ManufacturersViewComponent,
    CreateManufacturersComponent,
    ManufacturesDetailsComponent,
    FacilityGroupComponent,
    FacilityGroupEditComponent,
    FacilityGroupCreateComponent,
    FacilityGroupViewComponent,
    FacilityComponent,
    FacilityCreateComponent,
    FacilityDetailsComponent,
    FacilityViewComponent,
    DomainActivityComponent,
    TransactionCountsComponent,
    UserActivityComponent,
    AssetCapacityComponent,
    AssetStatusComponent,
    PowerAvailabilityComponent,
    SicknessRateComponent,
    TempExcursionsComponent,
    UpTimeComponent,
    CstmRprtsComponent,
    AbnormalStockComponent,
    ConsumptionComponent,
    DiscardsComponent,
    StockAvailabilityComponent,
    StockTrendsComponent,
    SupplyComponent,
    UtilizationComponent,
    OrderStatusComponent,
    OrderDiscrepanciesComponent,
    OrderDemandComponent,
    OrderResponseTimeComponent,
    RplnshRspnTimeComponent,
    RspnsTmRparComponent,
    UaByUserComponent,
    UaByUsersComponent,
    TsOverviewComponent,
    TsMaterialComponent,
    TsFacilitysComponent,
    TsLocationsComponent,
    AcOverviewComponent,
    AcLocationsComponent,
    AcFacilityCategoryComponent,
    AcAssetTypeComponent,
    AsOverviewComponent,
    AsLocationsComponent,
    AsManufactureComponent,
    AsModelComponent,
    PaOverviewComponent,
    PaFacilitysComponent,
    PaLocationsComponent,
    RspnsTmRparOverviewComponent,
    RspnsTmRparFacilitysComponent,
    RspnsTmRparLocationsComponent,
    SrOverviewComponent,
    SrLocationsComponent,
    TempExcursionOverviewComponent,
    TempExcursionAssetsComponent,
    TempExcursionLocationsComponent,
    AbnormalStockOverviewComponent,
    AbnormalStockMaterialsComponent,
    AbnormalStockFacilitysComponent,
    AbnormalStockLocationsComponent,
    ConsumptionOverviewComponent,
    ConsumptionMaterialsComponent,
    DiscardsOverviewComponent,
    DiscardsMaterialsComponent,
    DiscardsFacilitysComponent,
    DiscardsLocationsComponent,
    RplnshRspnTimeOverviewComponent,
    RplnshRspnTimeMaterialsComponent,
    RplnshRspnTimeFacilitysComponent,
    RplnshRspnTimeLocationsComponent,
    StockAvailabilityOverviewComponent,
    StockAvailabilityMaterialsComponent,
    StockAvailabilityFacilitysComponent,
    StockAvailabilityLocationsComponent,
    StockTrendsOverviewComponent,
    StockTrendsFacilitysComponent,
    StockTrendsLocationsComponent,
    SupplyOverviewComponent,
    SupplyMaterialsComponent,
    SupplyFacilitysComponent,
    SupplyLocationsComponent,
    UtilizationOverviewComponent,
    UtilizationFacilitysComponent,
    DemandOverviewComponent,
    DemandMaterialsComponent,
    DemandFacilitysComponent,
    DemandLocationsComponent,
    DemandReceivingFacilityComponent,
    OrderDiscrepanciesOverviewComponent,
    OrderDiscrepanciesMaterialsComponent,
    OrderDiscrepanciesFacilitysComponent,
    OrderDiscrepanciesLocationsComponent,
    OrderRespTmOverviewComponent,
    OrderRespTmFacilitysComponent,
    OrderRespTmLocationsComponent,
    ReportFilterComponent,
    UpTimeOverviewComponent,
    UpTimeFacilitysComponent,
    UpTimeLocationsComponent,
    ConfgFormComponent,
    SearchPipe,
    DbActivityComponent,
    DbAssetsComponent,
    DbCustomDashboardComponent,
    DbInventoryComponent,
    DbOrdersComponent,
    DbActivityOverviewComponent,
    ActiualDataOfTransactionComponent,
    DbInvOverviewComponent,
    DbInvStockOutPredictionComponent,
    CnfgGeneralComponent,
    CnfgAccountingComponent,
    CnfgCapabilitiesComponent,
    CnfgApprovalsComponent,
    CnfgAssetsComponent,
    CnfgBulletinBoardComponent,
    CnfgCustomDashboardComponent,
    CnfgDashboardComponent,
    CnfgInventoryComponent,
    CnfgOrdersComponent,
    CnfgCustomReportsComponent,
    CnfgEventSummaryComponent,
    CnfgNotificationsComponent,
    CnfgOptionsComponent,
    CnfgStockRebalancingComponent,
    CnfgTagsComponent,
    CnfgBulletinBoardConfigureComponent,
    CnfgBulletinBoardViewComponent,
    CnfgCreateBulletinBoardComponent,
    CnfgCreateCustomDashboardComponent,
    CnfgCustomDashboardViewComponent,
    CnfgEventSummaryActivityComponent,
    CnfgEventSummaryAssetsComponent,
    CnfgEventSummaryInventoryComponent,
    CnfgEventSummarySupplyComponent,
    CnfgNavigationComponent,
    NotificationsAccessLogComponent,
    CnfgAddNotificationsComponent,
    CnfgEventNotificationsComponent,
    CnfgNotificationsBulletinBoardComponent,
    CnfgNotificationsGeneralComponent,
    CnfgSentNotificationsComponent,
    DmnActvtyNewComponent,
    ManageComponent,
    DomainComponent,
    DomainListComponent,
    DomainCreateComponent,
    DomainDetailsComponent,
    ManageDataComponent,
    SystemConfigComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    ConsumptionFacilitiesComponent,
    ConsumptionLocationsComponent,
    StockReportMaterialStoreComponent,
    StockReportExpiresBeforeComponent,
    StockReportAllComponent,
    DashboardOverviewComponent,
    CnclOrderComponent,
    PucComponent,
    ActivityRateComponent,
    LoginOldComponent,
    StockViewsComponent,
    StocksReportsComponent,
    ConsumptionNewComponent,
    TransfersComponent,
    TransfersCreateComponent,
    TransfersListComponent,
    UserExportsComponent,
    UserProfileComponent,
    OverviewDashboardComponent,
    EStockReportComponent,
    FacilitySummaryComponent,
    ReportsHelpComponent,
    OvrwDashboardNewComponent,
    SetupUpdateBatchComponent,
    TxnExportsComponent,
    CnfgGnrlComponent,
    CcemanufacturersComponent,
    CceManfcturersComponent,
    CceModelComponent,
    PrchsIndentComponent,
    PrchsIndentListComponent,
    CreatePrchsIndentComponent,
    PrchsIndentSummaryComponent,
    CnsinWiseCnsumtonComponent,
    PrchsIndentStatusComponent,
    PrchsIndntDtlsComponent,
    PrchsIndntFulfullComponent,
    PrchsIndntCnsgneeComponent,
    CoverageReportComponent,
    WastageReportComponent,
    MnftrTrnsctnsComponent,
    PrchsIndntFulfillMdlComponent,
    ParMdlComponent,
    OrdrVarComponent,
    OrdrParComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    LeafletModule,

    ToastrModule.forRoot({ preventDuplicates: true, progressBar: true, progressAnimation: "decreasing", closeButton: true, timeOut: 10000 }),
    TypeaheadModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxSpinnerModule,
    NgxPrintModule,
    NgxPaginationModule,
    BreadcrumbModule,
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),    
    NgMultiSelectDropDownModule.forRoot(),
    PopoverModule,
    NgxQRCodeModule,
    NgSelectModule,
  ],
  providers: [
    DatePipe,
    GlobalService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
    // BsModalService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule { }