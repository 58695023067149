<form [formGroup]="ordrStatForm" autocomplete="off">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="p-2 pt-0">
                <div class="float-start">
                    <span class="h4 mr-2">Indent: {{orderId}}</span>&nbsp;
                    <span *ngIf="orderStatus[0]?.status!='Cancelled'" class="badge bg-success">{{orderStatus[0]?.status}}</span>
                    <span *ngIf="orderStatus[0]?.status=='Cancelled'" class="badge bg-danger">{{orderStatus[0]?.status}}</span>
                    <div class="mt-0">Issue reference #:
                        <div *ngIf="orderStatus[0]?.status!='Cancelled'">
                            <span *ngIf="irflg">
                                <span>{{orderStatus[0]?.reference}}</span>&nbsp;
                                <a *ngIf="orderStatus[0]?.status=='Confirmed' && mmIdDtlsEr">
                                    <i class="fa fa-edit" data-toggle="tooltip" data-placement="top" title="Edit" (click)="irflg=!irflg"></i>
                                </a>
                            </span>
                            <span *ngIf="!irflg">
                                <input type="text" class="form-control form-control-sm" formControlName="issuseRefNo">
                                <button type="button" class="btn btn-sm btn-primary mt-1"
                                    (click)="updateOrderDetails()">Save</button>&nbsp;
                                <button type="button" class="btn btn-sm btn-light mt-1"
                                    (click)="irflg=!irflg">Cancel</button>
                            </span>
                        </div>
                    </div>
                    <div class="small">Created on {{orderStatus[0]?.createdOn}} by
                        <a>{{orderStatus[0]?.createdName}}</a>
                    </div>
                    <div class="small">Last updated on {{orderStatus[0]?.updatedOn}} by
                        <a>{{orderStatus[0]?.updatedName}}</a>
                    </div>
                </div>
                <div class="float-end">
                    <span data-tooltip="Invoice" class="toggle-data" title="Invoice" (click)="onInvc()"
                        *ngIf="(orderStatus[0]?.status =='Shipped' || orderStatus[0]?.status =='Fulfilled') && orderStatus[0]?.orderTypeId != 3">
                        <i class="fa fa-print" aria-hidden="true"></i></span>
                    <span data-tooltip="Invoice" class="toggle-data" title="Invoice" (click)="onInvc()"
                        *ngIf="orderStatus[0]?.orderTypeId == 3">
                        <i class="fa fa-print" aria-hidden="true"></i></span>&nbsp;
                    <span data-tooltip="facility app" class="f-14" *ngIf="orderStatus[0]?.sourceType==1">
                        <i aria-hidden="true" class="fa fa-mobile"></i></span>&nbsp;
                    <span data-tooltip="facility app" class="f-14" *ngIf="orderStatus[0]?.sourceType==1">
                        <i aria-hidden="true" class="fa fa-globe"></i></span>
                    <span data-bs-toggle="collapse" data-bs-target="#multiCollapseExample2" aria-expanded="false"
                        aria-controls="multiCollapseExample2" *ngIf="orderStatus[0]?.sourceType==2">
                        <span data-tooltip="map view" class="f-14"><i aria-hidden="true" class="fa fa-desktop"></i></span>
                    </span>
                </div>
                <div class="clearfix"></div>
                <hr class="mt-1 mb-1" />
                <span *ngIf="bbflg">
                    <span
                        class="f-12 badge rounded-pill bg-secondary pt-1 pb-1">{{orderStatus[0]?.bookingBadge}}</span>&nbsp;
                    <a *ngIf="orderStatus[0]?.status=='Confirmed' && mmIdDtlsEc">
                        <i class="fa fa-edit" data-toggle="tooltip" data-placement="top" title="Edit" (click)="bbflg=!bbflg"></i>
                    </a>
                </span>
                <span *ngIf="!bbflg">
                    <div class="row col-xl-3">
                        <select class="form-select form-select-sm ml-3" formControlName="bookingBadge">
                            <option value="">-Select-</option>
                            <option *ngFor="let ot of lstTags" [value]="ot.id">{{ot.name}}</option>
                        </select>
                    </div>
                    <button type="button" class="btn btn-sm btn-primary mt-1" (click)="updateOrderDetails()">
                        Save
                    </button>&nbsp;
                    <button type="button" class="btn btn-sm btn-light mt-1" (click)="bbflg=!bbflg">
                        Cancel
                    </button>
                </span>
                <div class="mt-1 mb-1" *ngIf="!orderStatus[0]?.bookingBadge && bbflg && orderStatus[0]?.status!='Cancelled'">Add Indent tags</div>
                <hr class="mt-1 mb-1" />
                <div class="clearfix"></div>
                <div class="collapse multi-collapse" id="multiCollapseExample2">
                    <div class="card border-0 mt-3 shadow">
                        <div class="card-body p-2">
                            <div id="map-container-google-1" class="z-depth-1-half map-container"
                                style="width: 100%; height: 500px">
                                <iframe
                                    src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    width="100%" height="100%" frameborder="0" style="border:0"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 mt-3 shadow">
                    <div class="card-header p-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="float-start pt-1">
                                    <h6>Status </h6>
                                </div>
                                <div class="float-end m-2">
                                    <span class="m-2" title="History" class="toggle-data" *ngIf="mmIdDtlsIh">
                                        <a class="btn btn-sm btn-primary pt-1qz pb-1/" data-bs-toggle="modal"
                                            data-bs-target="#history-status" (click)="LoadHstry()">
                                            <i class="fa fa-history" aria-hidden="true"></i>
                                        </a>
                                    </span>&nbsp;
                                    <span class="dropdown" *ngIf="orderStatus[0]?.status!='Cancelled'">
                                        <button class="btn btn-sm btn-primary dropdown-toggle mr-2" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false" *ngIf="orderStatus[0]?.status!='Fulfilled'">
                                            <i class="fa fa-bars pr-0"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end p-0 btn_drop"
                                            aria-labelledby="dropdownMenuButton1">                                            
                                            <li *ngIf="mmIdDtlsRi">
                                                <a class="dropdown-item"
                                                    *ngIf="orderStatus[0]?.status=='Confirmed'" (click) ="onReopen(orderStatus[0].bookingId)">Reopen</a>
                                            </li>
                                            <li *ngIf="mmIdDtlsDi">
                                                <a class="dropdown-item" data-toggle="modal" data-target="#mdlRD"
                                                    *ngIf="orderStatus[0]?.status=='Confirmed'" (click)="dispatchForm.get('DispatchComment')?.setValue('')">
                                                    Ready For Dispatch
                                                </a>
                                            </li>
                                            <li *ngIf="mmIdDtlsCi">
                                                <!-- <a class="dropdown-item"
                                                    *ngIf="orderStatus[0]?.status=='Ready For Dispatch'">fulfill</a> -->
                                                <a *ngIf="orderStatus[0]?.status=='Pending' || orderStatus[0]?.status=='Confirmed' ||
                                                    orderStatus[0]?.status=='Shipped' || orderStatus[0]?.status=='Ready For Dispatch'"
                                                    class="dropdown-item" (click)="onCncl()">Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </span>
                                    <span class="m-2" *ngIf="orderStatus[0]?.status!='Cancelled' && mmIdDtlsSI &&
                                        (orderStatus[0]?.status=='Pending' && paramTabName == 'i' || orderStatus[0]?.status=='Confirmed' ||
                                        orderStatus[0]?.status=='Ready For Dispatch')">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                            (click)="openModal()">Ship</button>
                                    </span>
                                    <span class="m-2" *ngIf="orderStatus[0]?.status!='Cancelled' && mmIdDtlsFi">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                            *ngIf="orderStatus[0]?.status=='Shipped'"
                                            (click)="onClkFulfill()">Fulfill</button>
                                    </span>
                                    <span class="m-2" *ngIf="orderStatus[0]?.status!='Cancelled'">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                            *ngIf="orderStatus[0]?.status=='Pending' && paramTabName != 'i'"
                                            (click) = "onConfrim(orderStatus[0].bookingId)">Confirm</button>
                                    </span> 
                                    <!-- <span class="m-2" *ngIf="orderStatus[0]?.batchEnabled">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"                                            
                                            *ngIf="orderStatus[0]?.status=='Fulfilled' && orderStatus[0]?.file_export_path== null" (click)="openModalVAR('v')">Download VAR</button>                                            
                                    </span>
                                    <span class="m-2" *ngIf="orderStatus[0]?.batchEnabled == false">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"                                            
                                            *ngIf="orderStatus[0]?.status=='Fulfilled' && orderStatus[0]?.file_export_path== null" (click)="openModalVAR('p')">Download PAR</button>                                            
                                    </span>                                 -->
                                </div>
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-xl-12">
                                        <div class="process-wrapper">
                                            <div id="progress-bar-container" *ngIf="progressdata.length != 0">
                                                <div>
                                                    <ul>
                                                        <li style="animation-timing-function: ease-in;"
                                                            [ngClass]="item.label == 'Cancelled' ? 'inactive' : 'active'"
                                                            *ngFor="let item of progressdata; let i=index">
                                                            <div [ngStyle]="{ 'color': item.label == 'Cancelled' ? 'red' : '#28a745' }"
                                                                [attr.id]='i' class="step-inner">{{item.label}} </div>
                                                            <div class="status">
                                                                <div>{{item.activityDate != null ? (item.activityDate |
                                                                    date: 'dd-MM-yyyy hh:mm aa') : ''}}</div>
                                                            </div>
                                                            <div class="status">
                                                                <div>{{item.createdBy}}</div>
                                                            </div>
                                                        </li>
                                                    </ul> 
                                                </div>
                                                <div id="line">
                                                    <div id="line-progress"></div>
                                                </div>
                                            </div>
                                            <div class="text-center" *ngIf="progressdata.length == 0">
                                                {{noRcrdsTxt}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="float-end mb-2 mt-2">
                                    Processing time: 0.02 hour(s), Delivery lead time: 0 hours
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-xl-6 mb-3">
                                <div class="card shadow-sm h-100">
                                    <div class="card-header p-2 font-wt">Issuing facility</div>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-4">Issuing facility:</div>
                                            <div class="col-sm-8">
                                                <a>{{orderStatus[0]?.issuingStoreName}}</a>
                                                <div>{{orderStatus[0]?.issuingStoreLocation}}</div>
                                            </div>
                                        </div>
                                        <div class="row mt-1">
                                            <div class="col-sm-4 ">Estimated date of arrival:</div>
                                            <div class="col-sm-6">
                                                <div *ngIf="orderStatus[0]?.status!='Cancelled'">
                                                    <span *ngIf="edflg">
                                                        {{orderStatus[0]?.estimatedDateOfArrival}}
                                                        <span *ngIf="!orderStatus[0]?.estimatedDateOfArrival &&
                                                            orderStatus[0]?.status!='Fulfilled'">Select date</span>
                                                        <a *ngIf="orderStatus[0]?.status!='Fulfilled' && mmIdDtlsEe">
                                                            <i class="fa fa-edit" data-placement="top" title="Edit" (click)="edflg=!edflg"></i>
                                                        </a>
                                                    </span>
                                                    <span *ngIf="!edflg">
                                                        <div class="input-group mb-3">
                                                            <input type="text" autocomplete="off" onpaste="return false"
                                                                bsDatepicker class="form-control form-control-sm optinbg bsDP-bg"
                                                                #dpEAD="bsDatepicker" [bsConfig]="{ isAnimated: true,
                                                                    dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default',
                                                                    showWeekNumbers: false, adaptivePosition: true }"
                                                                readonly onkeypress='return event.charCode == 8'
                                                                [dateCustomClasses]="cstmDt" (bsValueChange)="onChngDT($event)"
                                                                formControlName="estimatedDateOfArvl" [minDate]="dtToDay">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button"
                                                                    (click)="dpEAD.toggle()"
                                                                    [attr.aria-expanded]="dpEAD.isOpen"> <i
                                                                        class="fa fa-calendar"></i>
                                                                </button>
                                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button"
                                                                    title="Clear Date"
                                                                    (click)="dpEAD.hide();clearBSDate('estimatedDateOfArvl')">
                                                                    <i class="fa fa-times"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <button type="button" class="btn btn-sm btn-primary mt-1"
                                                            (click)="updateOrderDetails()">Save</button>&nbsp;
                                                        <button type="button" class="btn btn-sm btn-light mt-1"
                                                            (click)="edflg=!edflg">Cancel</button>
                                                    </span>
                                                </div>
                                                <div *ngIf="orderStatus[0]?.status=='Cancelled'">
                                                    {{orderStatus[0]?.estimatedDateOfArrival | date:"dd-MM-yyyy"}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 mb-3">
                                <div class="card shadow-sm h-100">
                                    <div class="card-header p-2 font-wt">Receiving facility</div>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-4">Receiving facility:</div>
                                            <div class="col-sm-8">
                                                <a>{{orderStatus[0]?.receivingStoreName}}</a>
                                                <div>{{orderStatus[0]?.receivingStoreLocation}}</div>
                                            </div>
                                        </div>
                                        <div class="row mt-1">
                                            <div class="col-sm-4">Required by date:</div>
                                            <div class="col-sm-6">
                                                <div *ngIf="orderStatus[0]?.status!='Cancelled'">
                                                    <span *ngIf="rdflg">
                                                        {{orderStatus[0]?.requiredByDate | date: 'dd-MM-yyyy'}}
                                                        <span *ngIf="!orderStatus[0]?.requiredByDate &&
                                                            orderStatus[0]?.status!='Fulfilled'">Select date </span>
                                                        <a *ngIf="orderStatus[0]?.status!='Fulfilled' && mmIdDtlsEd">
                                                            <i class="fa fa-edit" data-placement="top" title="Edit" (click)="rdflg=!rdflg"></i>
                                                        </a>
                                                    </span>
                                                    <span *ngIf="!rdflg">
                                                        <div class="input-group mb-3">
                                                            <input type="text" autocomplete="off" onpaste="return false"
                                                                bsDatepicker class="form-control form-control-sm optinbg bsDP-bg" readonly
                                                                #dpRBD="bsDatepicker" [bsConfig]="{ isAnimated: true,
                                                                    dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default',
                                                                    showWeekNumbers: false, adaptivePosition: true }"
                                                                onkeypress='return event.charCode == 8' [minDate]="dtToDay"
                                                                formControlName="requiredByDate" [dateCustomClasses]="cstmDt"
                                                                (bsValueChange)="onChngDT($event)">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button"
                                                                    (click)="dpRBD.toggle()"
                                                                    [attr.aria-expanded]="dpRBD.isOpen"> <i
                                                                        class="fa fa-calendar"></i>
                                                                </button>
                                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button"
                                                                    title="Clear Date"
                                                                    (click)="dpRBD.hide();clearBSDate('requiredByDate')"> <i
                                                                        class="fa fa-times"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <button type="button" class="btn btn-sm btn-primary mt-1"
                                                            (click)="updateOrderDetails()">Save</button>&nbsp;
                                                        <button type="button" class="btn btn-sm btn-light mt-1"
                                                            (click)="rdflg=!rdflg">Cancel</button>
                                                    </span>
                                                </div>
                                                <div *ngIf="orderStatus[0]?.status=='Cancelled'">
                                                    {{orderStatus[0]?.requiredByDate | date: "dd-MM-yyyy"}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0">
                            <div class="card-body p-2">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item"><a class="nav-link active" id="Items-tab" data-bs-toggle="tab"
                                            href="#Items" role="tab" aria-controls="Items" aria-selected="true">Items
                                            <span class="badge rounded-pill bg-secondary">{{lstMats.length}}</span></a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link" id="Shipments-tab" data-bs-toggle="tab"
                                            href="#Shipments" role="tab" aria-controls="Shipments"
                                            aria-selected="true">Shipments
                                            <span
                                                class="badge rounded-pill bg-secondary">{{lstShipments.length}}</span></a>
                                    </li>
                                    <!-- <li class="nav-item"><a class="nav-link" id="Returns-tab" data-bs-toggle="tab"
                                            href="#Returns" role="tab" aria-controls="Returns"
                                            aria-selected="true">Returns
                                            <span class="badge rounded-pill bg-secondary">0</span></a></li> -->
                                    <li class="nav-item"><a class="nav-link" id="Comments-tabs" data-bs-toggle="tab"
                                            href="#Comments" role="tab" aria-controls="Comments"
                                            aria-selected="false">Comments
                                            <span
                                                class="badge rounded-pill bg-secondary">{{lstAllComments.length}}</span></a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="Items" role="tabpanel"
                                        aria-labelledby="Items-tab">
                                        <div *ngIf="false">
                                            <button type="button" class="btn btn-sm btn-primary mt-3"
                                            *ngIf="(orderStatus[0]?.status=='Confirmed' || orderStatus[0]?.status=='Pending') && (!allocateS && !editFlag) && mmIdDtlsEi &&
                                                orderStatus[0]?.status!='Cancelled'" (click)="onEdit()">Edit</button>&nbsp;&nbsp;
                                        </div>
                                        <button type="button" class="btn btn-sm btn-primary mt-3" (click)="allocate()"
                                            *ngIf="orderStatus[0]?.status=='Confirmed' && (!allocateS && !editFlag) && mmIdDtlsAi &&
                                                orderStatus[0]?.status!='Cancelled'">Allocate</button>
                                        <div class="table_spacing table-responsive mt-3">
                                            <table class="table table-striped table-sm table-bordered"
                                                formArrayName="bookingItems">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Material</th>
                                                        <th scope="col" class="text-center">Ordered</th>
                                                        <th scope="col" class="text-center"
                                                            *ngIf="orderStatus[0]?.status=='Ready For Dispatch' || orderStatus[0]?.status=='Confirmed'">
                                                            Allocated</th>
                                                        <th scope="col" class="text-center">Shipped</th>
                                                        <th scope="col" class="text-center"
                                                            *ngIf="orderStatus[0]?.status=='Ready For Dispatch' || orderStatus[0]?.status=='Confirmed'">
                                                            Yet to ship</th>
                                                        <th scope="col" class="text-center"
                                                            *ngIf="orderStatus[0]?.status=='Ready For Dispatch' || orderStatus[0]?.status=='Confirmed'">
                                                            Total stock<div class="small">
                                                                <div>{{orderStatus[0]?.receivingStoreName}}</div>
                                                            </div>
                                                        </th>
                                                        <th scope="col" class="text-center"
                                                            *ngIf="orderStatus[0]?.status=='Ready For Dispatch' || orderStatus[0]?.status=='Confirmed' || orderStatus[0]?.status=='Pending'">
                                                            Available stock<div class="small">
                                                                <div>{{orderStatus[0]?.issuingStoreName}}</div>
                                                            </div>
                                                        </th>
                                                        <th scope="col" class="text-center"
                                                            *ngIf="orderStatus[0]?.status=='Fulfilled' ">Fulfilled</th>
                                                        <th *ngIf="orderStatus[0]?.status=='Ready For Dispatch' || orderStatus[0]?.status=='Confirmed' ||  orderStatus[0]?.status=='Pending'"></th>
                                                        <th scope="col" class="text-center" *ngIf="orderStatus[0]?.status == 'Fulfilled' && orderStatus[0]?.orderTypeId == 3 && isFlePthShwHde">File Path</th>                                                    
                                                        <th scope="col" class="text-center" *ngIf="orderStatus[0]?.status == 'Fulfilled' && orderStatus[0]?.orderTypeId == 3 && isFleShwHde">Upload VAR/PAR</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let item of GetMatrls()?.controls;let tabIndx=index;"
                                                    [formGroupName]="tabIndx">
                                                    <tr>
                                                        <td>{{item?.get('name')?.value}}</td>
                                                        <td class="text-center">
                                                            <span *ngIf="!editFlag">
                                                                {{item?.get('ordrdStock')?.value}}
                                                                <sup *ngIf="item?.get('isedited')?.value"
                                                                    class="toggle-data" data-toggle="tooltip"
                                                                    data-placement="left"
                                                                    [title]="item?.get('ttlToolTip')?.value">
                                                                    <i class="fa fa-info-circle" id="popoverOption"
                                                                        data-placement="top" rel="popover"
                                                                        data-content="Recommended: 0; Originally ordered: 20; Reason for ordered discrepency: Demand Low"></i>
                                                                </sup>
                                                            </span>
                                                            <span *ngIf="editFlag">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control form-control-sm" 
                                                                        formControlName="quantity" (keyup)="onEditQty(item, tabIndx+1 > lstMats.length)"
                                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                                        [ngClass]="{ 'is-invalid': isSbmtd && (item?.get('quantity')?.errors || item?.get('isInvalidPttrn')?.errors )}">
                                                                    <div *ngIf="isSbmtd && (item?.get('quantity')?.errors || item?.get('isInvalidPttrn')?.errors)"
                                                                        class="invalid-feedback">
                                                                       <div *ngIf="item?.get('isInvalidPttrn')?.errors?.pattern">Quantity should be multiple of {{item?.get('handlngUnt')?.value}}</div>
                                                                        <div *ngIf="item?.get('quantity')?.errors?.required">Please fill Quantity</div>
                                                                        <!-- <div *ngIf="item?.get('quantity')?.errors?.pattern">Quantity should be multiple of 5</div> -->
                                                                        <!-- <div *ngIf="item?.get('quantity')?.errors?.min"><span *ngIf="tabIndx+1 > lstMats.length">Min. value should be 1</span><span *ngIf="tabIndx+1 <= lstMats.length">
                                                                                Ordered quantity cannot be less than allocated quantity
                                                                            </span>
                                                                        </div> -->
                                                                        <div *ngIf="item?.get('quantity')?.errors?.min">Min. value should be {{item?.get('handlngUnt')?.value != null ? item?.get('handlngUnt')?.value : '1'}}</div>
                                                                        <div *ngIf="item?.get('quantity')?.errors?.minlength">Min. Length is {{item?.get('handlngUnt')?.value != null ? item?.get('handlngUnt')?.value.toString().length : '1'}}</div>
                                                                        <!-- <div *ngIf="item?.get('quantity')?.errors?.max">Max. allowed is {{item?.get('stk')?.value}}</div>-->
                                                                        <div *ngIf="item?.get('quantity')?.errors?.maxlength">Max. Length is 10</div>
                                                                    </div><br />
                                                                    <span *ngIf="(tabIndx+1 <= lstMats.length) &&  item?.get('minMax')?.value==true">
                                                                        <div *ngIf="(item?.get('ordrdStock')?.value != item?.get('quantity')?.value) && item?.get('minMax')?.value==true">
                                                                           <span class="txt-red">Recommended: {{item?.get('rcmndedStk')?.value}}</span><br />
                                                                            <label>Reason<span class="text-danger" *ngIf="(item?.get('ordrdStock')?.value != item?.get('quantity')?.value)"><sup>*</sup></span></label>
                                                                            <select class="form-select form-select-sm" formControlName="modifiedReason"
                                                                            (change)="item?.get('others')?.setValue(''); onSlctRR($event, tabIndx+1, tabIndx+1 > lstMats.length)"
                                                                                [ngClass]="{ 'is-invalid': isSbmtd && item?.get('modifiedReason')?.errors }">
                                                                                <option value="">Select Reason</option>
                                                                                <option [value]="rs.reasonName" *ngFor="let rs of oldrsnsLst">{{rs.reasonName}}</option>
                                                                            </select>
                                                                            <div *ngIf="isSbmtd && item?.get('modifiedReason')?.errors" class="invalid-feedback">
                                                                                <div *ngIf="isSbmtd && item?.get('modifiedReason')?.errors?.required">Please choose Reason</div>
                                                                            </div> <br />
                                                                            <textarea *ngIf="item?.get('modifiedReason')?.value=='Others'"
                                                                                class="form-control form-control-name taRS"
                                                                                [ngClass]="{ 'is-invalid': isSbmtd &&
                                                                                    (item?.get('modifiedReason')?.value == 'Others') &&
                                                                                    (item?.get('others')?.value == '') }"
                                                                                placeholder="Enter Reason" formControlName="others">
                                                                            </textarea>
                                                                            <div *ngIf="isSbmtd && (item?.get('modifiedReason')?.value == 'Others') &&
                                                                                (item?.get('others')?.value == '')"
                                                                                class="invalid-feedback">
                                                                                <div>Please fill Reason</div>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                    <span *ngIf="(tabIndx+1 > lstMats.length) &&  item?.get('minMax')?.value==true">
                                                                        <div *ngIf="(item?.get('ordrdStock')?.value != item?.get('quantity')?.value) && item?.get('minMax')?.value==true">
                                                                           <span class="txt-red">Recommended: {{item?.get('rcmndedStk')?.value}}</span><br />
                                                                            <label>Reason<span class="text-danger" *ngIf="(item?.get('ordrdStock')?.value != item?.get('quantity')?.value)"><sup>*</sup></span></label>
                                                                            <select class="form-select form-select-sm" formControlName="reason" 
                                                                            (change)="item?.get('others')?.setValue(''); onSlctRR($event, tabIndx+1, tabIndx+1 > lstMats.length)"
                                                                                [ngClass]="{ 'is-invalid': isSbmtd && item?.get('reason')?.errors }">
                                                                                <option value="">Select Reason</option>
                                                                                <option [value]="rs.reasonName" *ngFor="let rs of rsnsLst">{{rs.reasonName}}</option>
                                                                            </select>
                                                                            <div *ngIf="isSbmtd && item?.get('reason')?.errors" class="invalid-feedback">
                                                                                <div *ngIf="isSbmtd && item?.get('reason')?.errors?.required">Please choose Reason</div>
                                                                            </div> <br />
                                                                            <textarea *ngIf="item?.get('reason')?.value=='Others'"
                                                                                class="form-control form-control-name taRS"
                                                                                [ngClass]="{ 'is-invalid': isSbmtd && (item?.get('reason')?.value == 'Others') &&
                                                                                (item?.get('others')?.value == '') }"
                                                                                placeholder="Enter Reason"
                                                                                formControlName="others">
                                                                            </textarea>
                                                                            <div *ngIf="isSbmtd && (item?.get('reason')?.value == 'Others') &&
                                                                                (item?.get('others')?.value == '')" class="invalid-feedback">
                                                                                <div>Please fill Reason</div>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </span>
                                                        </td>
                                                        <td class="text-center"
                                                            *ngIf="orderStatus[0]?.status=='Ready For Dispatch' || orderStatus[0]?.status=='Confirmed' ">
                                                            <span *ngIf="!allocateS">{{item?.get('allctd')?.value !=
                                                                null ? item?.get('allctd')?.value : 0}}</span>&nbsp;
                                                            <a (click)="loadAllocateBatches('alloBatches', item?.get('productId')?.value, item?.get('bookingItemId')?.value, tabIndx)"
                                                                *ngIf="!allocateS && item?.get('isBtch')?.value">
                                                                <i class="fa fa-list-alt"></i>
                                                            </a>
                                                            <table class="table table-striped table-sm table-bordered"
                                                                *ngIf="item?.get('statusAll')?.value"
                                                                formArrayName="lstAB1">
                                                                <thead>
                                                                    <th>Batch</th>
                                                                    <th>Expiry date</th>
                                                                    <th>Quantity</th>
                                                                </thead>
                                                                <tbody
                                                                    *ngFor="let bat of GetListAllAB1(tabIndx).controls;let batIndx=index;"
                                                                    [formGroupName]="batIndx">
                                                                    <tr *ngIf="bat?.get('quantity')?.value">
                                                                        <td>{{bat?.get('batch_no')?.value}}</td>
                                                                        <td>{{bat?.get('expiry_date')?.value | date : "dd-MM-yyyy"}}</td>
                                                                        <td>{{bat?.get('quantity')?.value}}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody
                                                                    *ngIf="GetListAllAB1(tabIndx).controls?.length==0">
                                                                    <tr>
                                                                        <td colspan="3" class="text-center">
                                                                            {{noRcrdsTxt}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <span *ngIf="allocateS && orderStatus[0]?.status=='Confirmed' && item?.get('isBtch')?.value">
                                                                <button class="btn btn-sm btn-success" (click)="allocatebyBatch(item, tabIndx);"
                                                                    *ngIf="!item?.get('shwAlloTab')?.value" [attr.disabled]="(item?.get('ordrdStock')?.value == 0 ||
                                                                    item?.get('avlStock')?.value == 0) ? true : null">Allocate by batch</button>
                                                                <button class="btn btn-sm btn-info" (click)="cancel(tabIndx)"
                                                                    *ngIf="item?.get('shwAlloTab')?.value">Hide batch</button>
                                                            </span>
                                                            <span *ngIf="allocateS && orderStatus[0]?.status=='Confirmed' && !item?.get('isBtch')?.value">
                                                                <input type="text" class="form-control form-control-sm" formControlName="allctd"
                                                                    [attr.disabled]="(item?.get('ordrdStock')?.value == 0 ||
                                                                        item?.get('avlStock')?.value == 0) ? true : null"
                                                                    [ngClass]="{ 'is-invalid': isAlctSave && (item?.get('allctd')?.value >
                                                                        item?.get('avlStock')?.value) }" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                                                <div *ngIf="isAlctSave && (item?.get('allctd')?.value >
                                                                    item?.get('avlStock')?.value)" class="invalid-feedback">
                                                                    Allocated cannot exceed Available stock
                                                                </div>
                                                                <!-- <input type="text" class="form-control form-control-sm" formControlName="allctd"
                                                                    [attr.disabled]="(item?.get('ordrdStock')?.value == 0 ||
                                                                        item?.get('avlStock')?.value == 0) ? true : null"
                                                                    [ngClass]="{ 'is-invalid': isAlctSave && (item?.get('allctd')?.value == null
                                                                        || item?.get('allctd')?.value == 0) && (item?.get('ordrdStock')?.value != 0 &&
                                                                        item?.get('avlStock')?.value != 0) }" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                                                <div *ngIf="isAlctSave && (item?.get('allctd')?.value == null ||
                                                                    item?.get('allctd')?.value == 0) && (item?.get('ordrdStock')?.value != 0 &&
                                                                    item?.get('avlStock')?.value != 0)" class="invalid-feedback">
                                                                    Min. value should be 1
                                                                </div> -->
                                                            </span>
                                                        </td>
                                                        <td class="text-center">{{item?.get('shiped')?.value != null ? item?.get('shiped')?.value : 0}}&nbsp;
                                                            <a *ngIf="item?.get('isBtch')?.value && (orderStatus[0]?.status=='Shipped' ||
                                                                orderStatus[0]?.status=='Fulfilled')" (click)="LoadBatches('shipB', item, tabIndx);">
                                                                <i class="fa fa-list-alt"></i>
                                                            </a>
                                                            <table class="table table-striped table-sm table-bordered"
                                                                *ngIf="item?.get('statusShi')?.value"
                                                                formArrayName="lstB">
                                                                <thead>
                                                                    <th>Batch</th>
                                                                    <th>Expiry date</th>
                                                                    <th>Quantity</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let bat of GetListAllocB(tabIndx).controls;let batIndx=index;"
                                                                        [formGroupName]="batIndx">
                                                                        <td>{{bat?.get('batch_no')?.value}}</td>
                                                                        <td>{{bat?.get('batch_expiry_date')?.value | date : "dd-MM-yyyy"}}</td>
                                                                        <td>{{bat?.get('quantity')?.value}}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody
                                                                    *ngIf="GetListAllocB(tabIndx).controls?.length==0">
                                                                    <tr>
                                                                        <td colspan="3" class="text-center">
                                                                            {{noRcrdsTxt}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td *ngIf="orderStatus[0]?.status=='Ready For Dispatch' || orderStatus[0]?.status=='Confirmed'"
                                                            class="text-center">{{item?.get('yetToShip')?.value}}</td>
                                                        <td *ngIf="orderStatus[0]?.status=='Ready For Dispatch' || orderStatus[0]?.status=='Confirmed'"
                                                            class="text-center">{{item?.get('ts')?.value}}
                                                            <div class="small toggle-data" title="(min, max)">
                                                                <!-- *ngIf="item?.get('isRCShow')?.value && && item?.get('ordrdStock')?.value >= item?.get('rc_min')?.value && 
                                                                item?.get('ordrdStock')?.value <= item?.get('rc_max')?.value" -->
                                                                <div *ngIf="tabIndx+1 <= lstMats.length ">
                                                                    ({{item?.get('rc_min')?.value}},
                                                                    {{item?.get('rc_max')?.value}})</div>
                                                            </div>&nbsp;&nbsp;
                                                            <div *ngIf="item?.get('in_transit_stock')?.value != 0 &&
                                                                item?.get('in_transit_stock')?.value != null && tabIndx+1 <= lstMats.length"
                                                                class="small">
                                                                <div>In-transit: {{item?.get('in_transit_stock')?.value}}</div>
                                                            </div>
                                                        </td>
                                                        <td *ngIf="orderStatus[0]?.status=='Ready For Dispatch' || orderStatus[0]?.status=='Confirmed' || orderStatus[0]?.status=='Pending'"
                                                            class="text-center">{{item?.get('avlStock')?.value}}
                                                            <!-- <span *ngIf="tabIndx+1 > lstMats.length">
                                                                <a (click)="onDelMtrls(tabIndx, item?.get('name')?.value)">
                                                                    <i class="fa fa-trash"></i>
                                                                </a>
                                                            </span> -->
                                                            <div class="small toggle-data" title="(min, max)"
                                                                *ngIf="tabIndx+1 <= lstMats.length && item?.get('ic_min')?.value != null &&
                                                                    item?.get('ic_max')?.value != null">
                                                                <!-- *ngIf="item?.get('isICShow')?.value && item?.get('ordrdStock')?.value >= item?.get('ic_min')?.value && item?.get('ordrdStock')?.value <= item?.get('ic_max')?.value" -->
                                                                <div>({{item?.get('ic_min')?.value}}, {{item?.get('ic_max')?.value}})</div>
                                                            </div>&nbsp;&nbsp;
                                                            <div *ngIf="item?.get('ic_total_stock')?.value != 0 && tabIndx+1 <= lstMats.length"
                                                                class="small">
                                                                <div>Total Stock: {{item?.get('ic_total_stock')?.value}}</div>
                                                            </div>&nbsp;&nbsp;
                                                            <div *ngIf="item?.get('ic_in_transit_stock')?.value != 0 &&
                                                                item?.get('ic_in_transit_stock')?.value != null && tabIndx+1 <= lstMats.length"
                                                                class="small">
                                                                <div>In-transit: {{item?.get('ic_in_transit_stock')?.value}}</div>
                                                            </div>
                                                        </td>
                                                        <td *ngIf="orderStatus[0]?.status=='Ready For Dispatch' || orderStatus[0]?.status=='Confirmed' ||  orderStatus[0]?.status=='Pending'">
                                                            <a  (click)="onRemove(tabIndx, item)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center" *ngIf="orderStatus[0]?.status=='Fulfilled'">
                                                            {{item?.get('fulfld')?.value}}
                                                            <a *ngIf="item?.get('isBtch')?.value && orderStatus[0]?.status=='Fulfilled'"
                                                                (click)="LoadFulfilBats(item, tabIndx);">
                                                                <i class="fa fa-list-alt"></i>
                                                            </a>
                                                            <table class="table table-striped table-sm table-bordered"
                                                                *ngIf="item?.get('statusFulfill')?.value"
                                                                formArrayName="lstFB">
                                                                <thead>
                                                                    <th>Batch</th>
                                                                    <th>Expiry date</th>
                                                                    <th>Quantity</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let bat of getFulfillBats(tabIndx).controls;let batIndx=index;"
                                                                        [formGroupName]="batIndx">
                                                                        <td>{{bat?.get('batch_no')?.value}}</td>
                                                                        <td>{{bat?.get('batch_expiry_date')?.value | date : "dd-MM-yyyy"}}</td>
                                                                        <td>{{bat?.get('quantity')?.value}}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody
                                                                    *ngIf="getFulfillBats(tabIndx).controls?.length==0">
                                                                    <tr>
                                                                        <td colspan="3" class="text-center">
                                                                            {{noRcrdsTxt}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <!--  *ngIf="item?.get('file_export_path')?.value != null && orderStatus[0]?.status == 'Fulfilled'" -->
                                                        <td class="text-center" *ngIf="orderStatus[0]?.status == 'Fulfilled' && orderStatus[0]?.orderTypeId == 3 && item?.get('file_export_path')?.value !=null">
                                                            <div *ngIf="item?.get('file_export_path')?.value !=null"><a href="{{item?.get('file_export_path')?.value}}" 
                                                                target="_blank">{{ item?.get('file_export_path')?.value !=null ? (item?.get('file_export_path')?.value).substring(108) : ''}}</a></div></td>
                                                        <td class="text-center" *ngIf="orderStatus[0]?.status == 'Fulfilled' && orderStatus[0]?.orderTypeId == 3 && item?.get('file_export_path')?.value ==null">
                                                            <div class="row" *ngIf="item?.get('file_export_path')?.value ==null">
                                                                <div class="col-xl-6 mt-2"><input type="file" class="form-control form-control-sm"
                                                                    accept="pdf" (change)="onFileSelectVar($event, tabIndx)"></div>
                                                                <div class="col-xl-2"><button class="btn btn-sm btn-primary mt-2" (click)="uploadFile(item, tabIndx)">Upload</button></div>
                                                                <div class="col-xl-4 mt-2 float-end" *ngIf="item?.get('isBtch')?.value == true">
                                                                    <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                                                        (click)="openModalVAR('v', item?.get('cargo_item_id')?.value)">Download VAR</button>
                                                                </div>
                                                                <div class="col-xl-4 mt-2 float-end" *ngIf="item?.get('isBtch')?.value == false">
                                                                    <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                                                        (click)="openModalVAR('p', item?.get('cargo_item_id')?.value)">Download PAR</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="allocateS && item?.get('shwAlloTab')?.value">
                                                        <td colspan="8">
                                                            <div class="card">
                                                                <div class="card-header p-2">
                                                                    <div class="float-start">
                                                                        <span class="font-weight-bold pt-3"> Batches of
                                                                            {{item?.get('name')?.value}}</span>
                                                                    </div>
                                                                    <div class="float-end">
                                                                        <button class="btn btn-sm rounded bg-light p-0"
                                                                            data-toggle="tooltip" data-placement="top"
                                                                            title="close">
                                                                            <a class="expand-row">
                                                                                <i class="fa fa-times-circle"
                                                                                    (click)="cancel(tabIndx)"></i>
                                                                            </a>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body p-2">
                                                                    <table class="table table-striped table-sm table-bordered"
                                                                        formArrayName="lstAB">
                                                                        <thead>
                                                                            <th>Item No.</th>
                                                                            <th>Batch ID</th>
                                                                            <th>Manufactured date (dd/mm/yyyy)</th>
                                                                            <th>Manufacturer</th>
                                                                            <th>Expiry date (dd/mm/yyyy)</th>
                                                                            <th>Total stock</th>
                                                                            <th>Allocated</th>
                                                                            <th>Available stock</th>
                                                                            <th>Quantity</th>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let bat of GetListAllAB(tabIndx).controls;let abIndex=index;"
                                                                                [formGroupName]="abIndex">
                                                                                <td>{{abIndex+1}}</td>
                                                                                <td>{{bat?.get('batch_no')?.value}}</td>
                                                                                <td>{{bat?.get('manufactured_date')?.value | date : "dd-MM-yyyy"}}</td>
                                                                                <td>{{bat?.get('batch_producer_name')?.value}}
                                                                                </td>
                                                                                <td>{{bat?.get('expiry_date')?.value|
                                                                                    date :"dd/MM/yyyy"}}</td>
                                                                                <td>{{bat?.get('total_stock')?.value}}
                                                                                </td>
                                                                                <td>{{bat?.get('allocated_stock')?.value}}
                                                                                </td>
                                                                                <td>{{bat?.get('available_stock')?.value}}
                                                                                </td>
                                                                                <td>
                                                                                    <input type="text"
                                                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                                                        class="form-control form-control-sm"
                                                                                        formControlName="quantity"
                                                                                        [ngClass]="{ 'is-invalid': isSb && bat?.get('quantity')?.errors }">
                                                                                    <div *ngIf="isSb && bat?.get('quantity')?.errors"
                                                                                        class="invalid-feedback">
                                                                                        <div *ngIf="bat?.get('quantity')?.errors?.max">
                                                                                            Quantity cannot exceed available stock</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                        <tbody *ngIf="GetListAllAB(tabIndx).controls?.length==0">
                                                                            <tr>
                                                                                <td colspan="9" class="text-center">
                                                                                    {{noRcrdsTxt}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div class="float-end mt-2">
                                                                        <button (click)="isSb=true;saveAllocBatches(tabIndx, item?.get('ordrdStock')?.value)"
                                                                            class="btn btn-sm btn-primary">Save</button>&nbsp;
                                                                        <button class="btn btn-sm btn-light" (click)="cancel(tabIndx)">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="GetMatrls()?.controls?.length==0">
                                                    <tr>
                                                        <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table>
                                                <tbody *ngIf="editFlag">
                                                    <tr>
                                                        <td colspan="9">
                                                            <ng-template #matItem let-model="item">
                                                                <span
                                                                    class="font-weight-bold">{{model.productName}}</span>
                                                            </ng-template>
                                                            <input name="mat" autocomplete="off" [adaptivePosition]="true"
                                                                placeholder="Type Material name to choose" type="text"
                                                                [typeahead]="MatsLst" [typeaheadItemTemplate]="matItem"
                                                                typeaheadOptionField="productName" formControlName="srchMtrl"
                                                                [typeaheadScrollable]="true" class="form-control form-control-sm w-150"
                                                                (typeaheadOnSelect)="slctdMat($event)" id="srchMtrl" 
                                                                [typeaheadOptionsLimit]="500">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="float-end mt-2" *ngIf="editFlag">
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="isSbmtd=true;editProds()">Save</button>&nbsp;
                                                <button class="btn btn-sm btn-light" (click)="canelEdit()">Cancel</button>
                                            </div>
                                            <div class="float-end mt-2" *ngIf="allocateS">
                                                <button class="btn btn-sm btn-primary" (click)="isAlctSave=true;allocateOrder()">Save</button>&nbsp;
                                                <button class="btn btn-sm btn-light" (click)="isAlctSave=false;cancelAllocate()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="Shipments" role="tabpanel"
                                        aria-labelledby="Shipments-tab">
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Shipment Id</th>
                                                        <th scope="col" class="text-center">Status</th>
                                                        <th scope="col" class="text-center">No of items</th>
                                                        <th scope="col" class="text-center">Transporter</th>
                                                        <th scope="col" class="text-center">Tracking #</th>
                                                        <th scope="col" class="text-center">EDA</th>
                                                        <th scope="col" class="text-center">Created</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="lstShipments.length!=0">
                                                    <tr *ngFor="let ship of lstShipments">
                                                        <td>{{ship.cargoNo}}</td>
                                                        <td>{{ship.cargoStatus}}</td>
                                                        <td>{{ship.noOfItems}}</td>
                                                        <td>{{ship.transporter}}</td>
                                                        <td>{{ship.trackingid}}</td>
                                                        <td>{{ship.estimatedDeliveryDate}}</td>
                                                        <td>{{ship.createdBy}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="lstShipments.length==0">
                                                    <tr>
                                                        <td colspan="7" class="text-center">{{noRcrdsTxt}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <!-- <div class="tab-pane fade" id="Returns" role="tabpanel"
                                        aria-labelledby="Returns-tab">
                                        <div class="card">
                                            <div class="card-body p-2">
                                                No returns have been created.
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="tab-pane fade" id="Comments" role="tabpanel"
                                        aria-labelledby="Comments-tab">
                                        <div class="table-responsive">
                                            <table
                                                class="table table-sm table-bordered table_spacing table-small-font p-0 mt-3">
                                                <tbody>
                                                    <tr *ngFor="let com of lstAllComments">
                                                        <td class="text-center"><i class="f-24 fa fa-user-circle-o"
                                                                aria-hidden="true"></i></td>
                                                        <td><a>{{com.userId}}</a>&nbsp;&nbsp;<small>added a message -
                                                            {{com.createdOnDate | date: "yy/MM/dd hh:mm a"}}</small>
                                                            <div>{{com.userComments}}</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="lstAllComments.length == 0">
                                                    <tr colspan="3" class="text-center">
                                                        <td>{{noRcrdsTxt}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="mt-3" *ngIf="(lstAllComments.length == 0 || cmntStat)">
                                            <div class="col-xl-12">
                                                <label class="form-label" for="comment">Comment</label>
                                                <textarea class="form-control taRS" id="comment"
                                                    formControlName="comment" placeholder="Add comment"></textarea>
                                            </div>
                                            <div class="float-end mt-2">
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="saveComments()">Save</button>&nbsp;
                                                <button class="btn btn-sm btn-light" (click)="cancelComment()">Cancel</button>
                                            </div>
                                        </div>
                                        <div class="mt-2 mb-2" *ngIf="!cmntStat && lstAllComments.length != 0 && mmIdDtlsAd">
                                            <button class="float-end btn btn-sm btn-primary" (click)="cmntStat = true">
                                                Add comment</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="modal" id="history-status">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Status history</h6>
            </div>
            <div class="modal-body">
                <span *ngIf="OrderHistory?.length != 0">
                    <div class="card-body p-2" *ngFor="let his of OrderHistory">
                        <i class="fa fa-check-circle"></i>
                        Order {{his.status}} on {{his.time | date: "d/M/yy hh:mm aa"}} by <a>{{his.user_id}}</a>
                    </div>
                </span>
                <span *ngIf="OrderHistory?.length == 0">
                    <div class="card-body p-2 text-center">{{noRcrdsTxt}}</div>
                </span>
            </div>
            <div class="modal-footer card-footer" style="padding: 6px !important;">
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="Tab-row-Backdrop">
    <div class="modal-dialog modal-lg modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header p-2">
                <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="Shipped-tab" data-bs-toggle="tab"
                            href="#Shipped" role="tab" aria-controls="Shipped" aria-selected="true">Shipped</a>
                    </li>
                    <li class="nav-item"><a class="nav-link" id="Fulfilled-tab" data-bs-toggle="tab" href="#Fulfilled"
                            role="tab" aria-controls="Fulfilled" aria-selected="true">Fulfilled</a></li>
                </ul>
                <button type="button" class="btn btn-sm btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-2">
                <div class="card mb-2 p-0 border-0 rounded-0">
                    <div class="card-body p-2">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="Shipped" role="tabpanel"
                                aria-labelledby="Shipped-tab">
                                <div class="table-responsive table_spacing mt-3">
                                    <table class="table table-striped table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="text-center">Batch</th>
                                                <th class="text-center">Expiry date</th>
                                                <th class="text-center">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center">HUY675</td>
                                                <td class="text-center">30/11/2021</td>
                                                <td class="text-center">100</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="Fulfilled" role="tabpanel" aria-labelledby="Fulfilled-tab">
                                <table class="table table-striped table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Batch</th>
                                            <th class="text-center">Expiry date</th>
                                            <th class="text-center">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">HUY675</td>
                                            <td class="text-center">30/11/2021</td>
                                            <td class="text-center">100</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="mdlRD">
    <div class="modal-dialog">
        <div class="modal-content">
            <form [formGroup]="dispatchForm" autocomplete="off">
                <div class="modal-header p-3 card-header">
                    <h5 class="modal-title">Ready for Dispatch</h5>
                    <button type="button" class="btn btn-sm close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="col-xl-12">
                        <div class="row p-2">
                            <div class="col-sm-4 text-xl-right text-sm-right text-xs-left">Next Status</div>
                            <div class="col-sm-8">Ready for Dispatch</div>
                        </div>
                        <div class="row p-2">
                            <div class="col-sm-4 text-xl-right text-sm-right text-xs-left mt-2">Comment</div>
                            <div class="col-sm-8">
                                <div class="form-group">
                                    <textarea class="form-control taRS" formControlName="DispatchComment"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-primary" (click)="dispatchBooking()">Submit</button>
                    <button type="button" class="btn btn-sm btn-light" data-dismiss="modal" id="clsMDL">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>