import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any, LoadSideBar: any, LoadSimpleBar: any, LoadCustom: any, LoadSelect2: any, LoadFeather: any, LoadFeathericon: any,
  LoadScriptfile: any, LoadDomains: any, LoadSetupDomains: any;

@Component({
  selector: 'evin-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent extends BaseComponent implements OnInit {

  isTagsHide = true; crntDtTm = ''; isLcl = environment.lclURL; lstPrnths: any[] = []; prnthId: any; lgnUsrName = ''; lgnUsrRole = '';
  isTrnsfr = false; isShowSR = true; isShowTNS = true; isShowOrdlst = true; isShowPrfl = true; isShowOrdMdl = true; isShowInvtMdl = true;
  mstrData = { txnTypeLst: [], ordTypeLst: [], cntriesLst: [], rsnsLst: [], stsLst: [], statesLst: [], blockLst: [], LngLst: [], allMats: [],
    carrierLst: [], comuChnlLst: [], tmZoneLst: [], astTypLst: [], rlsLst: [], dstLst: [], IdentifiersLst: [], evntNtfcnLst: [], fileTyps: [] };
  cnfgData: any = {}; cnfgMail = ''; cnfgMobl = ''; trnsMstrs = { manfsLst: [], matStsLst: [] }; isMngr = false;
  configData: any; usrPrmsn: any = null; lgnBadgeData = JSON.parse(<string>localStorage.getItem('appBdgs')); lstDmns: any[] = [];
  lgnAPIData = { lgnMstr: {}, allMstr: {}, prnths: [], strs: [] }; msdStngs = { singleSelection: true, idField: 'pranthId', textField: 'pranthName', 
    itemsShowLimit: 3, allowSearchFilter: true}; dfltStrTgsStk: any[] = []; dmnFrm: FormGroup = this.fb.group({}); slctDmnNme = '';
  isBetaURL = environment.isBetaURL; isLclDev = environment.isLclDev; isLclDevQa = environment.isLclDevQa; isMigURL = environment.isMigURL;
  lgnUsrDtls = JSON.parse(<string>localStorage.getItem('lgnUsrDtls'));
  // Domain Permissions
  mmCCE = true; mmDb = true; mmDbOvrw = true; mmDbStkMngmnt = true; mmDbAct = true; mmDbIndnts = true; mmIndnts = true; mmIndInd = true; 
  mmIndTrnsfrs = true; mmIndShpmnts = true; mmIndDesc = true; mmSm = true; mmSmStkrprt = true; mmSmStkDvnt = true; mmSmTxn = true;
  mmRprts = true; mmRprtsAct = true; mmRprtActDmn = true; mmRprtActTxnCnts = true; mmRprtUsrAct = true; mmRprtActRte = true; mmRprtCCE = true;
  mmRprtCceCpcty = true; mmRprtCceSts = true; mmRprtCcePwr = true; mmRprtCceRespTo = true; mmRprtCceSckNss = true; mmRprtsCceTemp = true; mmRprtCceUp = true;
  mmRprtsCstm = true; mmRprtsCstmRprt = true; mmRprtStk = true; mmRprtStkStkReprt = true; mmRprtsStkAbnrml = true; mmRprtStkCnsmptn = true; mmRprtsStkDisc = true;
  mmRprtStkAvlbl = true; mmRprtStkTrnd = true; mmRprtInd = true; mmRprtIndDesc = true; mmRprtIndResTme = true; mmCnfg = true; mmCnfgGen = true; mmCnfgCap = true;
  mmCnfgStk = true; mmCnfgInd = true; mmCnfgNtfcn = true; mmCnfgTag = true; mmCnfgCstmRprt = true; mmCnfgCCE = true; mmCnfgDb = true; mmCnfgOpt = true; mmCnfgEvnt = true;
  mmCnfgBltnBrd = true; mmCnfgCstmDb = true; mmStup = true; mmStupUsr = true; mmStupMtrl = true; mmStupFac = true; mmStupCCE = true; mmStupStk = true; 
  mmStupDmns = true; mmStupBltnBrd = true; mmStupTrans = true; mmStupManfc = true; mmDbCce = true; mmIndMnf = true; mmIndPrcs = true;
  viewPrfl = true; isSupervisorHide = false; isSupAdmn = localStorage.getItem('isClkdSA') == '1'; cnfgVal: any = null;
  hdnTT = { isOrdLstHdn: false, isOrdCrtHdn: false, isCCEHdn: false, isTxnLstHdn: false, isTxnCrtHdn: false, isStkRptHdn: false,
  isTrnsLstHdn: false, isTrnsCrtHdn: false, isPrflHdn: false };

  constructor(public glblSvc: GlobalService,
    public router: Router, public fb: FormBuilder) {
    super(glblSvc);
    if (location.href.includes('ovrvw-db'))
      this.isTagsHide = false;
      this.dmnFrm = this.fb.group({slctdmn: ['']})
  }

  ngOnInit(): void {
    this.isNtnlAdmn = this.ChkNtnlUsr();
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions' ).length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrLgnDtls === null) {
      this.router.navigate(['']), setTimeout(() => { window.location.reload(); }, 500);
      return;
    }
    let defLgnDmn: any[] = [], dmnIntrval: any;
    this.isMngr = this.usrLgnDtls.roleId == 2, this.prnthId = this.usrLgnDtls.pranthId, this.lgnUsrName = this.usrLgnDtls.firstName + ' ' +
      this.usrLgnDtls.lastName, this.glblSvc.sbjctPrnthList.subscribe(s => this.lstPrnths = s), this.lgnUsrRole = this.usrLgnDtls.roleName,
    setTimeout(() => {
      if (localStorage.getItem('slctdDmnId') == null || localStorage.getItem('slctdDmnName') == undefined || localStorage.getItem('slctdDmnDtls') == null) {
        dmnIntrval = setInterval(() => {
          this.lstPrnths.filter(f => { if (f.pranthId == +this.prnthId) { localStorage.setItem('slctdDmnDtls', JSON.stringify(f)) }});
          if (localStorage.getItem('slctdDmnDtls') != null)
            clearInterval(dmnIntrval);
        }, 500)
        $(".js-example-basic-single").select2().val(this.prnthId), localStorage.setItem('slctdDmnId', this.prnthId),
        localStorage.setItem('slctdDmnName', this.usrLgnDtls.pranthName),
        defLgnDmn.push({ pranthId: +this.prnthId, pranthName: this.usrLgnDtls.pranthName });
      }
      else
        this.prnthId = localStorage.getItem('slctdDmnId'), $(".js-example-basic-single").select2().val(this.prnthId),
          localStorage.setItem('slctdDmnName', this.lstPrnths.filter(f => f.pranthId == +this.prnthId)[0]?.pranthName),
          this.lstPrnths.filter(f => { if (f.pranthId == +this.prnthId) { localStorage.setItem('slctdDmnDtls', JSON.stringify(f)) }}),
          defLgnDmn.push({ pranthId: +this.prnthId, pranthName: this.lstPrnths.filter(f => f.pranthId == +this.prnthId)[0]?.pranthName });
          this.onCallUIScripts();
          setTimeout(() => {
            this.dmnFrm.get('slctdmn')?.setValue(defLgnDmn);
          }, 2000);  
        }, 500);

    this.isSupervisorHide = (<string>JSON.parse(<string>localStorage.getItem('lgnUsrDtls'))?.pranthName).toLowerCase().includes('supervisor');
    $(document).ready(function () {
      $("#dmnDDL").change(function () {
        localStorage.setItem('slctdDmnId', $(".js-example-basic-single").select2()[0].value), $('#btnDmn')?.click();        
      });
    });
    this.cnfgVal = Object.keys(this.usrLgnDtls).filter(f => f == 'permissions').length > 0 ? this.usrLgnDtls.permissions[0]?. modulePermissionRoles[1] : null;
    this.crntDtTm = this.glblSvc.setDT();
    this.LoadMainMasters(), this.DmnPrmsns(), this.menuHdn();
    //  setTimeout(() => {
    //   if (this.usrLgnDtls?.roleName == 'Manufacturer' && this.usrLgnDtls?.roleId == 7)
    //     this.router.navigate(['/home/prchs-ord'], { queryParams: { tab: `${'list'}`, type: `${'i'}` } });
    //  }, 500);
  }

  onSuprAdmn() {
    localStorage.setItem('isClkdSA', (localStorage.getItem('isClkdSA') == '0' ? '1' : '0'));
      this.isSupAdmn = localStorage.getItem('isClkdSA') == '1';
      this.router.navigate([this.isSupAdmn ? environment.lndgPathUsr : environment.lndgPathGnrl]);
    if (this.isSupAdmn)
      this.router.navigate([environment.lndgPathUsr]);
    else
      this.router.navigate([environment.lndgPathGnrl]);
  }

  onInv() {
    localStorage.setItem('isCnfgInv', '1');
  }

  DmnPrmsns() {
    if (this.usrPrmsn != null) { 
      let cceLst: any[] = this.usrPrmsn?.CCE_Management, dbLst: any[] = this.usrPrmsn?.Dashboard, indLst: any[] = this.usrPrmsn?.Indents, 
      smLst: any[] = this.usrPrmsn?.Stock_Management, rprtsLst: any[] = this.usrPrmsn?.Reports, cnfgrnLst: any[] = this.usrPrmsn?.Configurations, 
      setupLst: any[] = this.usrPrmsn?.Setup, capblty: any[] = this.usrPrmsn?.Capabilities;

      cceLst.filter(e => { (e.menuCode == 'mm_cm') ? (this.mmCCE = e.action) : '' });

      dbLst.filter(e => { ((e.menuCode == 'mm_db') ? (this.mmDb = e.action) : ((e.menuCode == 'mm_db_id') ? (this.mmDbIndnts = e.action) : 
        ((e.menuCode == 'mm_db_ov') ? (this.mmDbOvrw = e.action) : ((e.menuCode == 'mm_db_ac') ? (this.mmDbAct = e.action) : 
        ((e.menuCode == 'mm_db_sm') ? (this.mmDbStkMngmnt = e.action) : ((e.menuCode == 'mm_db_cm') ? (this.mmDbCce = e.action) : '' ))))));          
      });
      
      indLst.filter(e => { ((e.menuCode == 'mm_id') ? (this.mmIndnts = e.action) : ((e.menuCode == 'mm_id_ids') ? (this.mmIndInd = e.action) : 
        ((e.menuCode == 'mm_id_tf') ? (this.mmIndTrnsfrs = e.action) : ((e.menuCode == 'mm_id_sh') ? this.mmIndShpmnts = e.action : ((e.menuCode == 'mm_id_ds') ? 
        (this.mmIndDesc = e.action) : ((e.menuCode == 'mm_id_mt') ? (this.mmIndMnf = e.action): ((e.menuCode == 'mm_id_pi') ? (this.mmIndPrcs = e.action): '')))))));
      });

      smLst.filter(e => { ((e.menuCode == 'mm_sm') ? (this.mmSm = e.action) : ((e.menuCode == 'mm_sm_sr_li') ? (this.mmSmStkrprt = e.action) : 
        ((e.menuCode == 'mm_sm_sd') ? (this.mmSmStkDvnt = e.action) : ((e.menuCode == 'mm_sm_tx') ? (this.mmSmTxn = e.action) : ''))));
      });

      rprtsLst.filter(e => { ((e.menuCode == 'mm_rp') ? (this.mmRprts = e.action) : ((e.menuCode == 'mm_rp_ac_da') ? (this.mmRprtActDmn = e.action) : (
        (e.menuCode == 'mm_rp_ac_tc') ? (this.mmRprtActTxnCnts = e.action) : ((e.menuCode == 'mm_rp_ac_ua') ? (this.mmRprtUsrAct = e.action) : (
          (e.menuCode == 'mm_rp_ac_ar') ? (this.mmRprtActRte = e.action) : ((e.menuCode == 'mm_rp_ac') ? (this.mmRprtsAct = e.action) : ((e.menuCode == 'mm_rp_cm') ? 
          (this.mmRprtCCE = e.action) : ((e.menuCode == 'mm_rp_cm_cc') ? (this.mmRprtCceCpcty = e.action) : ((e.menuCode == 'mm_rp_cm_cs') ? (this.mmRprtCceSts = e.action) : 
          ((e.menuCode == 'mm_rp_cm_pa') ? (this.mmRprtCcePwr = e.action) : ((e.menuCode == 'mm_rp_cm_rt') ? (this.mmRprtCceRespTo = e.action) : ((e.menuCode == 'mm_rp_cm_sr') ? 
          (this.mmRprtCceSckNss = e.action) : ((e.menuCode == 'mm_rp_cm_te') ? (this.mmRprtsCceTemp = e.action) : ((e.menuCode == 'mm_rp_cm_ut') ? (this.mmRprtCceUp = e.action) : 
          ((e.menuCode == 'mm_rp_sm') ? (this.mmRprtStk = e.action) : ((e.menuCode == 'mm_rp_sm_sr') ? (this.mmRprtStkStkReprt = e.action) : ((e.menuCode == 'mm_rp_sm_as') ? 
          (this.mmRprtsStkAbnrml = e.action) : ((e.menuCode == 'mm_rp_sm_cn') ? (this.mmRprtStkCnsmptn = e.action) : ((e.menuCode == 'mm_rp_sm_ds') ? (this.mmRprtsStkDisc = e.action) : 
          ((e.menuCode == 'mm_rp_sm_sa') ? (this.mmRprtStkAvlbl = e.action) : ((e.menuCode == 'mm_rp_sm_st') ? (this.mmRprtStkTrnd = e.action) : ((e.menuCode == 'mm_rp_id') ? 
          (this.mmRprtInd = e.action) : ((e.menuCode == 'mm_rp_id_ds') ? (this.mmRprtIndDesc = e.action) : ((e.menuCode == 'mm_rp_id_rt') ? (this.mmRprtIndResTme = e.action) : 
          ((e.menuCode == 'mm_rp_cr') ? (this.mmRprtsCstm = e.action) : '')))))))))))))))))))))))));
      });

      cnfgrnLst.filter(e => { ((e.menuCode == 'mm_cf') ? (this.mmCnfg = e.action) : ((e.menuCode == 'mm_cf_gn') ? (this.mmCnfgGen = e.action) : 
        ((e.menuCode == 'mm_cf_ca') ? (this.mmCnfgCap = e.action) : ((e.menuCode == 'mm_cf_sm') ? (this.mmCnfgStk = e.action) : ((e.menuCode == 
        'mm_cf_id') ? (this.mmCnfgInd = e.action) : ((e.menuCode == 'mm_cf_nf') ? (this.mmCnfgNtfcn = e.action) : ((e.menuCode == 'mm_cf_ta') ? 
        (this.mmCnfgTag = e.action) : ((e.menuCode == 'mm_cf_cr') ? (this.mmCnfgCstmRprt = e.action) : ((e.menuCode == 'mm_cf_cm') ? (this.mmCnfgCCE = e.action) : 
        ((e.menuCode == 'mm_cf_cd') ? (this.mmCnfgDb = e.action) : ((e.menuCode == 'mm_cf_pc') ? (this.mmCnfgOpt = e.action) : ((e.menuCode == 'mm_cf_es') ?
         (this.mmCnfgEvnt = e.action) : ((e.menuCode == 'mm_cf_bb') ? (this.mmCnfgBltnBrd = e.action) : ((e.menuCode == 'mm_cf_cd') ? 
         (this.mmCnfgCstmDb = e.action) : ''))))))))))))));
      });

      setupLst.filter(e => { ((e.menuCode == 'mm_st') ? (this.mmStup = e.action) : ((e.menuCode == 'mm_st_ma') ? (this.mmStupMtrl = e.action) : 
        ((e.menuCode == 'mm_st_fc') ? (this.mmStupFac = e.action) : ((e.menuCode == 'mm_st_do') ? (this.mmStupDmns = e.action) : ((e.menuCode == 'mm_st_cm') ? 
        (this.mmStupCCE = e.action) : ((e.menuCode == 'mm_st_bb') ? (this.mmStupBltnBrd = e.action) : ((e.menuCode == 'mm_st_tp') ? (this.mmStupTrans = e.action) : 
        ((e.menuCode == 'mm_st_mf') ? (this.mmStupManfc = e.action) : ((e.menuCode == 'mm_st_us') ? (this.mmStupUsr = e.action) : ((e.menuCode == 'mm_st_sm') ? 
        (this.mmStupStk = e.action) : ''))))))))));
      });

      capblty.filter(e => {((e.menuCode == 'cap_mi_vp') ? (this.viewPrfl = e.action): '')})

    }
  }

  menuHdn() {
    this.hdnTT = { isOrdLstHdn: (this.cnfgVal?.permissionRoles[11]?.configuredValue?.value) ?? false,
      isOrdCrtHdn: this.cnfgVal?.permissionRoles[9]?.configuredValue?.value ?? false,
      isTrnsLstHdn: this.cnfgVal?.permissionRoles[10]?.configuredValue?.value ?? false,
      isTrnsCrtHdn: this.cnfgVal?.permissionRoles[12]?.configuredValue?.value ?? false,
      isTxnLstHdn: this.cnfgVal?.permissionRoles[2]?.configuredValue?.value ?? false,
      isTxnCrtHdn: this.cnfgVal?.permissionRoles[4]?.configuredValue?.value ?? false,
      isCCEHdn: this.cnfgVal?.permissionRoles[13]?.configuredValue?.value ?? false,
      isPrflHdn: this.cnfgVal?.permissionRoles[15]?.configuredValue?.value ?? false,
      isStkRptHdn: this.cnfgVal?.permissionRoles[0]?.configuredValue?.value ?? false };
  }

  LoadMainMasters() {
    if (localStorage.getItem('isMstrsCld') == '1') {
      this.glblSvc.sbjctSpnr.next(false);
      let lstAPIs: any[] = [];
      const lgnMstrs = this.glblSvc.postAPI(environment.apiPaths.lgnMstrs, environment.apiMthds.getAll, {}, 2, 1);
      const allMstrs = this.glblSvc.getAPI(environment.apiPaths.webMstrs, environment.apiMthds.getAll, 2, 1);
      const userPrns = this.glblSvc.getAPI(environment.apiPaths.pranth, environment.apiMthds.mappedPranthsGetAll.replace('pageNo', '').replace('pageSize', '').replace('nnn', ''), 0);
      const userStrs = this.glblSvc.getAPI(environment.apiPaths.store, environment.apiMthds.getAllStrs + localStorage.getItem('slctdDmnId'), 0);
      const allCnfgData = this.glblSvc.getAPI(environment.apiPaths.systemConfiguration, environment.apiMthds.pullAllCnfg, 0);
      // const cnfgInvData = this.glblSvc.getAPI(environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType + environment.cnfgTypes.inventory, 0);
      // const cnfgOdrData = this.glblSvc.getAPI(environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType + environment.cnfgTypes.orders, 0);
      lstAPIs = localStorage.getItem('isLgd') == '1' ? [lgnMstrs, allMstrs, userPrns, userStrs, allCnfgData] : // , cnfgInvData, cnfgOdrData
        [lgnMstrs, allMstrs, userStrs, allCnfgData];
      forkJoin(lstAPIs).subscribe({
        next: (res: any) => {
          res.forEach((list: any, indx: number) => {
            if (list.status === 'OK') {
              let mstrLst: any = {};
              if (localStorage.getItem('isLgd') == '1') {
                mstrLst = {
                  '0': () => this.lgnAPIData.lgnMstr = list?.data ?? {},
                  '1': () => this.lgnAPIData.allMstr = list?.data ?? {},
                  '2': () => this.lgnAPIData.prnths = list?.data?.pranthData ?? [],
                  '3': () => this.lgnAPIData.strs = list?.data ?? [],
                  '4': () => this.cnfgData = list?.data ?? {},
                }
              }
              else {
                mstrLst = {
                  '0': () => this.lgnAPIData.lgnMstr = list?.data ?? {},
                  '1': () => this.lgnAPIData.allMstr = list?.data ?? {},
                  '2': () => this.lgnAPIData.strs = list?.data ?? [],
                  '3': () => this.cnfgData = list?.data ?? {},
                }
              }
              mstrLst[indx]();
            }
          });
        },
        error: err => { console.error('something went wrong ', err), console.log('lgnAPIData', this.lgnAPIData, 'cnfgData', this.cnfgData); },
        complete: () => {
          let mstrsAll: any = this.lgnAPIData.allMstr, mstrsLgn: any = this.lgnAPIData.lgnMstr;
          this.mstrData = { txnTypeLst: mstrsLgn.masterTxnTypes, ordTypeLst: mstrsLgn.masterOrderTypes, cntriesLst: mstrsLgn.masterCountry,
            rsnsLst: mstrsLgn.masterReasonTypes, stsLst: mstrsLgn.masterStatuses, statesLst: mstrsLgn.masterStates, blockLst: mstrsLgn.masterBlocks,
            carrierLst: mstrsAll.carriers, comuChnlLst: mstrsLgn.masterCommunicationChannels, LngLst: mstrsLgn.masterLanguages, fileTyps: mstrsLgn.masterFileTypes,
            tmZoneLst: mstrsLgn.masterTimeZones, astTypLst: mstrsLgn.masterAssetTypes, rlsLst: mstrsLgn.masterRoles, dstLst: mstrsLgn.masterDistrict,
            IdentifiersLst: mstrsLgn.masterTmTypes, evntNtfcnLst: mstrsLgn.masterNotificationEventTypes, allMats: mstrsAll.products },
            localStorage.setItem('lgnMstrsData', JSON.stringify(this.mstrData)), this.glblSvc.sbjctMstrData.next(this.mstrData),
            localStorage.setItem('lgnCnfgData', JSON.stringify(this.cnfgData)), this.glblSvc.sbjctCnfgData.next(this.cnfgData),
            sessionStorage.setItem('lgnUsrStrs', JSON.stringify(this.lgnAPIData.strs)), this.glblSvc.sbjctStrList.next(this.lgnAPIData.strs), // localStorage.setItem('lgnUsrStrs', JSON.stringify(this.lgnAPIData.strs)),
            localStorage.setItem('appBdgs', JSON.stringify(mstrsAll.badges)), this.glblSvc.sbjctBdgsList.next(mstrsAll.badges),
            this.lgnBadgeData = mstrsAll.badges, this.cnfgMail = '', this.cnfgMobl = '';
            if (this.lgnUsrDtls?.roleId == 2 || this.lgnUsrDtls?.roleId == 3) {
              let mpDt: any = this.cnfgData?.cnfgGenralData?.support_contacts?.filter((f: any) => f.role_id == this.lgnUsrDtls?.roleId)[0];
              this.cnfgMail = mpDt?.email_id ?? '', this.cnfgMobl = mpDt?.phone_number ?? '';
            }
          if (localStorage.getItem('isLgd') == '1')
            localStorage.setItem('lgnUsrPrths', JSON.stringify(this.lgnAPIData.prnths)), this.glblSvc.sbjctPrnthList.next(this.lgnAPIData.prnths);
          setTimeout(() => {
            if (localStorage.getItem('isLgd') == '1')
              $(".js-example-basic-single").select2().val(this.prnthId), localStorage.setItem('slctdDmnId', this.prnthId),
                localStorage.setItem('slctdDmnName', this.usrLgnDtls.pranthName), this.lstPrnths.filter(f => { if (f.pranthId == +this.prnthId) { localStorage.setItem('slctdDmnDtls', JSON.stringify(f)) }});
            else
              $(".js-example-basic-single").select2().val(localStorage.getItem('slctdDmnId'));
          }, 2000), this.LoadOthrMstrs();
        },
      });
    } else {
      let isTrnsfrCndtn = JSON.parse(<string>localStorage.getItem('lgnCnfgData'));
      this.isTrnsfr = isTrnsfrCndtn.cnfgOdrData?.general?.transfer_release_orders?.rename_transfer_orders_as_release_orders;
    }
  }

  chngDmn() {
    let sltdDmn: any[] = [];
    sltdDmn = this.dmnFrm.value.slctdmn;
    if (confirm(`Are you sure do you want to navigate ${sltdDmn[0].pranthName} domain.?`)) {
      this.prnthId = sltdDmn[0].pranthId, this.glblSvc.sbjctChsnDmnId.next(this.prnthId), 
      localStorage.setItem('slctdDmnId', this.prnthId), localStorage.setItem('isMstrsCld', '1'); //, this.glblSvc.onTstr('i', `Domain='${this.dmnId}'`);
      this.lstPrnths.filter(f => { if (f.pranthId == +this.prnthId) { localStorage.setItem('slctdDmnDtls', JSON.stringify(f)) }});
      let prnthName = sltdDmn[0].pranthName;
      if (prnthName != '')
        localStorage.setItem('slctdDmnName', prnthName), this.glblSvc.sbjctChsnDmnName.next(prnthName);
      
      if (localStorage.getItem('isClkdSA') != '1' && localStorage.getItem('isLgd') == '0' && location.href.includes('/home/db-ovrw')) {
        // setTimeout(() => { this.LoadMainMasters(), this.menuBinding(), this.router.navigate(['/home']); }, 500);  
        window.location.reload();
      }
      
      if(localStorage.getItem('isClkdSA') == '1' && location.href.includes('/home/setup-users-list')) {
        window.location.reload();     
      }

      if(localStorage.getItem('isClkdSA') == '1' && !location.href.includes('/home/setup-users-list')) {
        setTimeout(() => { this.LoadMainMasters(), this.menuBinding(), this.router.navigate(['/home/setup-users-list']); }, 500); 
      }
      
      if (localStorage.getItem('isClkdSA') != '1' && !location.href.includes('/home/db-ovrw'))
        setTimeout(() => { this.LoadMainMasters(), this.menuBinding(), this.router.navigate(['/home']); }, 500);
    }
    else {
      setTimeout(() => {
        let defDmn: any[] = [], dmn: any = localStorage.getItem('slctdDmnId');
        this.dfltStrTgsStk = [...[]], defDmn.push({pranthId: +dmn, pranthName: localStorage.getItem('slctdDmnName')});
          this.dfltStrTgsStk = [...defDmn], this.dmnFrm.get('slctdmn')?.setValue(this.dfltStrTgsStk);
      });
    }
  }

  onDslctDmn() {
    setTimeout(() => {
      let defDmn: any[] = [], dmn: any = localStorage.getItem('slctdDmnId');
      this.dfltStrTgsStk = [...[]], defDmn.push({pranthId: +dmn, pranthName: localStorage.getItem('slctdDmnName')});
        this.dfltStrTgsStk = [...defDmn], this.dmnFrm.get('slctdmn')?.setValue(this.dfltStrTgsStk);
    });
  }

  LoadOthrMstrs() {
    let bdgId: any = null, strsGMSD: any[] = [];
    this.glblSvc.sbjctSpnr.next(false), this.lgnBadgeData.filter((e: any) => { if (e.description === 'GMSD') bdgId = e.id; });
    const manfsLst = this.glblSvc.getAPI(environment.apiPaths.producer, environment.apiMthds.getAll, 0);
    const matStsLst = this.glblSvc.getAPI(environment.apiPaths.mstrSts, environment.apiMthds.getAll, 0);
    const gmsStrLst = this.glblSvc.getAPI(environment.apiPaths.cnsmptn, environment.apiMthds.badgeStore.replace('rrr', this.prnthId).
      replace('sss', `${bdgId}`), 4);
    forkJoin(bdgId != null ? [manfsLst, matStsLst, gmsStrLst] : [manfsLst, matStsLst]).subscribe({
      next: (res: any) => {
        res.forEach((list: any, indx: number) => {
          if (list.status === 'OK') {
            let mstrLst: any = {};
            if (bdgId != null) {
              mstrLst = {
                '0': () => { this.trnsMstrs.manfsLst = list?.data ?? [], this.glblSvc.sbjctManfsLst.next(this.trnsMstrs.manfsLst),
                  localStorage.setItem('lstManfs', JSON.stringify(this.trnsMstrs.manfsLst)); },
                '1': () => { this.trnsMstrs.matStsLst = list?.data ?? [], this.glblSvc.sbjctMatStsLst.next(this.trnsMstrs.matStsLst),
                  localStorage.setItem('lstMatSts', JSON.stringify(this.trnsMstrs.matStsLst)); },
                '2': () => { strsGMSD = list?.data ?? [], this.glblSvc.sbjctGMSDStrList.next(strsGMSD),
                  localStorage.setItem('lstStrsGMSD', JSON.stringify(strsGMSD)); }
              }
            }
            else {
              mstrLst = {
                '0': () => { this.trnsMstrs.manfsLst = list?.data ?? [], this.glblSvc.sbjctManfsLst.next(this.trnsMstrs.manfsLst),
                  localStorage.setItem('lstManfs', JSON.stringify(this.trnsMstrs.manfsLst)); },
                '1': () => { this.trnsMstrs.matStsLst = list?.data ?? [], this.glblSvc.sbjctMatStsLst.next(this.trnsMstrs.matStsLst),
                  localStorage.setItem('lstMatSts', JSON.stringify(this.trnsMstrs.matStsLst)); }
              }
            }
            mstrLst[indx]();
          }
        });
      },
      error: err => { console.error('something went wrong ', err); },
      complete: () => {
        localStorage.setItem('isMstrsCld', '0'), this.configData = JSON.parse(<string>localStorage.getItem('lgnCnfgData'));
        if (this.configData?.cnfgOdrData) {
          if (Object.keys(this.configData?.cnfgOdrData).length > 0) {
            if (Object?.keys(this.configData?.cnfgOdrData?.general?.transfer_release_orders).filter(f => f == 'rename_transfer_orders_as_release_orders').length > 0)
              this.isTrnsfr = this.configData?.cnfgOdrData?.general?.transfer_release_orders?.rename_transfer_orders_as_release_orders;
            else
              this.isTrnsfr = false;
          }
          else
            this.isTrnsfr = false;
        }
        else
          this.isTrnsfr = false;
        setTimeout(() => { this.glblSvc.sbjctSpnr.next(false); }, 2000);
        if (localStorage.getItem('isLgd') == '1')
          this.menuBinding();
        // this.router.navigate([this.isMigURL ? environment.lndgPathClnt : environment.lndgPathGnrl]);
        // this.router.navigate([environment.lndgPathGnrl]);
       ( localStorage.getItem('isClkdSA') != '1' && this.usrLgnDtls?.roleName != 'Manufacturer' && this.usrLgnDtls?.roleId != 7 )? 
          this.router.navigate([environment.lndgPathGnrl]) : ((localStorage.getItem('isClkdSA') != '1' && this.usrLgnDtls?.roleName == 
            'Manufacturer' && this.usrLgnDtls?.roleId == 7)? (this.router.navigate(['/home/prchs-ord'], { queryParams: { tab: `${'list'}`, 
            type: `${'i'}` } })):this.router.navigate([environment.lndgPathUsr]));
      },
    });
  }

  onTabs(page: string) {
    this.glblSvc.sbjctTabsPage.next(page);
  }

  onCallUIScripts() {
    LoadSideBar(), LoadSimpleBar(), LoadCustom(), LoadSelect2(), LoadFeather(), LoadFeathericon(), LoadScriptfile(), LoadDomains();
  }

  chngdVal() {
    this.prnthId = localStorage.getItem('slctdDmnId'), this.glblSvc.sbjctChsnDmnId.next(this.prnthId), localStorage.setItem('isMstrsCld', '1'); //, this.glblSvc.onTstr('i', `Domain='${this.dmnId}'`);
    let prnthName = this.lstPrnths.filter(f => f.pranthId == +this.prnthId)[0]?.pranthName ?? '';
    if (prnthName != '')
      localStorage.setItem('slctdDmnName', prnthName), this.glblSvc.sbjctChsnDmnName.next(prnthName);
    if (localStorage.getItem('isClkdSA') != '1' && localStorage.getItem('isLgd') == '0' && location.href.includes('/home/db-ovrw')) {
        window.location.reload();
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate(['/home/db-ovrw']);
    }
      // this.LoadMainMasters();
    if(localStorage.getItem('isClkdSA') == '1' && location.href.includes('/home/setup-users-list')) {
      window.location.reload();     
    }
    if(localStorage.getItem('isClkdSA') == '1' && !location.href.includes('/home/setup-users-list')) {
      setTimeout(() => { this.LoadMainMasters(), this.menuBinding(), this.router.navigate(['/home/setup-users-list']); }, 500); 
    }
    if (localStorage.getItem('isClkdSA') != '1' && !location.href.includes('/home/db-ovrw'))
      setTimeout(() => { this.LoadMainMasters(), this.menuBinding(), this.router.navigate(['/home']); }, 500);
  }

  onTreDmnClk(sltdDtls: any) {
    if (sltdDtls.pranthId != localStorage.getItem('slctdDmnId')) {
      if (confirm(`Are you sure do you want to navigate ${sltdDtls.pranthName} domain.?`)) {
        this.prnthId = sltdDtls.pranthId, localStorage.setItem('slctdDmnId', sltdDtls.pranthId), this.glblSvc.sbjctChsnDmnId.next(sltdDtls.pranthId), localStorage.setItem('isMstrsCld', '1');//, this.glblSvc.onTstr('i', `Domain='${this.dmnId}'`);
        localStorage.setItem('slctdDmnName', sltdDtls.pranthName),
          this.lstPrnths.filter(f => { if (f.pranthId == +this.prnthId) { localStorage.setItem('slctdDmnDtls', JSON.stringify(f)) } }),
          this.glblSvc.sbjctChsnDmnName.next(sltdDtls.pranthName);
        if (localStorage.getItem('isClkdSA') != '1' && localStorage.getItem('isLgd') == '0' && location.href.includes('/home/db-ovrw'))
          window.location.reload();

          if(localStorage.getItem('isClkdSA') == '1' && location.href.includes('/home/setup-users-list')) {
            window.location.reload();     
          }

        if (!location.href.includes('/home/db-ovrw') || !location.href.includes('/home/setup-users-list')) {
          // if (this.isMigURL)
          //   setTimeout(() => { this.LoadMainMasters(), document.getElementById('mdlClseBtn')?.click(),
          //    this.router.navigate([environment.lndgPathClnt]) }, 500);
          // else
          setTimeout(() => {
            this.LoadMainMasters(), document.getElementById('mdlClseBtn')?.click(),
            ( localStorage.getItem('isClkdSA') != '1' && this.usrLgnDtls?.roleName != 'Manufacturer' && this.usrLgnDtls?.roleId != 7 )? 
                this.router.navigate([environment.lndgPathGnrl]) : ((localStorage.getItem('isClkdSA') != '1' && this.usrLgnDtls?.roleName == 
            'Manufacturer' && this.usrLgnDtls?.roleId == 7)? (this.router.navigate(['/home/prchs-ord'], { queryParams: { tab: `${'list'}`, 
            type: `${'i'}` } })):this.router.navigate([environment.lndgPathUsr])) }, 500);
          
          setTimeout(() => {
            let defDmn: any[] = []; this.dfltStrTgsStk = [...[]], defDmn.push({ pranthId: +this.prnthId, pranthName: sltdDtls.pranthName });
            this.dfltStrTgsStk = [...defDmn], this.dmnFrm.get('slctdmn')?.setValue(this.dfltStrTgsStk), this.menuBinding();
          }, 1000);
        }

      }
    }
  }

  LoadTreeDomains() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.getHrchryDtls + localStorage.getItem('slctdDmnId'),
        null).then(() => { let res = this.result;
      if (this.result)
        this.lstDmns = this.result?.data[0]?.Pranth ?? [], this.slctDmnNme = this.result?.data[0]?.pranthName ?? '',
        setTimeout(() => {
          LoadSetupDomains() }, 500);
      else
        this.glblSvc.onTstr('e', res.message)
    });
  }

  onClick(path: any) {
    localStorage.setItem('rprtPath', path), this.glblSvc.sbjctRprtFltr.next(path);
  }

  menuBinding() {
    // if (this.isMigURL)
    //   this.router.navigate([environment.lndgPathClnt]), setTimeout(() => { $('#srAnchr').click(), setTimeout(() => { $('#srMI').click(), localStorage.setItem('isLgd', '0'); }, 100); }, 300);
    // else
    if(this.isSupAdmn == false && this.usrLgnDtls?.roleName != 'Manufacturer' && this.usrLgnDtls?.roleId != 7)
      this.router.navigate([environment.lndgPathGnrl]), setTimeout(() => { $('#dbAnchr').click(), setTimeout(() => { $('#dbMI').click(), 
      localStorage.setItem('isLgd', '0'); }, 100); }, 300);
    else if (this.isSupAdmn == false && this.usrLgnDtls?.roleName == 'Manufacturer' && this.usrLgnDtls?.roleId == 7)
        this.router.navigate(['/home/prchs-ord'], { queryParams: { tab: `${'list'}`, type: `${'i'}` } });
  }

  removeDbStr() {
    localStorage.removeItem('dbstrDtl'), this.glblSvc.sbjctCceFacDB.next(''), this.router.navigate(['/home/asst-asts']);
  }

}